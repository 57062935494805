import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

const OverviewNavGroup = () => {
  const links = [
    { title: "Home", icon: "Home", path: "/" },
    {
      title: "Dashboard",
      icon: "ViewDashboard",
      path: "/dashboard",
    },
    {
      title: "Notifications",
      icon: "Ringer",
      path: "/notifications",
    },
  ];

  const rootProps = {
    title: "Overview",
    icon: "TVMonitor",
    path: links[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={links} />;
};

export default OverviewNavGroup;
