import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsList,
  SelectionMode,
} from "@fluentui/react";
import { pathToFileName } from "batches/customer/BatchFilesPivot";
import { fileTableDiv, tableStyling } from "../Styles";

const verifyStatusMap = {
  UNVERIFIED: "Not checked",
  NOT_VERIFIED: "Failed Check",
  PROCESSING: "Processsing",
  VERIFIED: "Verified",
  NEEDS_REVIEW: "Needs approval",
};

const BatchFilesPivot = (props) => {
  const { batch } = props;
  const blobs = batch ? batch.blobs : [];

  // Column Settings for the Table which lists the files in the Batch
  const columns = [
    {
      key: "column1",
      name: "File Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (item) => {
        return <div>{pathToFileName(item.name)}</div>;
      },
    },
    {
      key: "column2",
      name: "Size",
      fieldName: "size",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{`${item.properties.contentLength / 1000} KB`}</div>;
      },
    },
    {
      key: "column3",
      name: "Content Type",
      fieldName: "contentType",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{item.properties.contentType}</div>;
      },
    },
    {
      key: "column4",
      name: "Verify Status",
      fieldName: "verifyStatus",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{verifyStatusMap[item.verificationStatus]}</div>;
      },
    },
    {
      key: "column5",
      name: "Verify Reason",
      fieldName: "verifyReason",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
      onRender: (item) => {
        return item.verificationStatus === "NOT_VERIFIED" ? (
          <div>{decodeURIComponent(item.verificationReason)}</div>
        ) : null;
      },
    },
  ];

  return (
    <div className={fileTableDiv}>
      <DetailsList
        compact={true}
        items={blobs}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        styles={{ root: tableStyling }}
        selectionMode={SelectionMode.none}
        constrainMode={ConstrainMode.unconstrained}
      />
    </div>
  );
};

export default BatchFilesPivot;
