import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "./Styles";

const ConfirmManifestModal = (props) => {
  const { isModalOpen, hideModal, manifest, cluster, resendManifest } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Resend Manifest Confirmation</div>
      <div className={modalBodyPadding}>
        <div>
          <h6>
            <b>
              You are about to resend the Manifest file for the given cluster.
              This action will be logged. Continue?
            </b>
          </h6>
        </div>
        <div>
          <b>{`Cluster: `}</b>
          {cluster?.key}
        </div>
        <div>
          <b>{`Manifest Sequence: `}</b>
          {manifest}
        </div>
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => resendManifest()}
          className={modalButtons}
          data-testid="confirm-resend-button"
        >
          Confirm
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ConfirmManifestModal;
