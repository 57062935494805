import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "batches/Styles";
import { MODAL_FLOW_ENABLE } from "ingressFlows/admin/AdminIngressFlows";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  adminDisableFlowSync,
  adminEnableFlowSync,
} from "store/egressFlowSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";

export const JustificationModal = (props) => {
  const { isModalOpen, hideModal, modalType, modalFlow } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [justification, setJustification] = useState(null);

  const enableFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    dispatch(
      adminEnableFlowSync({
        guid: modalFlow.dataflowGuid,
        cloud: modalFlow.cloud,
        token: token,
        justification: justification,
      })
    );
    hideModal();
  };

  const disableFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    dispatch(
      adminDisableFlowSync({
        guid: modalFlow.dataflowGuid,
        cloud: modalFlow.cloud,
        token: token,
        justification: justification,
      })
    );
    hideModal();
  };

  const confirmFunc = () => {
    if (modalType === MODAL_FLOW_ENABLE) {
      enableFlow();
    } else {
      disableFlow();
    }
  };

  const modalBody =
    modalType === MODAL_FLOW_ENABLE ? (
      <div>
        <TextField
          label="Please provide the reason for why this Flow is being enabled"
          onChange={(event, newValue) => setJustification(newValue)}
        />
      </div>
    ) : (
      <div>
        <TextField
          label="Please provide the reason for why this Flow is being disabled"
          onChange={(event, newValue) => setJustification(newValue)}
        />
      </div>
    );

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Flow {modalFlow.dataflowGuid}</div>
      <div className={modalBodyPadding}>{modalBody}</div>
      <br />
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => confirmFunc()}
          disabled={!justification || !modalFlow}
          className={modalButtons}
          data-testid="confirm-button"
        >
          Confirm
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default JustificationModal;
