import { Spinner, SpinnerSize } from "@fluentui/react";
import { spinnerCentered } from "./Styles";

const LoadingView = (props) => {
  const { label } = props;

  return (
    <div className={spinnerCentered}>
      <Spinner size={SpinnerSize.large} label={label} labelPosition="top" />
    </div>
  );
};

export default LoadingView;
