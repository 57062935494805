import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  eulaPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "batches/Styles";

const ApproverAupModal = (props) => {
  const { isModalOpen, hideModal, aup, approveBatch } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Acceptable Use Policy</div>
      {
        <div className={eulaPadding}>
          <div dangerouslySetInnerHTML={{ __html: aup }}></div>
        </div>
      }
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => approveBatch()}
          className={modalButtons}
          data-testid="approve-button"
        >
          Approve
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ApproverAupModal;
