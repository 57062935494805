import { mergeStyles } from "@fluentui/merge-styles";

export const tableHeader = mergeStyles({
  paddingBottom: "8px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const tableDiv = mergeStyles({
  selectors: {
    ".ms-Viewport": {
      paddingTop: "0px",
      paddingBottom: "0px",
      maxHeight: "inherit",
    },
  },
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  maxHeight: "80%",
});

export const tableButtons = mergeStyles({
  marginLeft: "8px",
});
