import { useAccount, useMsal } from "@azure/msal-react";
import {
  Dropdown,
  DropdownMenuItemType,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { protectedResources } from "authConfig";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminOpsAPI } from "utils/endpoints";
import ConfirmResyncModal from "./ConfirmResyncModal";
import {
  baseDiv,
  body,
  footer,
  headerDiv,
  paddingTop,
  primaryActionButton,
  titleFont,
  whitebg,
} from "./Styles";
import {
  datetimeErrorText,
  guidErrorText,
  validDatetime,
  validFlowGuid,
} from "./utils";

const ResyncFlow = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [flowId, setFlowId] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [syncStartTime, setSyncStartTime] = useState(null);
  const [syncEndTime, setSyncEndTime] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const clusters =
    useSelector((state) => state.cluster.list)[selectedCloud?.project] ?? [];
  const ingressClusters = [
    {
      key: "ingressHeader",
      text: "Ingress Clusters",
      itemType: DropdownMenuItemType.Header,
    },
  ];
  clusters.forEach((clusterName) => {
    if (clusterName.slice(-1) === "a") {
      ingressClusters.push({
        key: clusterName,
        text: clusterName,
      });
    }
  });
  const clusterDropdownList = ingressClusters;

  const buttonEnabled =
    validFlowGuid(flowId) &&
    cluster &&
    validDatetime(syncStartTime) &&
    validDatetime(syncEndTime);

  const resyncFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const bodyParams = {
      flowId: flowId,
      cluster: cluster.key,
      syncStartTime: syncStartTime,
      syncEndTime: syncEndTime,
    };

    setRequestPending(true);
    hideModal();

    callAuthorizedEndpointWithBody(
      `${adminOpsAPI}/resync`,
      token,
      "POST",
      bodyParams
    )
      .then(() => {
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Successfully resynced Flow.",
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestPending(false);
      });
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Resync Flow</span>
      </div>
      <div className={whitebg}>
        <div className={body}>
          <TextField
            label="Flow ID"
            onChange={(event, newValue) => setFlowId(newValue.trim())}
            onGetErrorMessage={(value) => guidErrorText(value)}
            placeholder="e.g. 1245d9ba-0faa-4a90-9037-96125e2ee0b7"
          />
          <Dropdown
            label="Cluster"
            options={clusterDropdownList}
            onChange={(event, option, index) => setCluster(option)}
            data-testid="cluster-dropdown"
            className={paddingTop}
          />
          <TextField
            label="Sync From DateTime"
            onChange={(event, newValue) => setSyncStartTime(newValue.trim())}
            onGetErrorMessage={(value) => datetimeErrorText(value)}
            placeholder="e.g. 2020-01-01T00:00:00Z"
            className={paddingTop}
          />
          <TextField
            label="Sync To DateTime"
            onChange={(event, newValue) => setSyncEndTime(newValue.trim())}
            onGetErrorMessage={(value) => datetimeErrorText(value)}
            placeholder="e.g. 2022-12-31T00:00:00Z"
            className={paddingTop}
          />
          <div className={footer}>
            <PrimaryButton
              className={primaryActionButton}
              disabled={!buttonEnabled || requestPending}
              data-testid="resyncFlow-button"
              onClick={() => showModal()}
            >
              {requestPending ? "Loading..." : "Resync"}
            </PrimaryButton>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmResyncModal
          {...{
            isModalOpen,
            hideModal,
            flowId,
            cluster,
            syncStartTime,
            syncEndTime,
            resyncFlow,
          }}
        />
      )}
    </div>
  );
};

export default ResyncFlow;
