import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerDiv = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
});

export const titleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const refreshButton = mergeStyles({
  marginLeft: "8px",
});

export const tableDiv = mergeStyles({
  selectors: {
    ".ms-Viewport": {
      paddingTop: "0px",
      paddingBottom: "0px",
      maxHeight: "inherit",
    },
  },
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  maxHeight: "80%",
});

export const tableStyling = mergeStyles({
  overflowX: "auto",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  selectors: {
    "& [role=grid]": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    ".ms-DetailsHeader": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    ".ms-DetailsList": {
      maxHeight: "300px",
      overflowX: "scroll",
    },
  },
});

export const linkSpan = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgb(0, 69, 120)",
    },
  },
  cursor: "pointer",
  color: "rgb(0, 120, 212)",
  textDecoration: "underline",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

export const errorTextStyle = mergeStyles({
  position: "relative",
  top: "50%",
  left: "45%",
});

export const pivotPadding = mergeStyles({
  padding: "0 24px 0 24px",
  flexGrow: "1",
  overflowY: "auto",
});

export const overviewCard = mergeStyles({
  padding: "16px 8px 16px 8px",
  display: "flex",
  flexDirection: "column",
});

export const card = mergeStyles({
  padding: "20px 20px",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  flexGrow: "1",
});

export const essentialsTitle = mergeStyles({
  fontWeight: "bold",
  marginBottom: "18px",
  paddingLeft: "3px",
  flexGrow: "1",
});

export const essentialsColumn = mergeStyles({
  width: "50%",
  display: "inline-block",
  verticalAlign: "top",
});

export const essentialsGrid = mergeStyles({
  display: "grid",
  gridTemplateColumns: "max-content max-content auto",
  overflow: "hidden",
});

export const essentialsText = mergeStyles({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "5px 3px",
  "line-height": "24px",
});

export const baseDiv = classNames(greyPage, base);
