import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import PaginationTable from "paginationTable/Table";
import { getPasteDate } from "pastebin/pasteTimestamp";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "pastebin/Styles";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPastes } from "store/pasteSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { Link } from "utils/Link";

const sharedWithMapping = {
  me: "Only Me",
  specified: "Specified Recipients",
  everyone: "Everyone",
};

export const Pastes = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const pastes = useSelector((state) => state.paste.list)
    .map((paste) => {
      return {
        ...paste,
        shortenedName: paste.pasteName.substring(
          paste.pasteName.indexOf("-") + 1
        ),
        pasteUrl: paste.pasteName.substring(0, paste.pasteName.indexOf("-")),
        created: getPasteDate(paste.pasteTimeStamp),
        shared: sharedWithMapping[paste.sharedWith],
      };
    })
    .sort((a, b) => {
      if (a.created < b.created) {
        return 1;
      }

      if (a.created > b.created) {
        return -1;
      }

      return 0;
    });
  const getPastesStatus = useSelector((state) => state.paste.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the Table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "shortenedName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return (
          <Link className={linkSpan} to={`/pastes/${item.pasteUrl}`}>
            {item.shortenedName}
          </Link>
        );
      },
    },
    {
      key: "column2",
      name: "Owner",
      fieldName: "owner",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Created",
      fieldName: "created",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Shared With",
      fieldName: "shared",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/pastes/${item.pasteUrl}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of Paste List
   */
  const getPasteList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getPastes({ token: accessToken, cloud: selectedCloud.project }));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve paste list
   */
  useEffect(() => {
    getPasteList();
  }, [getPasteList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>My Pastes</span>
        <PrimaryButton
          text="Create new paste"
          onClick={() => navigate("/createpaste")}
        />
        <DefaultButton
          text="Refresh"
          onClick={() => getPasteList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={pastes}
        columns={columns}
        tableStyling={tableStyling}
        loading={getPastesStatus === "loading" || selectedCloud === null}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
};

export default Pastes;
