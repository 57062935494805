import { useAccount, useMsal } from "@azure/msal-react";
import {
  Dropdown,
  Icon,
  Label,
  MessageBarType,
  SpinButton,
  TextField,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useState } from "react";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminEgressAPI } from "utils/endpoints";
import {
  getEmailErrorText,
  getResourceGroupErrorText,
  getServiceNameErrorText,
  getServiceTreeErrorText,
  getSubscriptionIdErrorText,
  getTitleErrorText,
} from "../createFlowValidation";
import {
  card,
  createInput,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  editIconsPadding,
  flowEditDisabledIcons,
  flowEditIcon,
  flowSectionTitleDiv,
  validationLabel,
} from "egressFlows/Styles";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";
import { flowStates } from "egressFlows/customer/EgressFlows";
import EmployeePicker from "employeePicker/EmployeePicker";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import { aliasesToPeoplePickerList } from "ingressFlows/admin/AdminEditFlow";

const isEqualsJson = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  return (
    keys1.length === keys2.length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
};

const AdminEditFlow = (props) => {
  const { flow, setFlow, clusterList } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [editMode, setEditMode] = useState(false);
  const [modifiedFlow, setModifiedFlow] = useState(flow);
  const [sending, setSending] = useState(false);

  const onPrimaryUserSearchChange = (items) => {
    setModifiedFlow({
      ...modifiedFlow,
      primaryContact:
        items.length > 0 ? getAliasFromUserPrincipalName(items[0].upn) : "",
    });
  };

  const onSecondaryUserSearchChange = (items) => {
    const itemArr = items.map((item) =>
      item.key.includes("@")
        ? getAliasFromUserPrincipalName(item.key)
        : item.key
    );

    const uniqueArr = [...new Set(itemArr)];

    setModifiedFlow({
      ...modifiedFlow,
      secondaryContact: uniqueArr.join(";"),
    });
  };

  const cancelUpdate = () => {
    setEditMode(false);
    setModifiedFlow(flow);
  };

  const isUpdateAllowed = () => {
    return !sending && !isEqualsJson(flow, modifiedFlow);
  };

  const getDiff = (field) =>
    modifiedFlow[field] === flow[field] ? null : modifiedFlow[field];

  const getDiffJson = () => {
    const diffs = {
      Cluster: getDiff("cluster"),
      Email: getDiff("email"),
      Notes: getDiff("notes"),
      PrimaryContact: getDiff("primaryContact"),
      ResourceGroup: getDiff("resourceGroup"),
      SecondaryContact: getDiff("secondaryContact"),
      ServiceName: getDiff("serviceName"),
      ServiceTreeId: getDiff("serviceTreeId"),
      SubscriptionId: getDiff("subscriptionId"),
      SyncPeriod: getDiff("syncPeriod"),
      Title: getDiff("title"),
    };

    Object.keys(diffs).forEach((key) => {
      if (diffs[key] === null) {
        delete diffs[key];
      }
    });

    return diffs;
  };

  const updateFlow = async () => {
    const body = getDiffJson();

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setSending(true);

    callAuthorizedEndpointWithBody(
      `${adminEgressAPI}/${flow.dataflowGuid}?cloud=${flow.cloud}`,
      token,
      "PATCH",
      body
    )
      .then(() => {
        setFlow(modifiedFlow);
        setEditMode(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setSending(false);
      });
  };

  const editButton = editMode ? (
    <>
      <button
        onClick={() => {
          if (isUpdateAllowed()) updateFlow();
        }}
        className={isUpdateAllowed() ? flowEditIcon : flowEditDisabledIcons}
        data-testid="update-button"
      >
        <Icon iconName="Accept" />
      </button>
      <button
        onClick={() => {
          if (!sending) cancelUpdate();
        }}
        className={`${editIconsPadding} ${flowEditIcon} ${
          sending ? flowEditDisabledIcons : ""
        }`}
        data-testid="cancel-button"
      >
        <Icon iconName="Cancel" />
      </button>
    </>
  ) : (
    <button
      className={flowEditIcon}
      onClick={() => setEditMode(true)}
      data-testid="edit-button"
    >
      <Icon iconName="Edit" />
    </button>
  );

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>DRI Details</div>
          {editButton}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Flow Status</div>
          <Label className={validationLabel}>
            {`${flow.isDeleted ? "(Deleted) - " : ""}${
              flowStates[flow.egressStatus]
            }`}
          </Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Notes</div>
          {editMode ? (
            <TextField
              data-testid="Notes"
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, notes: value })
              }
              value={modifiedFlow.notes}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.notes}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cluster</div>
          {editMode && flow.egressStatus === flowStates.Approved ? (
            <Dropdown
              selectedKey={modifiedFlow.cluster}
              onChange={(event, option, index) =>
                setModifiedFlow({ ...modifiedFlow, cluster: option.key })
              }
              options={clusterList}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.cluster}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Sync Period in Minutes</div>
          {editMode &&
          flow.egressStatus === flowStates.Approved &&
          flow.onboarded ? (
            <SpinButton
              value={modifiedFlow.syncPeriod}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  syncPeriod: Number(value),
                })
              }
              min={1}
              step={1}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.syncPeriod}</Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          {editMode ? (
            <TextField
              data-testid="Title"
              onGetErrorMessage={(value) => getTitleErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, title: value })
              }
              value={modifiedFlow.title}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.title}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          {editMode ? (
            <TextField
              data-testid="Email"
              onGetErrorMessage={(value) => getEmailErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, email: value.trim() })
              }
              value={modifiedFlow.email}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.email}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          {editMode ? (
            <EmployeePicker
              itemLimit={1}
              onChange={(items) => onPrimaryUserSearchChange(items)}
              defaultSelectedItems={[
                { key: flow.primaryContact, text: flow.primaryContact },
              ]}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.primaryContact}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          {editMode ? (
            <EmployeePicker
              onChange={(items) => onSecondaryUserSearchChange(items)}
              defaultSelectedItems={aliasesToPeoplePickerList(
                modifiedFlow.secondaryContact
              )}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.secondaryContact?.split(";").join(", ")}
            </Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>Storage Tenant ID</div>
          <Label className={validationLabel}>{flow.storageTenantId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          {editMode ? (
            <TextField
              data-testid="subscriptionID"
              onGetErrorMessage={(value) => getSubscriptionIdErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  subscriptionId: value.trim().toLowerCase(),
                })
              }
              value={modifiedFlow.subscriptionId}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.subscriptionId}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          {editMode ? (
            <TextField
              data-testid="resourceGroup"
              onGetErrorMessage={(value) => getResourceGroupErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  resourceGroup: value.trim(),
                })
              }
              value={modifiedFlow.resourceGroup}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.resourceGroup}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          {editMode ? (
            <TextField
              data-testid="serviceName"
              onGetErrorMessage={(value) => getServiceNameErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceName: value,
                })
              }
              value={modifiedFlow.serviceName}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceName}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          {editMode ? (
            <TextField
              data-testid="serviceTreeId"
              onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceTreeId: value.trim(),
                })
              }
              value={modifiedFlow.serviceTreeId}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceTreeId}</Label>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditFlow;
