import * as React from "react";
import OverviewNavGroup from "./OverviewNavGroup";
import { navSelectionStyle } from "../Styles";
import BatchNavGroup from "./BatchNavGroup";
import IngressNavGroup from "./IngressNavGroup";
import EgressNavGroup from "./EgressNavGroup";
import AdminOpsNavGroup from "./AdminOpsNavGroup";
import { useSelector } from "react-redux";
import { RP_MODE } from "store/modeSlice";
import PipelineNavGroup from "./PipelineNavGroup";
import ConnectionNavGroup from "./ConnectionNavGroup";
import FlowNavGroup from "./FlowNavGroup";

const NavSelection = () => {
  const selectedMode = useSelector((state) => state.mode.currentAppMode);

  const teleportalSideNav = (
    <div className={navSelectionStyle}>
      <OverviewNavGroup />
      <BatchNavGroup />
      <IngressNavGroup />
      <EgressNavGroup />
      <AdminOpsNavGroup />
    </div>
  );

  const rpSideNav = (
    <div className={navSelectionStyle}>
      <OverviewNavGroup />
      <PipelineNavGroup />
      <ConnectionNavGroup />
      <FlowNavGroup />
      <AdminOpsNavGroup />
    </div>
  );

  return selectedMode === RP_MODE ? rpSideNav : teleportalSideNav;
};

export default NavSelection;
