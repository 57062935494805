import Editor from "@monaco-editor/react";
import { card, overviewCard } from "pipelines/Styles";
import { textEditor } from "pastebin/Styles";

const RawView = (props) => {
  const { resource } = props;
  var jsonString = JSON.stringify(resource, null, 2);
  return (
    <div className={overviewCard}>
      <div className={card}>
        <div className={textEditor}>
          <Editor
            language={"json"}
            defaultValue={jsonString}
            options={{ readOnly: true }}
          />
        </div>
      </div>
    </div>
  );
};
export default RawView;
