import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

export const batchLinks = [
  {
    title: "My batches",
    icon: "FolderList",
    path: "/batches",
  },
  {
    title: "Create a batch",
    icon: "FabricNewFolder",
    path: "/createbatch",
  },
  {
    title: "Shared with me",
    icon: "FabricUserFolder",
    path: "/sharedbatches",
  },
];

const BatchNavGroup = () => {
  const rootProps = {
    title: "Batches",
    icon: "PublicFolder",
    path: batchLinks[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={batchLinks} />;
};

export default BatchNavGroup;
