import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "./Styles";

const ConfirmResendModal = (props) => {
  const {
    isModalOpen,
    hideModal,
    flowId,
    cluster,
    objName,
    objHashPrefix,
    objNamePrefix,
    useOldVersion,
    useOnlySuccess,
    resendObj,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Teleport Object Resend Confirmation</div>
      <div className={modalBodyPadding}>
        <div>
          <b>{`Flow ID: `}</b>
          {flowId}
        </div>
        <div>
          <b>{`Cluster: `}</b>
          {cluster?.key}
        </div>
        {objName && (
          <div>
            <b>{`Object Name: `}</b>
            {objName}
          </div>
        )}
        {objHashPrefix && (
          <div>
            <b>{`Object Hash Prefix: `}</b>
            {objHashPrefix}
          </div>
        )}
        {objNamePrefix && (
          <div>
            <b>{`Object Name Prefix: `}</b>
            {objNamePrefix}
          </div>
        )}
        <div>
          <b>{`Use Old Versions: `}</b>
          {useOldVersion ? "Yes" : "No"}
        </div>
        <div>
          <b>{`Only Successful Entries: `}</b>
          {useOnlySuccess ? "Yes" : "No"}
        </div>
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => resendObj()}
          className={modalButtons}
          data-testid="confirm-resend-button"
        >
          Confirm
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ConfirmResendModal;
