import { MessageBarType } from "@fluentui/react";
import { createSlice } from "@reduxjs/toolkit";

export const messageBarSlice = createSlice({
  name: "messageBar",
  initialState: {
    show: false,
    type: MessageBarType.info,
    message: null,
  },
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setMessage: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },
  },
});

export const { setShow, setMessage } = messageBarSlice.actions;

export default messageBarSlice.reducer;
