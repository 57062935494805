import {
  CheckboxVisibility,
  ConstrainMode,
  DefaultButton,
  DetailsList,
  PrimaryButton,
  SelectionMode,
} from "@fluentui/react";
import { pathToFileName } from "batches/customer/BatchFilesPivot";
import {
  deleteBatchButton,
  fileTableDiv,
  tableRowButton,
  tableStyling,
} from "../Styles";

const FileReviewPivot = (props) => {
  const {
    batch,
    downloadFile,
    openApproveModal,
    openRejectModal,
    requestSending,
  } = props;
  const blobs = batch ? batch.blobs : [];

  const getVerifyColumn = (index) => {
    const status = blobs[index].internalStatus;

    if (status) {
      return <div>{status}</div>;
    }

    return (
      <>
        <PrimaryButton
          disabled={requestSending}
          text="Approve"
          data-testid="approve-file"
          onClick={() => openApproveModal(index)}
        />
        <PrimaryButton
          text="Reject"
          data-testid="reject-file"
          disabled={requestSending}
          className={deleteBatchButton}
          onClick={() => openRejectModal(index)}
        />
      </>
    );
  };

  // Column Settings for the Table which lists the files in the Batch
  const columns = [
    {
      key: "column1",
      name: "File Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isMultiline: true,
      onRender: (item) => {
        return <div>{pathToFileName(item.name)}</div>;
      },
    },
    {
      key: "column2",
      name: "Download",
      minWidth: 125,
      maxWidth: 125,
      isResizable: true,
      onRender: (item) => {
        return (
          <div className={tableRowButton}>
            <DefaultButton
              text="Download"
              data-testid="download-file"
              onClick={() => downloadFile(item.name)}
            />
          </div>
        );
      },
    },
    {
      key: "column3",
      name: "Actions",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
      onRender: (item, index) => {
        return <div className={tableRowButton}>{getVerifyColumn(index)}</div>;
      },
    },
    {
      key: "column4",
      name: "Size",
      fieldName: "size",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{`${item.properties.contentLength / 1000} KB`}</div>;
      },
    },
  ];

  return (
    <div className={fileTableDiv}>
      <DetailsList
        compact={true}
        items={blobs}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        styles={{ root: tableStyling }}
        selectionMode={SelectionMode.none}
        constrainMode={ConstrainMode.unconstrained}
      />
    </div>
  );
};

export default FileReviewPivot;
