import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

export const pasteLinks = [
  {
    title: "My Pastes",
    icon: "Code",
    path: "/pastes",
  },
  {
    title: "Create a Paste",
    icon: "CodeEdit",
    path: "/createpaste",
  },
  {
    title: "Shared with me",
    icon: "FileCode",
    path: "/sharedpastes",
  },
];

const PasteNavGroup = () => {
  const rootProps = {
    title: "Pastes",
    icon: "PasteAsCode",
    path: pasteLinks[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={pasteLinks} />;
};

export default PasteNavGroup;
