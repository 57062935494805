import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton, Pivot, PivotItem } from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { sharedBatchesAPI } from "utils/endpoints";
import LoadingView from "../LoadingView";
import SharedBatchFilesPivot from "./SharedBatchFilesPivot";
import SharedBatchOverviewPivot from "./SharedBatchOverviewPivot";
import SharedUserList from "../SharedUserList";
import {
  baseDiv,
  batchDetailsPivot,
  batchPropertiesDiv,
  batchTitleFont,
  errorTextStyle,
  headerDiv,
} from "../Styles";
import { downloadToFiles, downloadToZip } from "batches/batchDownloader";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";

const SharedBatchDetails = () => {
  const { batchName } = useParams();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);

  const dispatchFunc = (type, text) => {
    dispatch(
      setMessage({
        type: type,
        message: text,
      })
    );
    dispatch(setShow(true));
  };

  const getFileSAS = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const sasPromises = batch.blobs.map((blob) => {
      const fileName = encodeURIComponent(blob.name);
      return callAuthorizedEndpoint(
        `${sharedBatchesAPI}/${batchName.trim()}/${fileName}`,
        token
      );
    });

    return Promise.all(sasPromises).then((responses) =>
      Promise.all(responses.map((r) => r.json()))
    );
  };

  const downloadFiles = async () => {
    const filesArr = await getFileSAS();
    downloadToFiles(filesArr);
  };

  const downloadZip = async () => {
    const filesArr = await getFileSAS();
    downloadToZip(filesArr, batchName, dispatchFunc);
  };

  const downloadMenuProps = {
    items: [
      {
        key: "downloadFiles",
        text: "Download Files",
        onClick: (ev, props) => downloadFiles(),
      },
      {
        key: "downloadZip",
        text: "Download as Zip",
        onClick: (ev, props) => downloadZip(),
      },
    ],
    directionalHintFixed: true,
  };

  const fetchBatch = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${sharedBatchesAPI}/${batchName.trim()}`, token)
      .then((response) => response.json())
      .then((batchObj) => {
        batchObj.blobs = batchObj.blobs
          ? batchObj.blobs.filter(
              (blob) => !blob.name.endsWith(".teleportal.sha512")
            )
          : [];
        setBatch(batchObj);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        })
      );
  }, [account, batchName, instance]);

  useEffect(() => {
    fetchBatch();
  }, [fetchBatch]);

  const batchView = batch ? (
    <>
      <div className={headerDiv}>
        <span className={batchTitleFont}>{batch.name}</span>
        <DefaultButton text="Download Batch" menuProps={downloadMenuProps} />
      </div>
      <div className={batchDetailsPivot}>
        <Pivot>
          <PivotItem headerText="Overview">
            <div className={batchPropertiesDiv}>
              <SharedBatchOverviewPivot
                batch={batch}
                batchName={batchName}
                setBatch={setBatch}
              />
              <SharedUserList batchName={batchName} batchObj={batch} />
            </div>
          </PivotItem>
          <PivotItem headerText="Files">
            <SharedBatchFilesPivot batch={batch} />
          </PivotItem>
        </Pivot>
      </div>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : batchView}</div>;
};

export default SharedBatchDetails;
