import { Icon, TextField, TooltipHost } from "@fluentui/react";
import EmployeePicker from "employeePicker/EmployeePicker";
import { getEmailErrorText, getTitleErrorText } from "../createFlowValidation";
import {
  card,
  createFlowCard,
  createInput,
  createLabel,
  createRequired,
  createRow,
  createSectionTitle,
  createTooltip,
} from "../Styles";

const BasicsStep = (props) => {
  const {
    cloud,
    title,
    setTitle,
    email,
    setEmail,
    secondaryContact,
    setSecondaryContact,
  } = props;

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Flow Details</div>
        <div className={createRow}>
          <div className={createLabel}>
            Title
            <TooltipHost
              content="Choose a descriptive name for your Flow to make it easier to identify it."
              id="Title"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="Title"
            onGetErrorMessage={(value) => getTitleErrorText(value)}
            onChange={(event, value) => setTitle(value)}
            value={title}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="title"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Destination Environment &nbsp;
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="Cloud"
            readOnly={true}
            value={cloud}
            className={createInput}
            ariaLabel="cloud"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            E-mail Address
            <TooltipHost
              content="Enter your Microsoft corporate e-mail address."
              id="Email"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="Email"
            onGetErrorMessage={(value) => getEmailErrorText(value)}
            onChange={(event, value) => setEmail(value.trim().toLowerCase())}
            value={email}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="email"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Additional Contacts
            <TooltipHost
              content="Enter any other people that will be given permissions to view this Flow"
              id="secondary"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
          </div>
          <EmployeePicker
            onChange={(items) => setSecondaryContact(items)}
            defaultSelectedItems={secondaryContact}
            className={createInput}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicsStep;
