import { registerIcons } from "@fluentui/react/lib/Styling";

export const registerAppIcons = () => {
  registerIcons({
    icons: {
      Accept: <span className="ms-Icon ms-Icon--Accept" />,
      AnalyticsView: <span className="ms-Icon ms-Icon--AnalyticsView" />,
      AzureLogo: <span className="ms-Icon ms-Icon--AzureIcon" />,
      BookAnswers: <span className="ms-Icon ms-Icon--BookAnswers" />,
      Cancel: <span className="ms-Icon ms-Icon--Cancel" />,
      CheckMark: <span className="ms-Icon ms-Icon--CheckMark" />,
      ChevronDown: <span className="ms-Icon ms-Icon--ChevronDown" />,
      ChevronDownSmall: <span className="ms-Icon ms-Icon--ChevronDownSmall" />,
      ChevronUpSmall: <span className="ms-Icon ms-Icon--ChevronUpSmall" />,
      CircleRing: <span className="ms-Icon ms-Icon--CircleRing" />,
      Clear: <span className="ms-Icon ms-Icon--Clear" />,
      CloudDownload: <span className="ms-Icon ms-Icon--CloudDownload" />,
      CloudFlow: <span className="ms-Icon ms-Icon--CloudFlow" />,
      CloudSearch: <span className="ms-Icon ms-Icon--CloudSearch" />,
      CloudUpload: <span className="ms-Icon ms-Icon--CloudUpload" />,
      Code: <span className="ms-Icon ms-Icon--Code" />,
      CodeEdit: <span className="ms-Icon ms-Icon--CodeEdit" />,
      Completed: <span className="ms-Icon ms-Icon--Completed" />,
      Contact: <span className="ms-Icon ms-Icon--Contact" />,
      CRMServices: <span className="ms-Icon ms-Icon--CRMServices" />,
      Delete: <span className="ms-Icon ms-Icon--Delete" />,
      DoubleChevronLeft: (
        <span className="ms-Icon ms-Icon--DoubleChevronLeft" />
      ),
      DoubleChevronRight: (
        <span className="ms-Icon ms-Icon--DoubleChevronRight" />
      ),
      Edit: <span className="ms-Icon ms-Icon--Edit" />,
      ErrorBadge: <span className="ms-Icon ms-Icon--ErrorBadge" />,
      FabricNewFolder: <span className="ms-Icon ms-Icon--FabricNewFolder" />,
      FabricUserFolder: <span className="ms-Icon ms-Icon--FabricUserFolder" />,
      FileCode: <span className="ms-Icon ms-Icon--FileCode" />,
      FolderList: <span className="ms-Icon ms-Icon--FolderList" />,
      Header: <span className="ms-Icon ms-Icon--Header" />,
      Help: <span className="ms-Icon ms-Icon--Help" />,
      Home: <span className="ms-Icon ms-Icon--Home" />,
      Info: <span className="ms-Icon ms-Icon--Info" />,
      NotImpactedSolid: <span className="ms-Icon ms-Icon--NotImpactedSolid" />,
      PasteAsCode: <span className="ms-Icon ms-Icon--PasteAsCode" />,
      PublicFolder: <span className="ms-Icon ms-Icon--PublicFolder" />,
      Ringer: <span className="ms-Icon ms-Icon--Ringer" />,
      RingerOff: <span className="ms-Icon ms-Icon--RingerOff" />,
      Settings: <span className="ms-Icon ms-Icon--Settings" />,
      StatusCircleCheckmark: (
        <span className="ms-Icon ms-Icon--StatusCircleCheckmark" />
      ),
      SummaryChart: <span className="ms-Icon ms-Icon--SummaryChart" />,
      Switch: <span className="ms-Icon ms-Icon--Switch" />,
      ToggleLeft: <span className="ms-Icon ms-Icon--ToggleLeft" />,
      ToggleRight: <span className="ms-Icon ms-Icon--ToggleRight" />,
      TVMonitor: <span className="ms-Icon ms-Icon--TVMonitor" />,
      UserFollowed: <span className="ms-Icon ms-Icon--UserFollowed" />,
      UserRemove: <span className="ms-Icon ms-Icon--UserRemove" />,
      VerifiedBrand: <span className="ms-Icon ms-Icon--VerifiedBrand" />,
      ViewDashboard: <span className="ms-Icon ms-Icon--ViewDashboard" />,
    },
  });
};
