import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";
import { useSelector } from "react-redux";

const ConnectionNavGroup = () => {
  const roles = useSelector((state) => state.mode.roles);

  const links = [];

  if (roles.dri) {
    links.push({
      title: "View All Connections",
      icon: "CloudSearch",
      path: "/connection",
    });
  }

  const rootProps = {
    title: "Connections",
    icon: "CloudUpload",
    path: links.length > 0 ? links[0].path : null,
  };

  return links.length > 0 ? (
    <NavSelectionGroup root={rootProps} links={links} />
  ) : null;
};

export default ConnectionNavGroup;
