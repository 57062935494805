import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerDiv = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
});

export const titleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const refreshButton = mergeStyles({
  marginLeft: "8px",
});

export const tableStyling = mergeStyles({
  overflowX: "auto",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  selectors: {
    "& [role=grid]": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    ".ms-DetailsHeader": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    ".ms-DetailsList": {
      maxHeight: "300px",
      overflowX: "scroll",
    },
  },
});

export const linkSpan = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgb(0, 69, 120)",
    },
  },
  cursor: "pointer",
  color: "rgb(0, 120, 212)",
  textDecoration: "underline",
});

export const pivotPadding = mergeStyles({
  padding: "0 24px 0 24px",
});

export const createPasteCard = mergeStyles({
  padding: "16px 8px 16px 8px",
  display: "flex",
  flexDirection: "column",
});

export const card = mergeStyles({
  padding: "20px 20px",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  flexGrow: "1",
});

export const cardLabels = mergeStyles({
  maxWidth: "800px",
});

export const textEditor = mergeStyles({
  height: "600px",
  border: "1px solid grey",
});

export const createPasteFooter = mergeStyles({
  display: "flex",
  flexDirection: "column",
  paddingTop: "16px",
  alignItems: "end",
});

export const createButton = mergeStyles({
  maxWidth: "80px",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

export const errorTextStyle = mergeStyles({
  position: "relative",
  top: "50%",
  left: "45%",
});

export const deletePasteButton = mergeStyles({
  selectors: {
    ":hover": {
      background: "#750b1c",
      borderColor: "#750b1c",
    },
    ":active": {
      background: "#610614",
      borderColor: "#610614",
    },
  },
  marginLeft: "8px",
  background: "#a4373a",
  borderColor: "#a4373a",
});

export const errorText = mergeStyles({
  color: "rgb(164, 38, 44)",
  fontWeight: "bold",
});

export const baseDiv = classNames(greyPage, base);
