import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminEgressAPI } from "utils/endpoints";
import LoadingView from "egressFlows/LoadingView";
import { useBoolean } from "@fluentui/react-hooks";
import {
  approveButton,
  baseDiv,
  errorTextStyle,
  headerDiv,
  pivotPadding,
  redText,
  titleFont,
} from "egressFlows/Styles";
import { setMessage, setShow } from "store/messageBarSlice";
import ReviewDetails from "./ReviewDetails";
import ApprovalUpdateModal from "./ApprovalUpdateModal";
import ApprovalConfirmModal from "./ApprovalConfirmModal";
import HistoricalTimeline from "egressFlows/HistoricalTimeline";

const ApproveEgressFlow = () => {
  const { flowGuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flow, setFlow] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [syncPeriod, setSyncPeriod] = useState(30);
  const [notes, setNotes] = useState(null);
  const [
    isUpdateModalOpen,
    { setTrue: showUpdateModal, setFalse: hideUpdateModal },
  ] = useBoolean(false);
  const [
    isApprovalModelOpen,
    { setTrue: showApproveModal, setFalse: hideApproveModal },
  ] = useBoolean(false);
  const [historyEvents, setHistoryEvents] = useState(null);

  const cachedFlowInfo = useSelector(
    (state) => state.egressFlows.approval_list
  ).find((flow) => flow.dataflowGuid === flowGuid);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const updateFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const body = {
      Notes: notes,
    };

    callAuthorizedEndpointWithBody(
      `${adminEgressAPI}/${flowGuid}/approval?cloud=${selectedCloud.project}`,
      token,
      "PATCH",
      body
    )
      .then(() => navigate("/ApproveEgress"))
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
    hideUpdateModal();
  };

  const approveFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const body = {
      Cluster: cluster,
      SyncPeriod: syncPeriod,
      Notes: notes,
    };

    callAuthorizedEndpointWithBody(
      `${adminEgressAPI}/${flowGuid}/approval?cloud=${selectedCloud.project}`,
      token,
      "POST",
      body
    )
      .then(() => navigate("/ApproveEgress"))
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
    hideApproveModal();
  };

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${adminEgressAPI}/${flowGuid}/approval?cloud=${selectedCloud.project}`,
      token
    )
      .then((response) => response.json())
      .then((response) => {
        setFlow(response.flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [account, instance, flowGuid, selectedCloud]);

  const showApproval = () => {
    setNotes(flow.notes);
    showApproveModal();
  };

  const showUpdate = () => {
    setNotes(flow.notes);
    showUpdateModal();
  };

  useEffect(() => {
    if (cachedFlowInfo) {
      setFlow(cachedFlowInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedFlowInfo, getFlow]);

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>
          <span className={redText}>[Approval Review] -&nbsp;</span>
          {flow.title}
        </span>
        <DefaultButton text="Update Notes" onClick={() => showUpdate()} />
        <PrimaryButton
          text="Approve Flow"
          onClick={() => showApproval()}
          className={approveButton}
        />
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <ReviewDetails flow={flow} />
          <ApprovalUpdateModal
            isModalOpen={isUpdateModalOpen}
            hideModal={hideUpdateModal}
            updateFlow={updateFlow}
            notes={notes}
            setNotes={setNotes}
          />
          <ApprovalConfirmModal
            isModalOpen={isApprovalModelOpen}
            hideModal={hideApproveModal}
            approveFlow={approveFlow}
            cluster={cluster}
            setCluster={setCluster}
            syncPeriod={syncPeriod}
            setSyncPeriod={setSyncPeriod}
            notes={notes}
            setNotes={setNotes}
          />
        </PivotItem>
        <PivotItem headerText="History">
          <HistoricalTimeline
            adminMode={true}
            flow={flow}
            events={historyEvents}
            setEvents={setHistoryEvents}
          />
        </PivotItem>
      </Pivot>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow details" /> : content}
    </div>
  );
};

export default ApproveEgressFlow;
