import * as React from "react";
import { useSelector } from "react-redux";
import NavSelectionGroup from "../NavSelectionGroup";

const BatchNavGroup = () => {
  const roles = useSelector((state) => state.mode.roles);

  const links = [];

  if (roles.dri || roles.approver) {
    links.push({
      title: "Approve Batches",
      icon: "NotImpactedSolid",
      path: "/batches",
    });
  }

  if (roles.batchVerifier) {
    links.push({
      title: "Review Batch Files",
      icon: "NotImpactedSolid",
      path: "/batchreview",
    });
  }

  const rootProps = {
    title: "Batches",
    icon: "PublicFolder",
    path: links.length > 0 ? links[0].path : null,
  };

  return links.length > 0 ? (
    <NavSelectionGroup root={rootProps} links={links} />
  ) : null;
};

export default BatchNavGroup;
