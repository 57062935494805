import * as React from "react";
import { Callout, Icon, Persona, Spinner, SpinnerSize } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { select } from "store/tentedSlice";
import {
  cloudToggleArrow,
  cloudToggleBase,
  cloudToggleDiv,
  cloudToggleText,
  dropdownIconStyle,
  cloudToggleSelection,
  cloudToggleSelectionBox,
  cloudToggleSelectionText,
  cloudToggleIcon,
  cloudToggleSelectionIcon,
} from "./Styles";
import { useNavigate } from "react-router-dom";

const CloudToggle = () => {
  const dispatch = useDispatch();

  // fields for the cloud toggle callout
  const [
    isCalloutVisible,
    { setTrue: setCalloutVisible, setFalse: setCalloutInvisible },
  ] = useBoolean(false);

  const buttonId = useId("callout-button");
  const navigate = useNavigate();

  // grab global states from redux store
  const tentedClouds = useSelector((state) => state.tentedClouds.list);
  const getCloudsStatus = useSelector((state) => state.tentedClouds.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Set the icon div
  const loadingSpinner = (
    <Spinner size={SpinnerSize.small} data-testid="loading-spinner" />
  );
  const coin = selectedCloud ? (
    <Persona
      imageInitials={selectedCloud.project.toUpperCase()}
      coinSize={24}
    />
  ) : (
    <></>
  );
  const icon = getCloudsStatus === "loading" ? loadingSpinner : coin;

  // Set the selected cloud name text
  const cloudName = selectedCloud ? selectedCloud.name.toUpperCase() : "";

  /**
   * Function to switch which destination cloud is selected
   * @param {json} cloud the destination cloud object
   */
  const selectCloud = React.useCallback(
    (cloud, nav = true) => {
      localStorage.setItem("cloud", JSON.stringify(cloud));

      if (selectedCloud.project === cloud.project) {
        return;
      }

      if (nav) {
        navigate("/");
      }

      dispatch(select(cloud));
    },
    [dispatch, navigate, selectedCloud?.project]
  );

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const cloudParam = urlParams.get("cloud");
    if (cloudParam) {
      const cloud = tentedClouds.find((c) => c.name === cloudParam);
      if (cloud) {
        selectCloud(cloud, false);
      }
    } else {
      const cloudFromStorage = localStorage.getItem("cloud");
      if (cloudFromStorage) {
        selectCloud(JSON.parse(cloudFromStorage), false);
      }
    }
  }, [selectCloud, selectedCloud, tentedClouds]);

  return (
    <div className={cloudToggleBase}>
      <div className={cloudToggleDiv}>
        <span className={cloudToggleIcon}>{icon}</span>
        <span className={cloudToggleText}>{cloudName}</span>
      </div>
      <button
        id={buttonId}
        onClick={isCalloutVisible ? setCalloutInvisible : setCalloutVisible}
        className={cloudToggleArrow}
        data-testid="show-callout"
        tabIndex={0}
        aria-label="cloud-toggle-button"
      >
        <Icon iconName="ChevronDown" className={dropdownIconStyle} />
      </button>
      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={setCalloutInvisible}
          isBeakVisible={false}
          data-testid="cloud-callout"
          setInitialFocus
        >
          {tentedClouds.map((cloud) => {
            return (
              <button
                key={cloud.project}
                onClick={() => {
                  selectCloud(cloud);
                  setCalloutInvisible();
                }}
                className={cloudToggleSelection}
                tabIndex={0}
              >
                <div className={cloudToggleSelectionBox}>
                  <span className={cloudToggleSelectionIcon}>
                    <Persona
                      imageInitials={cloud.project.toUpperCase()}
                      coinSize={24}
                    />
                  </span>
                  <span className={cloudToggleSelectionText}>
                    {cloud.name.toUpperCase()}
                  </span>
                </div>
              </button>
            );
          })}
        </Callout>
      )}
    </div>
  );
};

export default CloudToggle;
