import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
} from "utils/AuthorizedFetchCalls";
import {
  adminEgressAPI,
  adminEgressApprovalAPI,
  adminEgressVerifyAPI,
  egressFlowAPI,
} from "utils/endpoints";
import { setMessage, setShow } from "./messageBarSlice";
import { MessageBarType } from "@fluentui/react";

export const getFlows = createAsyncThunk(
  "egressFlow/getFlows",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${egressFlowAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const getAdminFlows = createAsyncThunk(
  "egressFlow/getAdminFlows",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${adminEgressAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const getVerifyFlows = createAsyncThunk(
  "egressFlow/getVerifyFlows",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${adminEgressVerifyAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const getApprovalFlows = createAsyncThunk(
  "egressFlow/getApprovalFlows",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${adminEgressApprovalAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const adminEnableFlowSync = createAsyncThunk(
  "egressFlow/adminEnableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(adminEnableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${adminEgressAPI}/${params.guid}/enable?cloud=${params.cloud}`,
      params.token,
      "POST",
      { Justification: params.justification }
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to enable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(adminDisableFlow(params.guid));
      });
  }
);

export const adminDisableFlowSync = createAsyncThunk(
  "egressFlow/adminDisableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(adminDisableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${adminEgressAPI}/${params.guid}/disable?cloud=${params.cloud}`,
      params.token,
      "POST",
      { Justification: params.justification }
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to disable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(adminEnableFlow(params.guid));
      });
  }
);

export const egressFlowSlice = createSlice({
  name: "egressFlow",
  initialState: {
    list: [],
    status: null,
    admin_list: [],
    admin_list_status: null,
    verify_list: [],
    verify_list_status: null,
    approval_list: [],
    approval_list_status: null,
  },
  reducers: {
    adminEnableFlow: (state, action) => {
      state.admin_list = state.admin_list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = true;
        }
        return flow;
      });
    },
    adminDisableFlow: (state, action) => {
      state.admin_list = state.admin_list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = false;
        }
        return flow;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFlows.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getFlows.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = "success";
    });
    builder.addCase(getFlows.rejected, (state, action) => {
      state.list = [];
      state.status = "failed";
    });
    builder.addCase(getAdminFlows.pending, (state, action) => {
      state.admin_list_status = "loading";
    });
    builder.addCase(getAdminFlows.fulfilled, (state, action) => {
      state.admin_list = action.payload;
      state.admin_list_status = "success";
    });
    builder.addCase(getAdminFlows.rejected, (state, action) => {
      state.admin_list = [];
      state.admin_list_status = "failed";
    });
    builder.addCase(getVerifyFlows.pending, (state, action) => {
      state.verify_list_status = "loading";
    });
    builder.addCase(getVerifyFlows.fulfilled, (state, action) => {
      state.verify_list = action.payload;
      state.verify_list_status = "success";
    });
    builder.addCase(getVerifyFlows.rejected, (state, action) => {
      state.verify_list = [];
      state.verify_list_status = "failed";
    });
    builder.addCase(getApprovalFlows.pending, (state, action) => {
      state.approval_list_status = "loading";
    });
    builder.addCase(getApprovalFlows.fulfilled, (state, action) => {
      state.approval_list = action.payload;
      state.approval_list_status = "success";
    });
    builder.addCase(getApprovalFlows.rejected, (state, action) => {
      state.approval_list = [];
      state.approval_list_status = "failed";
    });
  },
});

export const { adminEnableFlow, adminDisableFlow } = egressFlowSlice.actions;

export default egressFlowSlice.reducer;
