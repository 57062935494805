import { useAccount, useMsal } from "@azure/msal-react";
import { Callout, MessageBarType } from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminBatchesAPI, batchesAPI } from "utils/endpoints";
import { diffInfoCallout, historicalDiv, historyTitle } from "./Styles";
import { borderLine } from "notifications/Styles";
import { useBoolean } from "@fluentui/react-hooks";
import {
  generateActivityItems,
  generateDiffCallout,
  generateInfoList,
} from "./Utils";

const HistoricalTimeline = (props) => {
  const { adminMode, batchName, events, setEvents } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [targetId, setTarget] = useState(null);
  const [info, setInfo] = useState({});

  const showMoreInfo = (target, info) => {
    setTarget(target);
    setInfo(info);
    toggleIsCalloutVisible();
  };

  useEffect(() => {
    const getBatchHistory = async () => {
      const token = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      callAuthorizedEndpoint(
        `${adminMode ? adminBatchesAPI : batchesAPI}/${batchName}/history`,
        token
      )
        .then((response) => response.json())
        .then((res) => {
          const eventList = res.events.map((event) => ({
            ...event,
            eventDetails: JSON.parse(event.requestBody),
            creationDate: new Date(event.eventDate),
          }));

          const infoList = generateInfoList(eventList);

          setEvents(infoList);
        })
        .catch((response) =>
          response.text().then((text) => {
            dispatch(setMessage({ type: MessageBarType.error, message: text }));
            dispatch(setShow(true));
          })
        );
    };

    if (!events) {
      getBatchHistory();
    }
  }, [adminMode, account, instance, batchName, dispatch, events, setEvents]);

  return (
    <div className={historicalDiv}>
      <span className={historyTitle}>Event Timeline</span>
      <div className={borderLine} />
      {!events && <div>Fetching...</div>}
      {events && events.length === 0 ? (
        <div>No events recorded for this resource</div>
      ) : (
        generateActivityItems(events, showMoreInfo)
      )}
      {isCalloutVisible && (
        <Callout
          role="dialog"
          target={`#${targetId}`}
          gapSpace={0}
          onDismiss={toggleIsCalloutVisible}
          className={diffInfoCallout}
          setInitialFocus
        >
          <div>{generateDiffCallout(info)}</div>
        </Callout>
      )}
    </div>
  );
};

export default HistoricalTimeline;
