import * as React from "react";
import {
  baseDiv,
  headerDiv,
  batchTitleFont,
  errorTextStyle,
  headerButtonPadding,
  deleteBatchButton,
  batchDetailsPivot,
  batchPropertiesDiv,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { protectedResources } from "../../authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { batchesAPI } from "utils/endpoints";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { MessageBarType, Pivot, PivotItem } from "@fluentui/react";
import BatchOverviewPivot from "./BatchOverviewPivot";
import BatchFilesPivot from "./BatchFilesPivot";
import { useCallback } from "react";
import SharedUserList from "../SharedUserList";
import { useDispatch } from "react-redux";
import { setShow, setMessage } from "store/messageBarSlice";
import LoadingView from "../LoadingView";
import { downloadToFiles, downloadToZip } from "batches/batchDownloader";
import ConfirmationDialog, {
  DELETE_BATCH_DIALOG_TYPE,
  SUBMIT_BATCH_DIALOG_TYPE,
} from "./ConfirmationDialog";
import HistoricalTimeline from "batches/HistoricalTimeline";
import { getShortenedBatchName } from "batches/Utils";

export const BatchDetails = () => {
  const { batchName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [diaglogType, setDialogType] = useState("");
  const [historyEvents, setHistoryEvents] = useState(null);

  const dispatchFunc = (type, text) => {
    dispatch(
      setMessage({
        type: type,
        message: text,
      })
    );
    dispatch(setShow(true));
  };

  const getFileSAS = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const sasPromises = batch.blobs.map((blob) => {
      const fileName = encodeURIComponent(blob.name);
      return callAuthorizedEndpoint(
        `${batchesAPI}/${batchName.trim()}/${fileName}`,
        token
      );
    });

    return Promise.all(sasPromises).then((responses) =>
      Promise.all(responses.map((r) => r.json()))
    );
  };

  const downloadFiles = async () => {
    const filesArr = await getFileSAS();
    downloadToFiles(filesArr);
  };

  const downloadZip = async () => {
    const filesArr = await getFileSAS();
    downloadToZip(filesArr, batchName, dispatchFunc);
  };

  const downloadMenuProps = {
    items: [
      {
        key: "downloadFiles",
        text: "Download Files",
        onClick: (ev, props) => downloadFiles(),
      },
      {
        key: "downloadZip",
        text: "Download as Zip",
        onClick: (ev, props) => downloadZip(),
      },
    ],
    directionalHintFixed: true,
  };

  const showDeleteModal = () => {
    setDialogType(DELETE_BATCH_DIALOG_TYPE);
    setShowDialog(true);
  };

  const showSubmitModal = () => {
    setDialogType(SUBMIT_BATCH_DIALOG_TYPE);
    setShowDialog(true);
  };

  const submitBatch = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${batchesAPI}/${batchName.trim()}/submit`,
      token,
      "POST",
      {}
    )
      .then(() => {
        setBatch({ ...batch, state: "Submitted" });
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Batch was successfully submitted for review",
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setShowDialog(false);
      });
  };

  const deleteBatch = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${batchesAPI}/${batchName.trim()}`,
      token,
      "DELETE",
      {}
    )
      .then(() => {
        navigate("/batches");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          setShowDialog(false);
        })
      );
  };

  const fetchBatch = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${batchesAPI}/${batchName.trim()}`, token)
      .then((response) => response.json())
      .then((batchObj) => {
        batchObj.allBlobs = batchObj.blobs;
        batchObj.blobs = batchObj.blobs
          ? batchObj.blobs.filter(
              (blob) => !blob.name.endsWith(".teleportal.sha512")
            )
          : [];
        setBatch(batchObj);
        setLoading(false);
      })
      .catch((response) => {
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        });
      });
  }, [account, batchName, instance]);

  useEffect(() => {
    fetchBatch();
  }, [fetchBatch]);

  const batchView = batch ? (
    <>
      <div className={headerDiv}>
        <span className={batchTitleFont}>
          {getShortenedBatchName(batch.name)}
        </span>
        {(batch.state === "New" || batch.state === "Rejected") && (
          <PrimaryButton text="Submit for Approval" onClick={showSubmitModal} />
        )}
        <DefaultButton
          text="Download Batch"
          menuProps={downloadMenuProps}
          className={headerButtonPadding}
        />
        <PrimaryButton
          text="Delete Batch"
          onClick={showDeleteModal}
          className={deleteBatchButton}
        />
      </div>
      <div className={batchDetailsPivot}>
        <Pivot>
          <PivotItem headerText="Overview">
            <div className={batchPropertiesDiv}>
              <BatchOverviewPivot
                batch={batch}
                batchName={batchName}
                setBatch={setBatch}
              />
              <SharedUserList
                batchName={batchName}
                batchObj={batch}
                setBatch={setBatch}
              />
            </div>
          </PivotItem>
          <PivotItem headerText="Files">
            <BatchFilesPivot batch={batch} fetchBatch={fetchBatch} />
          </PivotItem>
          <PivotItem headerText="History">
            <HistoricalTimeline
              adminMode={false}
              batchName={batchName.trim()}
              events={historyEvents}
              setEvents={setHistoryEvents}
            />
          </PivotItem>
        </Pivot>
      </div>
      {showDialog && (
        <ConfirmationDialog
          type={diaglogType}
          show={showDialog}
          setShow={setShowDialog}
          primaryFunc={
            diaglogType === DELETE_BATCH_DIALOG_TYPE ? deleteBatch : submitBatch
          }
        />
      )}
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : batchView}</div>;
};

export default BatchDetails;
