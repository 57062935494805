import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  Dropdown,
  Icon,
  SpinButton,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import {
  getDataDestErrorText,
  getDataOriginErrorText,
  getDataUsageErrorText,
  getServiceNameErrorText,
  getServiceTreeErrorText,
  getUseCaseErrorText,
} from "ingressFlows/createFlowValidation";
import {
  card,
  createFlowCard,
  createInput,
  createLabel,
  createRequired,
  createRow,
  createSectionTitle,
  createTooltip,
} from "ingressFlows/Styles";
import EmployeePicker from "employeePicker/EmployeePicker";

export const dataTypeOptions = [
  { key: "OfficialBuilds", text: "Complex Data - Official Builds" },
  { key: "VHDs", text: "Complex Data - VHDs, Container Images" },
  {
    key: "OfficeDocuments",
    text: "Complex Data - Office Documents, PDF, Image/Video",
  },
  { key: "Scripts", text: "Simple Text - Scripts: PowerShell/Python" },
  {
    key: "JSON",
    text: "Simple Text - JSON/XML/plaintext files (notes & instructions)",
  },
  { key: "Database", text: "Database (SQL, CosmosDB)" },
  { key: "Other", text: "Other" },
];

export const cloudVaultOptions = [
  { key: "Yes", text: "Yes" },
  { key: "No", text: "No" },
];

export const manualUsageOptions = [
  { key: "Yes", text: "Yes" },
  { key: "No", text: "No" },
];

/**
 * Function to determine what the updated dataTypeList should be given a user clicked on an input (whether it be selected or deselected)
 * @param {Array<string>} dataTypesList array with the list of selected keys
 * @param {string} dataType the dataType key that was selected/deselectedc (clicked on)
 */
export const updateDataTypesList = (dataTypesList, dataType) => {
  const originalLength = dataTypesList.length;
  const updatedList = dataTypesList.filter((key) => key !== dataType);
  if (updatedList.length === originalLength) {
    updatedList.push(dataType);
  }
  return updatedList;
};

const JustificationStep = (props) => {
  const {
    useCase,
    setUseCase,
    dataTypes,
    setDataTypes,
    cloudVault,
    setCloudVault,
    dataOrigin,
    setDataOrigin,
    dataDest,
    setDataDest,
    dataUsage,
    setDataUsage,
    serviceName,
    setServiceName,
    serviceTreeId,
    setServiceTreeId,
    dailyData,
    setDailyData,
    maxData,
    setMaxData,
    manualUsage,
    setManualUsage,
    manualUsers,
    setManualUsers,
    manualJit,
    setManualJit,
    tented,
    setTented,
  } = props;

  const manualUsageToggle = (toggleKey) => {
    setManualUsage(toggleKey);
    setManualUsers([]);
    setManualJit(false);
    setTented(false);
  };

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>
            Use Case
            <TooltipHost
              content="Describe the use case for needing this Flow to replicate data"
              id="useCase"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="useCase"
            onGetErrorMessage={(value) => getUseCaseErrorText(value)}
            onChange={(event, value) => setUseCase(value)}
            value={useCase}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Use Case"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Data Types
            <TooltipHost
              content="Select all the data types that this Flow will service"
              id="dataTypes"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <ComboBox
            multiSelect={true}
            selectedKey={dataTypes}
            onChange={(event, option, index, value) =>
              setDataTypes(updateDataTypesList(dataTypes, option.key))
            }
            options={dataTypeOptions}
            errorMessage={
              dataTypes.length === 0
                ? "You must select one of the data type choices"
                : ""
            }
            className={createInput}
            ariaLabel="Data Type(s)"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Content from CloudVault?
            <TooltipHost
              content="Is this data currently stored in CloudVault?"
              id="cloudVault"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <Dropdown
            selectedKey={cloudVault}
            onChange={(event, option, index) => setCloudVault(option.key)}
            options={cloudVaultOptions}
            data-testid="cloudVault-dropdown"
            className={createInput}
            ariaLabel="Cloud Vault"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Origin of Data
            <TooltipHost
              content="Describe what the system of origin for where this data is from"
              id="dataOrigin"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="dataOrigin"
            multiline={true}
            resizable={false}
            onGetErrorMessage={(value) => getDataOriginErrorText(value)}
            onChange={(event, value) => setDataOrigin(value)}
            value={dataOrigin}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Data Origin"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Destination of Data
            <TooltipHost
              content="Describe what the destination of the data will be beyond the receiving Storage Account"
              id="dataDest"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="dataDest"
            multiline={true}
            resizable={false}
            onGetErrorMessage={(value) => getDataDestErrorText(value)}
            onChange={(event, value) => setDataDest(value)}
            value={dataDest}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Data Destination"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            How Will Data Be Used?
            <TooltipHost content="Describe how the data be used" id="dataUsage">
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="dataUsage"
            multiline={true}
            resizable={false}
            onGetErrorMessage={(value) => getDataUsageErrorText(value)}
            onChange={(event, value) => setDataUsage(value)}
            value={dataUsage}
            className={createInput}
            ariaLabel="Data Usage"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Service Name
            <TooltipHost content="Enter the name of your Service" id="service">
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="service"
            onGetErrorMessage={(value) => getServiceNameErrorText(value)}
            onChange={(event, value) => setServiceName(value)}
            value={serviceName}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Service Name"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Service Tree ID
            <TooltipHost
              content="Enter the Service Tree ID of your service"
              id="serviceTree"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="serviceTree"
            onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
            onChange={(event, value) => setServiceTreeId(value.trim())}
            value={serviceTreeId}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Service Tree ID"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Average Data in a Day (GB)
            <TooltipHost
              content="Enter the daily estimate on data sent (by GB) in a day"
              id="avgData"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <SpinButton
            value={dailyData}
            onChange={(event, value) => setDailyData(parseInt(value))}
            min={1}
            step={1}
            className={createInput}
            ariaLabel="Average Data in a Day"
            incrementButtonAriaLabel="Increment Average Data"
            decrementButtonAriaLabel="Decrement Average Data"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Max Data in a Day (GB)
            <TooltipHost
              content="Enter the max (99th percentile) estimate on data sent (by GB) in a day"
              id="maxData"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <SpinButton
            value={maxData}
            onChange={(event, value) => setMaxData(parseInt(value))}
            min={1}
            step={1}
            className={createInput}
            ariaLabel="Max Data in a Day"
            incrementButtonAriaLabel="Increment Max Data"
            decrementButtonAriaLabel="Decrement Max Data"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Manual Usage of Teleport?
            <TooltipHost
              content="Does your service have manual upload through the Teleport pipeline?"
              id="manualUsage"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <ChoiceGroup
            selectedKey={manualUsage}
            onChange={(event, option) => manualUsageToggle(option.key)}
            options={manualUsageOptions}
          />
        </div>
        {manualUsage === "Yes" && (
          <>
            <div className={createRow}>
              <div className={createLabel}>
                Manual Users List
                <TooltipHost
                  content="Enter any other people who would manually upload data"
                  id="manualUsers"
                >
                  <Icon iconName="Info" className={createTooltip} />
                </TooltipHost>
                <span className={createRequired}>*</span>
              </div>
              <EmployeePicker
                onChange={(items) => setManualUsers(items)}
                defaultSelectedItems={manualUsers}
                className={createInput}
              />
            </div>
            <div className={createRow}>
              <div className={createLabel}>
                Confirm JIT Usage
                <TooltipHost
                  content="Please confirm JIT will be used for manual transfers, whether they be access to the source Storage Container or any service which interacts with the Storage Account"
                  id="jitUsage"
                >
                  <Icon iconName="Info" className={createTooltip} />
                </TooltipHost>
                <span className={createRequired}>*</span>
              </div>
              <Checkbox
                checked={manualJit}
                onChange={(event, isChecked) => setManualJit(isChecked)}
                ariaLabel="Jit Usage Confirmation"
              />
            </div>
            <div className={createRow}>
              <div className={createLabel}>
                Confirm Users are Tented
                <TooltipHost
                  content="Please confirm all listed Manual Users are Tented."
                  id="tented"
                >
                  <Icon iconName="Info" className={createTooltip} />
                </TooltipHost>
                <span className={createRequired}>*</span>
              </div>
              <Checkbox
                checked={tented}
                onChange={(event, isChecked) => setTented(isChecked)}
                ariaLabel="Tented Confirmation"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JustificationStep;
