// Parses the timestamp string on the paste blob (UTC in the form yyyyMMddHHmmssfff) into
// something javascript can deal with to display the paste timestamp to user in a nice way w/local time zone
// Some legacy pastes didn't have correct timestamp format, hence the if/else.
export const getPasteDate = (timestamp) => {
  if (timestamp.length === 17) {
    const match = timestamp.match(
      /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{3})/
    );
    var dateString = `${match[1]}-${match[2]}-${match[3]}T${match[4]}:${match[5]}:${match[6]}.${match[7]}Z`;
    var date = new Date(dateString);
    var dateTimeFormat = new Intl.DateTimeFormat();
    var timeZone = dateTimeFormat.resolvedOptions().timeZone;
    return date.toLocaleString("en-US", { timeZone: timeZone });
  } else {
    return `${timestamp} (UTC)`; // for old pastes.
  }
};
