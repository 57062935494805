import { baseDiv, bodyText, headerTitle, titleFont } from "./Styles";

const AdminHome = () => {
  return (
    <div className={baseDiv}>
      <div className={headerTitle}>
        <h2 className={titleFont}>Cloud Transfer Service Portal Admin Mode</h2>
      </div>
      <div className={bodyText}>
        This is the Admin Mode of Portal, where you can perform various
        administrative actions. As a note, ALL actions and events will be logged
        for auditing.
      </div>
    </div>
  );
};

export default AdminHome;
