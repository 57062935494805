import * as React from "react";
import AnalyticsNavGroup from "./AnalyticsNavGroup";
import BatchNavGroup from "./BatchNavGroup";
import EgressNavGroup from "./EgressNavGroup";
import IngressNavGroup from "./IngressNavGroup";
import OverviewNavGroup from "./OverviewNavGroup";
import { navSelectionStyle } from "../Styles";
import PasteNavGroup from "./PasteNavGroup";

const NavSelection = () => {
  return (
    <div className={navSelectionStyle}>
      <OverviewNavGroup />
      <BatchNavGroup />
      <PasteNavGroup />
      <IngressNavGroup />
      <EgressNavGroup />
      <AnalyticsNavGroup />
    </div>
  );
};

export default NavSelection;
