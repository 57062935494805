import { useAccount, useMsal } from "@azure/msal-react";
import {
  Dropdown,
  Label,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  baseDiv,
  card,
  cardLabels,
  createButton,
  createPasteCard,
  createPasteFooter,
  errorText,
  headerDiv,
  pivotPadding,
  textEditor,
  titleFont,
} from "pastebin/Styles";
import { useNavigate } from "react-router-dom";
import Editor, { useMonaco } from "@monaco-editor/react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getNameErrorText,
  validContent,
  validName,
} from "pastebin/pasteValidation";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";
import EmployeePicker from "employeePicker/EmployeePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { protectedResources } from "authConfig";
import { pastesAPI } from "utils/endpoints";
import { setMessage, setShow } from "store/messageBarSlice";

const shareWithOptions = [
  { key: "me", text: "Only me" },
  { key: "recipients", text: "Specified Recipients" },
  { key: "everyone", text: "Everyone" },
];

export const CreatePaste = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const [selectedLanguageKey, setSelectedLanguageKey] = useState(null);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [shareSelection, setShareSelection] = useState(shareWithOptions[0].key);
  const [recipients, setRecipients] = useState([]);
  const [isSendingCreateRequest, setIsSendingCreateRequest] = useState(false);
  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      const languages = monaco?.languages.getLanguages().map((language) => {
        return {
          key: language.id,
          text: language.aliases ? language.aliases[0] : language.id,
        };
      });
      setSupportedLanguages(languages);
      setSelectedLanguageKey(languages[0].key);
    }
  }, [monaco]);

  const isCreateAllowed =
    validName(name) &&
    selectedLanguageKey &&
    validContent(content) &&
    selectedCloud;

  const createPaste = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const requestBody = {
      PasteName: name,
      PasteContent: content,
      PasteRecipients: recipients
        .map((contact) => getAliasFromUserPrincipalName(contact.upn))
        .join(","),
      PasteSyntaxLanguage: selectedLanguageKey,
      PasteDestinationCloud: selectedCloud.project,
      PasteShareableByURL: shareSelection === "everyone",
    };

    setIsSendingCreateRequest(true);

    callAuthorizedEndpointWithBody(`${pastesAPI}`, token, "POST", requestBody)
      .then(() => navigate("/pastes"))
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          setIsSendingCreateRequest(false);
        })
      );
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Create New Paste</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={createPasteCard}>
            <div className={card}>
              <div className={cardLabels}>
                <TextField
                  label="Paste Name"
                  onGetErrorMessage={(value) => getNameErrorText(value)}
                  onChange={(event, value) => setName(value)}
                  value={name}
                  validateOnLoad={false}
                />
                <br />
                <Dropdown
                  label="Language/Syntax Highlight"
                  options={supportedLanguages}
                  selectedKey={selectedLanguageKey}
                  onChange={(event, option, index) =>
                    setSelectedLanguageKey(option.key)
                  }
                  errorMessage={
                    selectedLanguageKey ? "" : "You must select a language"
                  }
                />
              </div>
              <br />
              <Label>Paste Content</Label>
              <div className={textEditor}>
                <Editor
                  language={selectedLanguageKey}
                  onChange={(value, event) => setContent(value)}
                />
              </div>
              <br />
              <div className={cardLabels}>
                <Dropdown
                  label="Share With"
                  options={shareWithOptions}
                  selectedKey={shareSelection}
                  onChange={(event, option, index) => {
                    setRecipients([]);
                    setShareSelection(option.key);
                  }}
                />
                {shareSelection === "recipients" && (
                  <>
                    <br />
                    <Label>Recipients</Label>
                    <EmployeePicker
                      itemLimit={5}
                      onChange={(items) => setRecipients(items)}
                    />
                  </>
                )}
              </div>
              <div className={createPasteFooter}>
                {content && !validContent(content) && (
                  <span className={errorText}>
                    Your paste cannot be longer than 25,000 characters
                  </span>
                )}
                <PrimaryButton
                  className={createButton}
                  disabled={!isCreateAllowed || isSendingCreateRequest}
                  onClick={() => createPaste()}
                  data-testid="create-paste"
                >
                  Create
                </PrimaryButton>
              </div>
            </div>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default CreatePaste;
