import { ActivityItem, Icon } from "@fluentui/react";
import { activityItemDiv, nameText } from "./Styles";
import { Link } from "react-router-dom";

const PASTE_CREATE_EVENT = "PASTE_CREATED";

const BATCH_CREATE_EVENT = "BATCH_CREATED";
const BATCH_UPDATED_EVENT = "BATCH_UPDATED";
const BATCH_SUBMITTED_EVENT = "BATCH_SUBMITTED";
const BATCH_DELETED_EVENT = "BATCH_DELETED";
const BATCH_ADD_SHARED_USER_EVENT = "BATCH_ADD_SHARED_USER";
const BATCH_DELETE_SHARED_USER_EVENT = "BATCH_DELETE_SHARED_USER";
const BATCH_APPROVED_EVENT = "BATCH_APPROVED";
const BATCH_REJECTED_EVENT = "BATCH_REJECTED";
const BATCH_UPDATE_EVENTS = {
  [BATCH_CREATE_EVENT]: 1,
  [BATCH_UPDATED_EVENT]: 1,
};

const batchInitialState = {
  Cloud: "",
  Justification: "",
  ServiceTreeId: "",
};

const INGRESS_FLOW_CREATE_EVENT = "INGRESS_FLOW_CREATED";
const INGRESS_FLOW_UPDATED_EVENT = "INGRESS_FLOW_UPDATED";
const INGRESS_FLOW_DELETE_EVENT = "INGRESS_FLOW_DELETED";
const INGRESS_FLOW_ADMIN_CONVERT_ACCESS = "INGRESS_FLOW_ADMIN_CONVERT_ACCESS";
const INGRESS_FLOW_ADMIN_DELETE = "INGRESS_FLOW_ADMIN_DELETE";
const INGRESS_FLOW_ADMIN_DISABLED = "INGRESS_FLOW_ADMIN_DISABLED";
const INGRESS_FLOW_ADMIN_ENABLED = "INGRESS_FLOW_ADMIN_ENABLED";
const INGRESS_FLOW_ADMIN_UPDATED = "INGRESS_FLOW_ADMIN_UPDATED";
const INGRESS_FLOW_APPROVED = "INGRESS_FLOW_APPROVED";
const INGRESS_FLOW_APPROVAL_UPDATE = "INGRESS_FLOW_APPROVAL_UPDATE";
const INGRESS_FLOW_UPDATE_EVENTS = {
  [INGRESS_FLOW_CREATE_EVENT]: 1,
  [INGRESS_FLOW_UPDATED_EVENT]: 1,
  [INGRESS_FLOW_ADMIN_UPDATED]: 1,
};

const ingressFlowInitialState = {
  AvgDailyData: "",
  Cloud: "",
  CloudVault: "",
  DataTypes: "",
  DataUse: "",
  DataflowGuid: "",
  Justification: "",
  Destination: "",
  Email: "",
  ManualUsage: "",
  ManualUserList: null,
  MaxData: "",
  Origin: "",
  PrimaryContact: "",
  ResourceGroup: "",
  SecondaryContact: "",
  ServiceName: "",
  StorageAccountName: "",
  StorageContainerName: "",
  StorageContainerRegion: "",
  StorageContainerType: "",
  StorageTenantId: "",
  ServiceTreeId: "",
  SubscriptionId: "",
  Title: "",
  UseCase: "",
};

const EGRESS_FLOW_ADMIN_DELETE = "EGRESS_FLOW_ADMIN_DELETE";
const EGRESS_FLOW_ADMIN_UPDATED = "EGRESS_FLOW_ADMIN_UPDATED";
const EGRESS_FLOW_APPROVAL_UPDATED = "EGRESS_FLOW_APPROVAL_UPDATED";
const EGRESS_FLOW_APPROVED = "EGRESS_FLOW_APPROVED";
const EGRESS_FLOW_CREATED = "EGRESS_FLOW_CREATED";
const EGRESS_FLOW_DELETED = "EGRESS_FLOW_DELETED";
const EGRESS_FLOW_UPDATED = "EGRESS_FLOW_UPDATED";
const EGRESS_FLOW_VERIFIED = "EGRESS_FLOW_VERIFIED";
const EGRESS_FLOW_VERIFY_UPDATE = "EGRESS_FLOW_VERIFY_UPDATE";
const EGRESS_FLOW_ADMIN_DISABLED = "EGRESS_FLOW_ADMIN_DISABLED";
const EGRESS_FLOW_ADMIN_ENABLED = "EGRESS_FLOW_ADMIN_ENABLED";
const EGRESS_FLOW_UPDATE_EVENTS = {
  [EGRESS_FLOW_ADMIN_UPDATED]: 1,
  [EGRESS_FLOW_CREATED]: 1,
  [EGRESS_FLOW_UPDATED]: 1,
};

const egressFlowInitialState = {
  Cloud: "",
  DataflowGuid: "",
  Email: "",
  PrimaryContact: "",
  ResourceGroup: "",
  SecondaryContact: "",
  ServiceName: "",
  StorageAccountName: "",
  StorageContainerName: "",
  StorageContainerRegion: "",
  StorageContainerType: "",
  StorageTenantId: "",
  ServiceTreeId: "",
  SubscriptionId: "",
  Title: "",
};

export const EVENT_TO_ICON_MAPPING = {
  [BATCH_CREATE_EVENT]: "FabricNewFolder",
  [BATCH_UPDATED_EVENT]: "Edit",
  [BATCH_SUBMITTED_EVENT]: "PublicFolder",
  [BATCH_DELETED_EVENT]: "Delete",
  [BATCH_ADD_SHARED_USER_EVENT]: "UserFollowed",
  [BATCH_DELETE_SHARED_USER_EVENT]: "UserRemove",
  [BATCH_APPROVED_EVENT]: "CheckMark",
  [BATCH_REJECTED_EVENT]: "Cancel",
  [PASTE_CREATE_EVENT]: "CodeEdit",
  [INGRESS_FLOW_CREATE_EVENT]: "CloudFlow",
  [INGRESS_FLOW_UPDATED_EVENT]: "Edit",
  [INGRESS_FLOW_ADMIN_CONVERT_ACCESS]: "Switch",
  [INGRESS_FLOW_ADMIN_DELETE]: "Delete",
  [INGRESS_FLOW_ADMIN_DISABLED]: "ToggleLeft",
  [INGRESS_FLOW_ADMIN_ENABLED]: "ToggleRight",
  [INGRESS_FLOW_ADMIN_UPDATED]: "Edit",
  [INGRESS_FLOW_APPROVED]: "CheckMark",
  [INGRESS_FLOW_DELETE_EVENT]: "Delete",
  [INGRESS_FLOW_APPROVAL_UPDATE]: "Edit",
  [EGRESS_FLOW_ADMIN_DELETE]: "Delete",
  [EGRESS_FLOW_ADMIN_UPDATED]: "Edit",
  [EGRESS_FLOW_APPROVAL_UPDATED]: "Edit",
  [EGRESS_FLOW_APPROVED]: "CheckMark",
  [EGRESS_FLOW_CREATED]: "CloudDownload",
  [EGRESS_FLOW_DELETED]: "Delete",
  [EGRESS_FLOW_UPDATED]: "Edit",
  [EGRESS_FLOW_VERIFIED]: "CheckMark",
  [EGRESS_FLOW_VERIFY_UPDATE]: "Edit",
  [EGRESS_FLOW_ADMIN_DISABLED]: "ToggleLeft",
  [EGRESS_FLOW_ADMIN_ENABLED]: "ToggleRight",
};

const generateActivityDescription = (event, currIndex, showMoreInfo) => {
  if (event.eventName === BATCH_CREATE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === BATCH_UPDATED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === BATCH_SUBMITTED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;submitted Batch for approval</span>
      </div>
    );
  } else if (event.eventName === BATCH_DELETED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;deleted Batch</span>
      </div>
    );
  } else if (event.eventName === BATCH_ADD_SHARED_USER_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;added&nbsp;</span>
        <span key={3} className={nameText}>
          {event.eventDetails.User}
        </span>
        <span key={4}>&nbsp;as a shared user to the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_DELETE_SHARED_USER_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;removed&nbsp;</span>
        <span key={3} className={nameText}>
          {event.eventDetails.User}
        </span>
        <span key={4}>&nbsp;as a shared user to the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_APPROVED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_REJECTED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;rejected the Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === PASTE_CREATE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Paste&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          (see more details)
        </Link>
      </div>
    );
  }
  if (event.eventName === INGRESS_FLOW_CREATE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_UPDATED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_CONVERT_ACCESS) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;convert access method for Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_DELETE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;hard deleted Ingress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_DISABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;disabled Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_ENABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;enabled Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;edited Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_APPROVED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_DELETE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;soft-deleted Ingress Flow</span>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_APPROVAL_UPDATE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Ingress Flow in approval state</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  }
  if (event.eventName === EGRESS_FLOW_ADMIN_DELETE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;hard-deleted Egress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_APPROVAL_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>
          &nbsp;updated Egress Flow still in Approval state&nbsp;
        </span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_APPROVED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_CREATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Egress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_DELETED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;soft-deleted Egress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Egress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_VERIFIED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;verified Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_ENABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;enabled Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_DISABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;disabled Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_VERIFY_UPDATE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>
          &nbsp;updated Egress Flow still in Verification state&nbsp;
        </span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  }
};

export const filterAdminList = (events) => {
  const result = [];

  for (let i = 0; i < events.length; i++) {
    if (i < events.length) {
      result.push(events[i]);
    }

    if (i + 1 >= events.length) {
      break;
    }

    if (
      events[i].eventName === events[i + 1].eventName &&
      events[i].isAdminEvent &&
      !events[i + 1].isAdminEvent
    ) {
      i++;
    }
  }

  return result;
};

const getDiffInfo = (currState, newObj) => {
  const diffInfo = {};

  Object.keys(newObj).forEach((key) => {
    if (
      currState.hasOwnProperty(key) &&
      newObj[key] !== null &&
      currState[key] !== newObj[key]
    ) {
      diffInfo[key] = [currState[key], newObj[key]];
      currState[key] = newObj[key];
    }
  });

  return diffInfo;
};

export const convertDateToLocaleString = (date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const generateInfoList = (eventList, type) => {
  const events = filterAdminList(eventList);

  const infoList = new Array(events.length);

  let currState = {};
  let updatingEvents = {};

  switch (type) {
    case "BATCH":
      currState = Object.assign({}, batchInitialState);
      updatingEvents = BATCH_UPDATE_EVENTS;
      break;
    case "PASTE":
      break;
    case "INGRESS_FLOW":
      currState = Object.assign({}, ingressFlowInitialState);
      updatingEvents = INGRESS_FLOW_UPDATE_EVENTS;
      break;
    case "EGRESS_FLOW":
      currState = Object.assign({}, egressFlowInitialState);
      updatingEvents = EGRESS_FLOW_UPDATE_EVENTS;
      break;
    default:
      break;
  }

  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];

    if (updatingEvents[event.eventName]) {
      event.diffInfo = getDiffInfo(currState, event.eventDetails);
    }

    infoList[i] = event;
  }

  return events;
};

export const generateActivityItems = (events, showMoreInfo) => {
  return events?.map((event, currIndex) => (
    <div key={currIndex}>
      <ActivityItem
        activityDescription={generateActivityDescription(
          event,
          currIndex,
          showMoreInfo
        )}
        activityIcon={
          <Icon iconName={EVENT_TO_ICON_MAPPING[event.eventName]} />
        }
        timeStamp={convertDateToLocaleString(event.creationDate)}
        className={currIndex !== 0 ? activityItemDiv : null}
      />
    </div>
  ));
};

export const generateDiffCallout = (diffObj) => {
  return (
    <div>
      <div>{`{`}</div>
      {Object.keys(diffObj).map((key) => {
        if (Array.isArray(diffObj[key]) && diffObj[key].length === 2) {
          return (
            <div
              key={key}
            >{`${key}: ${diffObj[key][0]} -> ${diffObj[key][1]}`}</div>
          );
        } else {
          return <div key={key}>{`${key}: ${diffObj[key]}`}</div>;
        }
      })}
      <div>{`}`}</div>
    </div>
  );
};
