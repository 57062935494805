import { useAccount, useMsal } from "@azure/msal-react";
import { MessageBarType } from "@fluentui/react";
import { protectedResources } from "authConfig";
import { linkSpan, tableStyling } from "connections/Styles";
import PaginationTable from "paginationTable/Table";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminConnectionAPI } from "utils/endpoints";
import {
  getResourceGroup,
  getSubscriptionId,
} from "utils/resourceProviderUtils";

const FlowList = (props) => {
  const { connection, flows, setFlows } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const subscriptionId = getSubscriptionId(connection.id);
  const resourceGroup = getResourceGroup(connection.id);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      key: "column1",
      name: "Name",
      onRender: (item) => {
        return (
          <Link
            className={linkSpan}
            to={`/flow/${getSubscriptionId(item.id)}/${getResourceGroup(
              item.id
            )}/${connection.name}/${item.name}`}
          >
            {item.name}
          </Link>
        );
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Flow Guid",
      onRender: (item) => {
        return <div>{item.properties.flowId}</div>;
      },
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Link Status",
      onRender: (item) => {
        return <div>{item.properties.linkStatus}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Flow Type",
      onRender: (item) => {
        return <div>{item.properties.flowType}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Location",
      fieldName: "location",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const getConnectionFlows = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    setLoading(true);
    callAuthorizedEndpoint(
      `${adminConnectionAPI}/${subscriptionId}/${resourceGroup}/${connection.name}/flows`,
      token
    )
      .then((response) => response.json())
      .then((flows) => {
        setFlows(flows);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  }, [
    instance,
    account,
    subscriptionId,
    resourceGroup,
    connection.name,
    setFlows,
    dispatch,
  ]);

  useEffect(() => {
    if (flows.length < 1) {
      getConnectionFlows();
    }
  }, [flows.length, getConnectionFlows]);

  return (
    <PaginationTable
      items={flows}
      columns={columns}
      tableStyling={tableStyling}
      loading={loading}
    />
  );
};

export default FlowList;
