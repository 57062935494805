import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "./Styles";

const ConfirmResyncModal = (props) => {
  const {
    isModalOpen,
    hideModal,
    flowId,
    cluster,
    syncStartTime,
    syncEndTime,
    resyncFlow,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Resync Flow Confirmation</div>
      <div className={modalBodyPadding}>
        <div>
          <h6>
            <b>
              You are about Resync this Flow. This action will be logged.
              Continue?
            </b>
          </h6>
        </div>
        <div>
          <b>{`Flow ID: `}</b>
          {flowId}
        </div>
        <div>
          <b>{`Cluster: `}</b>
          {cluster?.key}
        </div>
        <div>
          <b>{`Sync Start Time: `}</b>
          {syncStartTime}
        </div>
        <div>
          <b>{`Sync End Time: `}</b>
          {syncEndTime}
        </div>
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => resyncFlow()}
          className={modalButtons}
          data-testid="confirm-process-button"
        >
          Confirm
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ConfirmResyncModal;
