import * as React from "react";
import { baseDiv, comingSoon } from "./Styles";
export const Dashboard = () => {
  return (
    <div className={baseDiv}>
      <span className={comingSoon}>Coming Soon!</span>
    </div>
  );
};
export default Dashboard;
