import { useAccount, useMsal } from "@azure/msal-react";
import { MessageBarType, PrimaryButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { protectedResources } from "authConfig";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Papa from "papaparse";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminOpsAPI } from "utils/endpoints";
import {
  baseDiv,
  body,
  footer,
  headerDiv,
  primaryActionButton,
  titleFont,
  whitebg,
} from "./Styles";
import { validateRows } from "./utils";
import ConfirmBatchResendModal from "./ConfirmBatchResendModal";

const BatchResend = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [requests, setRequests] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [parsing, setParsing] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const buttonEnabled = requests.length > 0;

  const resendAll = async () => {
    const totalRequests = requests.length;
    let successes = 0;
    let failures = 0;

    const incrementSuccess = () => successes++;
    const incrementFailure = () => failures++;
    const checkRequests = () => {
      if (successes + failures === totalRequests) {
        setRequestPending(false);
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: `Successfully triggered ${successes} out of ${totalRequests} requests.`,
          })
        );
        dispatch(setShow(true));
      }
    };

    setRequestPending(true);
    hideModal();

    for (const request of requests) {
      const token = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );

      const payload = {
        cluster: request.cluster,
        flowId: request.flowGuid,
        objectHash: request.objHash,
      };

      callAuthorizedEndpointWithBody(
        `${adminOpsAPI}/resend/batch`,
        token,
        "POST",
        payload
      )
        .then(() => incrementSuccess())
        .catch((response) =>
          response.text().then((text) => {
            console.error(text);
            incrementFailure();
          })
        )
        .finally(() => checkRequests());
    }
  };

  const getValidRows = (requests) => {
    const validRequests = validateRows(requests);
    setRequests(validRequests);

    if (validRequests.length === 0) {
      dispatch(
        setMessage({
          type: MessageBarType.error,
          message: "Found no valid requests",
        })
      );
      dispatch(setShow(true));
    }
  };

  const handleFileInput = (e) => {
    const csvFile = e.target.files[0];
    setParsing(true);

    try {
      Papa.parse(csvFile, {
        delimiter: ",",
        skipEmptyLines: true,
        complete: function (results) {
          getValidRows(results.data);
          setParsing(false);
        },
      });
    } catch (error) {
      console.error(error);
      setParsing(false);
    }
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Batch Resend</span>
      </div>
      <div className={whitebg}>
        <div className={body}>
          <div>
            <h6>
              Upload a CSV file with each line triggering a flow object resend
            </h6>
          </div>
          <div>
            <h6>
              Format: <i>clusterName</i>,<i>flowGuid</i>,<i>objectHash</i>
            </h6>
          </div>
          <br />
          <input
            type="file"
            accept=".csv"
            data-testid="file-input"
            disabled={parsing}
            onChange={handleFileInput}
            onClick={(event) => {
              event.target.value = null;
              setRequests([]);
            }}
          />
          {requests.length > 0 && (
            <div>Found {requests.length} valid requests.</div>
          )}
          <div className={footer}>
            <PrimaryButton
              className={primaryActionButton}
              disabled={!buttonEnabled || requestPending}
              data-testid="batchResend-button"
              onClick={() => showModal()}
            >
              {requestPending ? "Loading..." : "Resend All"}
            </PrimaryButton>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmBatchResendModal
          {...{
            isModalOpen,
            hideModal,
            requests,
            resendAll,
          }}
        />
      )}
    </div>
  );
};

export default BatchResend;
