import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerDiv = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
});

export const titleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const whitebg = mergeStyles({
  width: "50%",
  margin: "16px 32px",
  padding: "8px 16px 16px 16px",
  display: "flex",
  flexDirection: "row",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
});

export const body = mergeStyles({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  selectors: {
    ".ms-TextField": {
      width: "50%",
    },

    ".ms-Dropdown-container": {
      width: "50%",
    },
  },
});

export const paddingTop = mergeStyles({
  paddingTop: "20px",
});

export const footer = mergeStyles({
  display: "flex",
  flexDirection: "column",
  paddingTop: "32px",
});

export const primaryActionButton = mergeStyles({
  maxWidth: "80px",
});

export const runIdStyle = mergeStyles({
  paddingTop: "20px",
  width: "50%",
});

export const spinInputStyle = mergeStyles({
  paddingTop: "20px",
  width: "50%",
});

export const modalContainer = mergeStyles({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "stretch",
});

export const modalHeader = mergeStyles({
  flex: "1 1 auto",
  borderTop: "4px solid rgb(0, 120, 212)",
  color: "rgb(50, 49, 48)",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  padding: "12px 12px 14px 24px",
  fontSize: "20px",
});

export const modalBodyPadding = mergeStyles({
  padding: "10px 24px 10px 24px",
  overflowX: "hidden",
});

export const modalFooter = mergeStyles({
  padding: "30px 12px 12px 12px",
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
  alignItems: "end",
});

export const modalButtons = mergeStyles({
  marginLeft: "auto",
});

export const padTop = mergeStyles({
  marginTop: "20px",
});

export const cancelButton = mergeStyles({
  marginLeft: "8px",
});

export const splitPane = mergeStyles({
  display: "flex",
  height: "100%",
  outline: "none",
  overflow: "hidden",
  flexDirection: "row",
  flex: "1 1 0%",
  userSelect: "text",
});

export const queryPane = mergeStyles({
  outline: "none",
  width: "320px",
  flexGrow: "0",
  display: "flex",
  flexDirection: "column",
  marginLeft: "12px",
  marginRight: "12px",
  paddingLeft: "10px",
  paddingRight: "10px",
});

export const queryTitleDiv = mergeStyles({
  paddingTop: "12px",
  display: "flex",
  flexDirection: "row",
});

export const resizeLine = mergeStyles({
  display: "block",
  opacity: "1",
  background:
    "linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)) 2px 0px / 1px 100% no-repeat content-box content-box",
  borderRightWidth: "3px !important",
  borderLeftWidth: "3px !important",
  pointerEvents: "auto",
  position: "relative",
  width: "11px",
  margin: "0 -5px",
  borderLeft: "5px solid rgba(255,255,255,0)",
  borderRight: "5px solid rgba(255,255,255,0)",
  zIndex: "1",
  boxSizing: "border-box",
});

export const spinnerCentered = mergeStyles({
  flexGrow: "1",
  position: "relative",
  top: "40%",
});

export const eventListDiv = mergeStyles({
  display: "flex",
  flexDirection: "column",
  padding: "12px",
  overflowY: "scroll",
});

export const card = mergeStyles({
  selectors: {
    ":hover": {
      transition: "all .2s ease-in-out",
      boxShadow: "0 5px 10px rgb(0 0 0 / 14%)",
    },
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "center",
  backgroundColor: "#fff",
  borderRadius: "6px",
  boxShadow: "0 2px 5px rgb(0 0 0 / 14%)",
  color: "#4c4c51",
  position: "relative",
  border: "1px solid transparent",
  padding: "10px 24px",
  cursor: "pointer",
  margin: "10px 0px",
});

export const cardFooter = mergeStyles({
  [`.${card}:hover &`]: {
    textDecoration: "underline",
  },
  fontSize: "14px",
  fontWeight: "600",
  color: "#0062ad",
});

export const resourceTitleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
});

export const titlePadding = mergeStyles({
  paddingTop: "10px",
});

export const historicalDiv = mergeStyles({
  marginRight: "15%",
  marginLeft: "15%",
  marginTop: "32px",
  marginBottom: "32px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
});

export const historyTitle = mergeStyles({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.2125rem",
  lineHeight: "20px",
});

export const borderLine = mergeStyles({
  height: "1px",
  background: "#EDEBE9",
  margin: "10px 0px",
});

export const nameText = mergeStyles({
  fontWeight: "bold",
});

export const activityItemDiv = mergeStyles({
  marginTop: "20px",
});

export const diffInfoCallout = mergeStyles({
  minWidth: 320,
  maxWidth: "90%",
  padding: "20px 24px",
});

export const eventExpandedPane = mergeStyles({
  flexGrow: "1",
  overflowY: "scroll",
});

export const baseDiv = classNames(greyPage, base);
export const titleWithPadding = classNames(resourceTitleFont, titlePadding);
