import * as React from "react";
import { baseDiv, dashboardDiv } from "dashboard/Styles";
import TentedCheckCard from "./TentedCheckCard";
import BadAccessCheckCard from "./BadAccessCheckCard";

const AdminDashboard = () => {
  return (
    <div className={baseDiv}>
      <div className={dashboardDiv}>
        <TentedCheckCard />
        <BadAccessCheckCard />
      </div>
    </div>
  );
};

export default AdminDashboard;
