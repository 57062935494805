import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { authorizedClustersAPI } from "utils/endpoints";

export const getClusters = createAsyncThunk(
  "cluster/getClusters",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(authorizedClustersAPI, token)
      .then((res) => res.json())
      .then((obj) => {
        const clusters = obj.clusters;
        return clusters;
      });
  }
);

export const clusterSlice = createSlice({
  name: "cluster",
  initialState: {
    list: {},
    status: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getClusters.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getClusters.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = "success";
    });
    builder.addCase(getClusters.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default clusterSlice.reducer;
