import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import PaginationTable from "paginationTable/Table";
import {
  baseDiv,
  headerDiv,
  refreshButton,
  tableStyling,
  titleFont,
} from "connections/Styles";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetFlows } from "store/resourceProviderSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { linkSpan } from "flows/Styles";
import { Link } from "react-router-dom";
import {
  getConnectionName,
  getResourceGroup,
  getSubscriptionId,
} from "utils/resourceProviderUtils";

const Flows = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const flows = useSelector((state) => state.resourceProvider.admin_flows_list);
  const fetchingFlows = useSelector(
    (state) => state.resourceProvider.fetching_admin_flow_list
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const columns = [
    {
      key: "column1",
      name: "Name",
      onRender: (item) => {
        return (
          <Link
            className={linkSpan}
            to={`/flow/${getSubscriptionId(item.id)}/${getResourceGroup(
              item.id
            )}/${getConnectionName(item.id)}/${item.name}`}
          >
            {item.name}
          </Link>
        );
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Flow Guid",
      onRender: (item) => {
        return <div>{item.properties.flowId}</div>;
      },
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Link Status",
      onRender: (item) => {
        return <div>{item.properties.linkStatus}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Flow Type",
      onRender: (item) => {
        return <div>{item.properties.flowType}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Location",
      fieldName: "location",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of Flow List
   */
  const getFlows = useCallback(async () => {
    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(adminGetFlows(accessToken));
  }, [account, instance, dispatch]);

  /**
   * On start, retrieve flow list
   */
  useEffect(() => {
    getFlows();
  }, [getFlows]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>All Flows</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getFlows()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={fetchingFlows === "loading" || selectedCloud === null}
      />
    </div>
  );
};

export default Flows;
