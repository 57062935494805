const api = "/api";

export const profileAPI = `${api}/profile`;

export const batchesAPI = `${api}/batches`;

export const sharedBatchesAPI = `${api}/sharedbatches`;

export const pastesAPI = `${api}/pastes`;
export const sharedPastesAPI = `${api}/sharedPastes`;

export const authorizedAPI = `${api}/authorized`;
export const authorizedUserInfoAPI = `${authorizedAPI}/userinfo`;
export const authorizedCloudsAPI = `${authorizedAPI}/clouds`;
export const authorizedRolesAPI = `${authorizedAPI}/roles`;
export const authorizedClustersAPI = `${authorizedAPI}/clusters`;

export const complianceAPI = `${api}/compliance`;
export const aupAPI = `${complianceAPI}/aup`;
export const userAupAPI = `${aupAPI}/user`;
export const ingressFlowAupAPI = `${aupAPI}/teleport`;
export const egressFlowAupAPI = `${aupAPI}/egress`;
export const batchApproverAupAPI = `${aupAPI}/approver`;

export const flowsAPI = `${api}/flows`;
export const validateFlowAPI = `${flowsAPI}/checkaccess`;
export const ingressFlowAPI = `${flowsAPI}/ingress`;
export const egressFlowAPI = `${flowsAPI}/egress`;

export const analyticsAPI = `${api}/analytics`;

export const adminAPI = `${api}/admin`;
export const adminBatchesAPI = `${adminAPI}/batches`;
export const approvalBatchesAPI = `${adminBatchesAPI}/approvals`;
export const fileReviewBatchesAPI = `${adminBatchesAPI}/filereview`;
export const adminFlowsAPI = `${adminAPI}/flows`;
export const adminIngressAPI = `${adminFlowsAPI}/ingress`;
export const adminApprovalIngressAPI = `${adminIngressAPI}/approvals`;
export const adminEgressAPI = `${adminFlowsAPI}/egress`;
export const adminEgressVerifyAPI = `${adminEgressAPI}/verify`;
export const adminEgressApprovalAPI = `${adminEgressAPI}/approval`;
export const adminOpsAPI = `${adminAPI}/ops`;
export const adminDashboardAPI = `${adminAPI}/dashboard`;
export const adminRpAPI = `${adminAPI}/rp`;
export const adminPipelineAPI = `${adminRpAPI}/pipeline`;
export const adminConnectionAPI = `${adminRpAPI}/connection`;
export const adminFlowAPI = `${adminRpAPI}/flow`;

export const notificationAPI = `${api}/notification`;

const MOCK_GRAPH_ENDPOINT = "https://graph.microsoft.test/";
const DEV_GRAPH_ENDPOINT = "https://graph.microsoft.com/";
const AME_GRAPH_ENDPOINT = "https://graph.microsoft.com/";
const FF_GRAPH_ENDPOINT = "https://graph.microsoft.us/";
const MC_GRAPH_ENDPOINT = "https://microsoftgraph.chinacloudapi.cn/";
export const GRAPH_ENDPOINT =
  process.env.NODE_ENV === "test"
    ? MOCK_GRAPH_ENDPOINT
    : process.env.NODE_ENV === "development"
    ? DEV_GRAPH_ENDPOINT
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ame"
      ? AME_GRAPH_ENDPOINT
      : process.env.REACT_APP_BUILD_MODE === "ff"
      ? FF_GRAPH_ENDPOINT
      : process.env.REACT_APP_BUILD_MODE === "mc"
      ? MC_GRAPH_ENDPOINT
      : DEV_GRAPH_ENDPOINT
    : MOCK_GRAPH_ENDPOINT;

const MOCK_MY_ACCOUNT_LINK = "https://myaccount.microsoft.test/?ref=MeControl";
const DEV_MY_ACCOUNT_LINK = "https://myaccount.microsoft.com/?ref=MeControl";
const AME_MY_ACCOUNT_LINK = "https://myaccount.microsoft.com/?ref=MeControl";
const FF_MY_ACCOUNT_LINK = "https://myaccount.microsoft.us/?ref=MeControl";
const MC_MY_ACCOUNT_LINK = "https://myaccount.microsoft.cn/?ref=MeControl";
export const MY_ACCOUNT_LINK =
  process.env.NODE_ENV === "test"
    ? MOCK_MY_ACCOUNT_LINK
    : process.env.NODE_ENV === "development"
    ? DEV_MY_ACCOUNT_LINK
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ame"
      ? AME_MY_ACCOUNT_LINK
      : process.env.REACT_APP_BUILD_MODE === "ff"
      ? FF_MY_ACCOUNT_LINK
      : process.env.REACT_APP_BUILD_MODE === "mc"
      ? MC_MY_ACCOUNT_LINK
      : DEV_MY_ACCOUNT_LINK
    : MOCK_MY_ACCOUNT_LINK;
