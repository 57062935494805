import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import {
  approvalBatchesAPI,
  batchesAPI,
  fileReviewBatchesAPI,
} from "utils/endpoints";

export const getBatches = createAsyncThunk(
  "batch/getBatches",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${batchesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const batchList = obj.batches;
        return batchList;
      });
  }
);

export const getApprovalBatches = createAsyncThunk(
  "batch/getApprovalBatches",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${approvalBatchesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const batchList = obj.batches;
        return batchList;
      });
  }
);

export const getFileReviewBatches = createAsyncThunk(
  "batch/getFileReviewBatches",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${fileReviewBatchesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const batchList = obj.batches;
        return batchList;
      });
  }
);

export const batchSlice = createSlice({
  name: "batch",
  initialState: {
    list: [],
    status: null,
    approvals: [],
    approvals_status: null,
    file_reviews: [],
    file_reviews_status: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getBatches.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getBatches.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = "success";
    });
    builder.addCase(getBatches.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getApprovalBatches.pending, (state, action) => {
      state.approvals_status = "loading";
    });
    builder.addCase(getApprovalBatches.fulfilled, (state, action) => {
      state.approvals = action.payload;
      state.approvals_status = "success";
    });
    builder.addCase(getApprovalBatches.rejected, (state, action) => {
      state.approvals_status = "failed";
    });
    builder.addCase(getFileReviewBatches.pending, (state, action) => {
      state.file_reviews_status = "loading";
    });
    builder.addCase(getFileReviewBatches.fulfilled, (state, action) => {
      state.file_reviews = action.payload;
      state.file_reviews_status = "success";
    });
    builder.addCase(getFileReviewBatches.rejected, (state, action) => {
      state.file_reviews_status = "failed";
    });
  },
});

export default batchSlice.reducer;
