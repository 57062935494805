import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { sharedBatchesAPI } from "utils/endpoints";

export const getSharedBatches = createAsyncThunk(
  "sharedBatch/getSharedBatches",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${sharedBatchesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        return obj.batches;
      });
  }
);

export const sharedBatchSlice = createSlice({
  name: "sharedBatch",
  initialState: {
    list: [],
    status: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getSharedBatches.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getSharedBatches.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = "success";
    });
    builder.addCase(getSharedBatches.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default sharedBatchSlice.reducer;
