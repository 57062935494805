import { useAccount, useMsal } from "@azure/msal-react";
import {
  Checkbox,
  Dropdown,
  DropdownMenuItemType,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { protectedResources } from "authConfig";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminOpsAPI } from "utils/endpoints";
import ConfirmResendModal from "./ConfirmResendModal";
import {
  baseDiv,
  body,
  footer,
  headerDiv,
  paddingTop,
  primaryActionButton,
  titleFont,
  whitebg,
} from "./Styles";
import { guidErrorText, validFlowGuid } from "./utils";

const optionalErrorText = (objName, hashPrefix, namePrefix) =>
  [objName, hashPrefix, namePrefix].filter((query) => query).length > 1;

const ObjResend = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [flowId, setFlowId] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [objName, setObjName] = useState(null);
  const [objHashPrefix, setObjHashPrefix] = useState(null);
  const [objNamePrefix, setObjNamePrefix] = useState(null);
  const [useOldVersion, setUseOldVersion] = useState(false);
  const [useOnlySuccess, setUseOnlySuccess] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const clusters =
    useSelector((state) => state.cluster.list)[selectedCloud?.project] ?? [];
  const ingressClusters = [
    {
      key: "ingressHeader",
      text: "Ingress Clusters",
      itemType: DropdownMenuItemType.Header,
    },
  ];
  clusters.forEach((clusterName) => {
    if (clusterName.slice(-1) === "a") {
      ingressClusters.push({
        key: clusterName,
        text: clusterName,
      });
    }
  });
  const clusterDropdownList = ingressClusters;

  const buttonEnabled = validFlowGuid(flowId) && cluster;

  const resendObj = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const bodyParams = {
      flowId: flowId,
      cluster: cluster.key,
      useObjectName: !!objName,
      objectName: objName,
      useObjectHashPrefix: !!objHashPrefix,
      objectHashPrefix: objHashPrefix,
      useObjectNamePrefix: !!objNamePrefix,
      objectNamePrefix: objNamePrefix,
      useOldVersions: useOldVersion,
      onlySuccessEntries: useOnlySuccess,
    };

    setRequestPending(true);
    hideModal();

    callAuthorizedEndpointWithBody(
      `${adminOpsAPI}/resend`,
      token,
      "POST",
      bodyParams
    )
      .then(() => {
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: `Successfully initiated resend.`,
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestPending(false);
      });
  };

  const verifyAndShowModal = () => {
    if (optionalErrorText(objName, objHashPrefix, objNamePrefix)) {
      dispatch(
        setMessage({
          type: MessageBarType.error,
          message:
            "You may only select 1 query filter out of ObjectName, ObjectHashPrefix, or ObjectNamePrefix",
        })
      );
      dispatch(setShow(true));
      return; // show error and don't go to confirmation modal
    }

    showModal();
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Teleport Object Resend</span>
      </div>
      <div className={whitebg}>
        <div className={body}>
          <TextField
            label="Flow ID"
            onChange={(event, newValue) => setFlowId(newValue.trim())}
            onGetErrorMessage={(value) => guidErrorText(value)}
            placeholder="e.g. 1245d9ba-0faa-4a90-9037-96125e2ee0b7"
          />
          <Dropdown
            label="Cluster"
            options={clusterDropdownList}
            onChange={(event, option, index) => setCluster(option)}
            data-testid="cluster-dropdown"
            className={paddingTop}
          />
          <TextField
            label="(Optional) Exact Object Name"
            onChange={(event, newValue) => setObjName(newValue.trim())}
            className={paddingTop}
          />
          <TextField
            label="(Optional) Object Hash Prefix"
            onChange={(event, newValue) => setObjHashPrefix(newValue.trim())}
            className={paddingTop}
          />
          <TextField
            label="(Optional) Object Name Prefix"
            onChange={(event, newValue) => setObjNamePrefix(newValue.trim())}
            className={paddingTop}
          />
          <Checkbox
            label="Get Old Versions"
            className={paddingTop}
            checked={useOldVersion}
            onChange={(ev, checked) => setUseOldVersion(checked)}
          />
          <Checkbox
            label="Only Success Entries"
            className={paddingTop}
            checked={useOnlySuccess}
            onChange={(ev, checked) => setUseOnlySuccess(checked)}
          />
          <div className={footer}>
            <PrimaryButton
              className={primaryActionButton}
              disabled={!buttonEnabled || requestPending}
              data-testid="objResend-button"
              onClick={() => verifyAndShowModal()}
            >
              {requestPending ? "Loading..." : "Resend"}
            </PrimaryButton>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmResendModal
          {...{
            isModalOpen,
            hideModal,
            flowId,
            cluster,
            objName,
            objHashPrefix,
            objNamePrefix,
            useOldVersion,
            useOnlySuccess,
            resendObj,
          }}
        />
      )}
    </div>
  );
};

export default ObjResend;
