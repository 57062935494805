import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  confirmModalButton,
  modalBodyPadding,
  modalContainer,
  modalFooter,
  modalHeader,
} from "batches/Styles";

const FileApproveModal = (props) => {
  const { isModalOpen, hideModal, approveFile, fileName, requestSending } =
    props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Approve File</div>
      <div className={modalBodyPadding}>
        Are you sure you want to approve <b>{fileName}</b> to be transfered?
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          disabled={requestSending}
          onClick={() => approveFile(fileName)}
          className={confirmModalButton}
          data-testid="confirm-button"
        >
          {requestSending ? "Confirming..." : "Confirm"}
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default FileApproveModal;
