import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI } from "utils/endpoints";
import LoadingView from "ingressFlows/LoadingView";
import { useBoolean } from "@fluentui/react-hooks";
import {
  baseDiv,
  cancelButton,
  card,
  createFlowCard,
  deleteFlowButton,
  errorTextStyle,
  essentialsColumn,
  essentialsGrid,
  essentialsText,
  essentialsTitle,
  headerDiv,
  pivotPadding,
  statusDropdown,
  titleFont,
} from "ingressFlows/Styles";
import FlowMetrics from "ingressFlows/FlowMetrics";
import AdminEditFlow from "./AdminEditFlow";
import JustificationModal from "./JustificationModal";
import { MODAL_FLOW_DISABLE, MODAL_FLOW_ENABLE } from "./AdminIngressFlows";
import HistoricalTimeline from "ingressFlows/HistoricalTimeline";

export const createGridElements = (keyText, valueText) => (
  <>
    <div className={essentialsText}>{keyText}</div>
    <div className={essentialsText}>:</div>
    <div className={essentialsText}>{valueText}</div>
  </>
);

const AdminIngressFlowDetails = () => {
  const { flowGuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flow, setFlow] = useState(null);
  const [hideDialog, { toggle: toggleDialog }] = useBoolean(true);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [modalType, setModalType] = useState(null);
  const [historyEvents, setHistoryEvents] = useState(null);

  const cachedFlowInfo = useSelector(
    (state) => state.ingressFlows.admin_list
  ).find((flow) => flow.dataflowGuid === flowGuid);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const clusters = useSelector((state) => state.cluster.list);
  const clusterList = clusters[selectedCloud?.project] ?? [];
  const clusterDropdownList = clusterList
    .filter((clusterName) => clusterName.slice(-1) === "a")
    .map((clusterName) => {
      return {
        key: clusterName,
        text: clusterName,
      };
    });

  const deleteFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const apiEndpoint = `${adminIngressAPI}/${flow.dataflowGuid}?cloud=${flow.cloud}`;

    callAuthorizedEndpointWithBody(apiEndpoint, token, "DELETE", {})
      .then(() => {
        navigate("/ingress");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          toggleDialog();
        })
      );
  };

  const deleteDialog = {
    type: DialogType.normal,
    title: "Delete Flow",
    subText: "Are you sure you want to delete this Flow?",
    footer: (
      <>
        <PrimaryButton
          onClick={deleteFlow}
          text="Delete"
          className={deleteFlowButton}
          data-testid="delete-button"
        />
        <DefaultButton
          onClick={toggleDialog}
          className={cancelButton}
          text="Cancel"
        />
      </>
    ),
  };

  const getFlowStatusText = () => {
    if (flow.isDeleted) {
      return "Deleted";
    }

    if (!flow.finalApprover && !flow.aupForm) {
      return "Approval Required";
    }

    if (!flow.onboarded) {
      return "Onboarding in process";
    }

    const options = [
      { key: "Enabled", text: "Enabled" },
      { key: "Disabled", text: "Disabled" },
    ];

    const selectedKey = flow.enabled ? "Enabled" : "Disabled";

    const toggleChange = (toggleKey) => {
      if (toggleKey === selectedKey) return;

      if (toggleKey === "Enabled") {
        setModalType(MODAL_FLOW_ENABLE);
      } else {
        setModalType(MODAL_FLOW_DISABLE);
      }
      showModal();
    };

    return (
      <Dropdown
        selectedKey={selectedKey}
        onChange={(event, option, index) => toggleChange(option.key)}
        options={options}
        className={statusDropdown}
      />
    );
  };

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${adminIngressAPI}/${flowGuid}?cloud=${selectedCloud.project}`,
      token
    )
      .then((response) => response.json())
      .then((response) => {
        setFlow(response.flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [account, instance, flowGuid, selectedCloud]);

  useEffect(() => {
    if (cachedFlowInfo) {
      setFlow(cachedFlowInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedFlowInfo, getFlow]);

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{flow.title}</span>
        <PrimaryButton
          text="Delete Flow"
          onClick={toggleDialog}
          className={deleteFlowButton}
        />
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={createFlowCard}>
            <div className={card}>
              <div className={essentialsTitle}>Flow Details</div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Flow Identifier", flowGuid)}
                  {createGridElements("Owner", flow.primaryContact)}
                  {createGridElements("Flow Status", getFlowStatusText())}
                </div>
              </div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Title", flow.title)}
                  {createGridElements("Cloud", flow.cloud.toUpperCase())}
                  {createGridElements("Notes", flow.notes)}
                </div>
              </div>
            </div>
          </div>
          <FlowMetrics flow={flow} />
        </PivotItem>
        <PivotItem headerText="Info">
          <AdminEditFlow
            flow={flow}
            setFlow={setFlow}
            clusterList={clusterDropdownList}
          />
        </PivotItem>
        <PivotItem headerText="History">
          <HistoricalTimeline
            adminMode={true}
            flow={flow}
            events={historyEvents}
            setEvents={setHistoryEvents}
          />
        </PivotItem>
      </Pivot>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleDialog}
        dialogContentProps={deleteDialog}
      >
        <DialogFooter>{deleteDialog.footer}</DialogFooter>
      </Dialog>
      {isModalOpen && (
        <JustificationModal
          {...{ isModalOpen, hideModal, modalType, modalFlow: flow }}
        />
      )}
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow details" /> : content}
    </div>
  );
};

export default AdminIngressFlowDetails;
