import { ActivityItem, Icon, Link } from "@fluentui/react";
import { activityItemDiv, nameText } from "./Styles";

const EGRESS_FLOW_ADMIN_DELETE = "EGRESS_FLOW_ADMIN_DELETE";
const EGRESS_FLOW_ADMIN_UPDATED = "EGRESS_FLOW_ADMIN_UPDATED";
const EGRESS_FLOW_APPROVAL_UPDATED = "EGRESS_FLOW_APPROVAL_UPDATED";
const EGRESS_FLOW_APPROVED = "EGRESS_FLOW_APPROVED";
const EGRESS_FLOW_CREATED = "EGRESS_FLOW_CREATED";
const EGRESS_FLOW_DELETED = "EGRESS_FLOW_DELETED";
const EGRESS_FLOW_UPDATED = "EGRESS_FLOW_UPDATED";
const EGRESS_FLOW_VERIFIED = "EGRESS_FLOW_VERIFIED";
const EGRESS_FLOW_VERIFY_UPDATE = "EGRESS_FLOW_VERIFY_UPDATE";
const EGRESS_FLOW_ADMIN_DISABLED = "EGRESS_FLOW_ADMIN_DISABLED";
const EGRESS_FLOW_ADMIN_ENABLED = "EGRESS_FLOW_ADMIN_ENABLED";

export const EGRESS_EVENT_TO_ICON_MAPPING = {
  [EGRESS_FLOW_ADMIN_DELETE]: "Delete",
  [EGRESS_FLOW_ADMIN_UPDATED]: "Edit",
  [EGRESS_FLOW_APPROVAL_UPDATED]: "Edit",
  [EGRESS_FLOW_APPROVED]: "CheckMark",
  [EGRESS_FLOW_CREATED]: "CloudDownload",
  [EGRESS_FLOW_DELETED]: "Delete",
  [EGRESS_FLOW_UPDATED]: "Edit",
  [EGRESS_FLOW_VERIFIED]: "CheckMark",
  [EGRESS_FLOW_VERIFY_UPDATE]: "Edit",
  [EGRESS_FLOW_ADMIN_DISABLED]: "ToggleLeft",
  [EGRESS_FLOW_ADMIN_ENABLED]: "ToggleRight",
};

export const convertDateToLocaleString = (date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const generateActivityDescription = (event, currIndex, showMoreInfo) => {
  if (event.eventName === EGRESS_FLOW_ADMIN_DELETE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;hard-deleted Egress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_ENABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;enabled Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_DISABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;disabled Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_ADMIN_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_APPROVAL_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>
          &nbsp;updated Egress Flow still in Approval state&nbsp;
        </span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_APPROVED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_CREATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Egress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_DELETED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;soft-deleted Egress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Egress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_VERIFIED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;verified Egress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === EGRESS_FLOW_VERIFY_UPDATE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>
          &nbsp;updated Egress Flow still in Verification state&nbsp;
        </span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          &nbsp;(see more details)
        </Link>
      </div>
    );
  }
};

export const generateActivityItems = (events, showMoreInfo) => {
  return events?.map((event, currIndex) => (
    <div key={currIndex}>
      <ActivityItem
        activityDescription={generateActivityDescription(
          event,
          currIndex,
          showMoreInfo
        )}
        activityIcon={
          <Icon iconName={EGRESS_EVENT_TO_ICON_MAPPING[event.eventName]} />
        }
        timeStamp={convertDateToLocaleString(event.creationDate)}
        className={currIndex !== 0 ? activityItemDiv : null}
      />
    </div>
  ));
};

const getDiffInfo = (currState, newObj) => {
  const diffInfo = {};

  Object.keys(newObj).forEach((key) => {
    if (
      currState.hasOwnProperty(key) &&
      newObj[key] !== null &&
      currState[key] !== newObj[key]
    ) {
      diffInfo[key] = [currState[key], newObj[key]];
      currState[key] = newObj[key];
    }
  });

  return diffInfo;
};

// Function to remove the duplciate customer's non-admin event from the final list
export const filterAdminList = (events) => {
  const result = [];

  for (let i = 0; i < events.length; i++) {
    if (i < events.length) {
      result.push(events[i]);
    }

    if (i + 1 >= events.length) {
      break;
    }

    if (
      events[i].eventName === events[i + 1].eventName &&
      events[i].isAdminEvent &&
      !events[i + 1].isAdminEvent
    ) {
      i++;
    }
  }

  return result;
};

export const generateInfoList = (events) => {
  const infoList = new Array(events.length);

  let currState = {
    Cloud: "",
    DataflowGuid: "",
    Email: "",
    PrimaryContact: "",
    ResourceGroup: "",
    SecondaryContact: "",
    ServiceName: "",
    StorageAccountName: "",
    StorageContainerName: "",
    StorageContainerRegion: "",
    StorageContainerType: "",
    StorageTenantId: "",
    ServiceTreeId: "",
    SubscriptionId: "",
    Title: "",
  };

  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];

    if (
      event.eventName === EGRESS_FLOW_ADMIN_UPDATED ||
      event.eventName === EGRESS_FLOW_CREATED ||
      event.eventName === EGRESS_FLOW_UPDATED
    ) {
      event.diffInfo = getDiffInfo(currState, event.eventDetails);
    }

    infoList[i] = event;
  }

  return events;
};

export const generateDiffCallout = (diffObj) => {
  return (
    <div>
      <div>{`{`}</div>
      {Object.keys(diffObj).map((key) => {
        if (Array.isArray(diffObj[key]) && diffObj[key].length === 2) {
          return (
            <div
              key={key}
            >{`${key}: ${diffObj[key][0]} -> ${diffObj[key][1]}`}</div>
          );
        } else {
          return <div key={key}>{`${key}: ${diffObj[key]}`}</div>;
        }
      })}
      <div>{`}`}</div>
    </div>
  );
};
