export const validTitle = (title) => {
  return title?.trim().length > 0;
};

export const getTitleErrorText = (title) => {
  return validTitle(title) ? "" : "A title is required";
};

export const validEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const getEmailErrorText = (email) => {
  if (email?.trim().length < 1) {
    return "An email is required";
  }

  return validEmail(email) ? "" : "Not a valid email format";
};

export const validPrimaryContact = (peopleArr) => {
  return peopleArr?.length > 0;
};

export const validSubscriptionId = (id) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(id);

export const getSubscriptionIdErrorText = (id) => {
  if (id?.trim().length < 1) {
    return "The Subscription ID is required";
  }

  return validSubscriptionId(id) ? "" : "Invalid Subscription ID format";
};

export const validResourceGroup = (name) => {
  return name?.trim().length > 0;
};

export const getResourceGroupErrorText = (name) => {
  return validResourceGroup(name)
    ? ""
    : "A Resource Group Name must be provided";
};

export const validStorageAccountName = (accountName) => {
  return accountName?.trim().length > 0;
};

export const getStorageAccountErrorText = (accountName) => {
  return validStorageAccountName(accountName)
    ? ""
    : "An Azure Storage Account Name is required";
};

export const validStorageContainerName = (containerName) => {
  return containerName?.trim().length > 0;
};

export const getStorageContainerErrorText = (containerName) => {
  return validStorageContainerName(containerName)
    ? ""
    : "The Name of your Storage Table or Blob Container is required";
};

export const validServiceName = (name) => name?.trim().length > 0;

export const getServiceNameErrorText = (name) =>
  validServiceName(name) ? "" : "A Service Name must be provided";

export const validServiceTreeId = (id) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(id);

export const getServiceTreeErrorText = (id) => {
  if (id?.trim().length < 1) {
    return "The Service Tree ID is required";
  }

  return validServiceTreeId(id) ? "" : "Invalid Service Tree ID format";
};

export const validGuid = (guid) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(guid);
export const validTenantId = (id) => validGuid(id);
export const getTenantIdErrorText = (id) => {
  if (id?.trim().length < 1) {
    return "The Tenant ID is required";
  }

  return validTenantId(id) ? "" : "Invalid Tenant ID format";
};
