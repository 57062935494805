export const validName = (name) => new RegExp("^[a-zA-Z0-9-]+$").test(name);

export const getNameErrorText = (name) => {
  if (name.length < 1) {
    return "A name is required";
  }

  return validName(name) ? "" : "Characters can only be alphanumeric or dashes";
};

/* NOTE: https://www.geeksforgeeks.org/how-to-validate-guid-globally-unique-identifier-using-regular-expression/ */
export const validServiceTreeId = (id) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(id);

export const getServiceTreeErrorText = (id) => {
  if (id.length < 1) {
    return "A Service Tree ID is required";
  }

  return validServiceTreeId(id) ? "" : "Not a valid GUID";
};

export const validJustification = (justification) => justification.length >= 25;

export const getJustificationErrorText = (justification) => {
  if (justification.length < 1) {
    return "A justification is required";
  }

  return validJustification(justification)
    ? ""
    : "Minimum justification length must be at least 25 characters long.";
};
