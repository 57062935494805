import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { egressFlowAPI, egressFlowAupAPI } from "utils/endpoints";
import AupStep from "./AupStep";
import BasicsStep from "./BasicsStep";
import ConnectionStep, {
  containerTypeOptions,
  regionTypeOptions,
} from "./ConnectionStep";
import {
  validEmail,
  validResourceGroup,
  validServiceName,
  validServiceTreeId,
  validStorageAccountName,
  validStorageContainerName,
  validSubscriptionId,
  validTenantId,
  validTitle,
} from "../createFlowValidation";
import JustificationStep from "./JustificationStep";
import LoadingView from "../LoadingView";
import ReviewStep from "./ReviewStep";
import {
  baseDiv,
  createFooter,
  footerButtons,
  footerRow,
  headerDiv,
  pivotPadding,
  pivotSelect,
  reviewDiv,
  titleFont,
} from "../Styles";
import { setMessage, setShow } from "store/messageBarSlice";
export const CreateEgressFlows = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isCreating, setIsCreating] = useState(false);
  const [selectedPivot, setSelectedPivot] = useState(0);
  const [locked, setLocked] = useState(false);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState([]);
  const [tenantId, setTenantId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [resourceGroup, setResourceGroup] = useState("");
  const [storageAccName, setStorageAccName] = useState("");
  const [storageContainerType, setStorageContainerType] = useState(
    containerTypeOptions[0].key
  );
  const [storageContainerName, setStorageContainerName] = useState("");
  const [containerRegion, setContainerRegion] = useState(
    regionTypeOptions[0].key
  );
  const [sasTest, setSasTest] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceTreeId, setServiceTreeId] = useState("");
  const [aupForm, setAupForm] = useState("");
  const [fetchingAup, setFetchingAup] = useState(true);
  const [acceptAup, setAcceptAup] = useState(false);

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const destinationField = selectedCloud
    ? selectedCloud.name.toUpperCase()
    : "";

  const validBasic = () =>
    validTitle(title) && selectedCloud && validEmail(email);

  const validConnection = () =>
    validSubscriptionId(subscriptionId) &&
    validResourceGroup(resourceGroup) &&
    validStorageAccountName(storageAccName) &&
    validStorageContainerName(storageContainerName) &&
    validTenantId(tenantId) &&
    sasTest;

  const validJustification = () =>
    validServiceName(serviceName) && validServiceTreeId(serviceTreeId);

  const validAup = () => acceptAup;

  const validCreation =
    validBasic() && validConnection() && validJustification() && validAup();

  const createSteps = [
    {
      title: "Basics",
      key: "basics",
      render: (
        <BasicsStep
          cloud={destinationField}
          title={title}
          setTitle={setTitle}
          email={email}
          setEmail={setEmail}
          setSecondaryContact={setSecondaryContact}
          secondaryContact={secondaryContact}
        />
      ),
      validateFunc: () => validBasic(),
    },
    {
      title: "Connection Info",
      key: "connection",
      render: (
        <ConnectionStep
          setLocked={setLocked}
          tenantId={tenantId}
          setTenantId={setTenantId}
          subscriptionId={subscriptionId}
          setSubscriptionId={setSubscriptionId}
          resourceGroup={resourceGroup}
          setResourceGroup={setResourceGroup}
          storageAccName={storageAccName}
          setStorageAccName={setStorageAccName}
          storageContainerType={storageContainerType}
          setStorageContainerType={setStorageContainerType}
          storageContainerName={storageContainerName}
          setStorageContainerName={setStorageContainerName}
          containerRegion={containerRegion}
          setContainerRegion={setContainerRegion}
          sasTest={sasTest}
          setSasTest={setSasTest}
        />
      ),
      validateFunc: () => validConnection(),
    },
    {
      title: "Business Justification",
      key: "justification",
      render: (
        <JustificationStep
          serviceName={serviceName}
          setServiceName={setServiceName}
          serviceTreeId={serviceTreeId}
          setServiceTreeId={setServiceTreeId}
        />
      ),
      validateFunc: () => validJustification(),
    },
    {
      title: "AUP",
      key: "aup",
      render: (
        <AupStep
          aupForm={aupForm}
          fetchingAup={fetchingAup}
          acceptAup={acceptAup}
          setAcceptAup={setAcceptAup}
        />
      ),
      validateFunc: () => validAup(),
    },
    {
      title: "Review & Create",
      key: "review",
      render: (
        <ReviewStep
          title={title}
          cloud={destinationField}
          email={email}
          secondaryContact={secondaryContact}
          tenantId={tenantId}
          subscriptionId={subscriptionId}
          resourceGroup={resourceGroup}
          storageAccName={storageAccName}
          storageContainerType={storageContainerType}
          storageContainerName={storageContainerName}
          containerRegion={containerRegion}
          serviceName={serviceName}
          serviceTreeId={serviceTreeId}
        />
      ),
    },
  ];

  const createFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setIsCreating(true);

    const body = {
      Cloud: selectedCloud.project,
      Email: email,
      ResourceGroup: resourceGroup,
      SecondaryContact: secondaryContact
        .map((contact) => getAliasFromUserPrincipalName(contact.upn))
        .join(";"),
      ServiceName: serviceName,
      ServiceTreeId: serviceTreeId,
      StorageAccountName: storageAccName,
      StorageContainerName: storageContainerName,
      StorageContainerRegion: containerRegion,
      StorageContainerType: storageContainerType,
      StorageTenantId: tenantId,
      SubscriptionId: subscriptionId,
      Title: title,
    };

    callAuthorizedEndpointWithBody(egressFlowAPI, token, "POST", body)
      .then(() => navigate("/egress"))
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          setIsCreating(false);
        })
      );
  };

  useEffect(() => {
    const getAupForm = async () => {
      const token = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );

      callAuthorizedEndpoint(egressFlowAupAPI, token)
        .then((response) => response.text())
        .then((text) => {
          setAupForm(text);
          setFetchingAup(false);
        })
        .catch((response) =>
          response.text().then((error) => {
            setFetchingAup(false);
          })
        );
    };

    getAupForm();
  }, [account, instance]);

  return (
    <div className={baseDiv}>
      {isCreating && <LoadingView label="Creating your new Flow" />}
      {!isCreating && (
        <>
          <div className={headerDiv}>
            <span className={titleFont}>Create New Egress Flow</span>
          </div>
          <Pivot
            className={pivotPadding}
            selectedKey={createSteps[selectedPivot].key}
          >
            {createSteps.map((step) => (
              <PivotItem
                headerText={step.title}
                itemKey={step.key}
                key={step.key}
              >
                {step.render}
              </PivotItem>
            ))}
          </Pivot>
          <div className={createFooter}>
            <div className={footerRow}>
              <DefaultButton
                disabled={selectedPivot === 0 || locked}
                onClick={() => setSelectedPivot(selectedPivot - 1)}
                text="< Previous"
                className={pivotSelect}
              />
              <DefaultButton
                disabled={
                  selectedPivot === createSteps.length - 1 ||
                  !createSteps[selectedPivot].validateFunc() ||
                  locked
                }
                onClick={() => setSelectedPivot(selectedPivot + 1)}
                text="Next >"
                className={pivotSelect}
              />
              {selectedPivot === createSteps.length - 1 && (
                <div className={reviewDiv}>
                  <PrimaryButton
                    text="Create"
                    disabled={locked || !validCreation}
                    onClick={createFlow}
                    className={footerButtons}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CreateEgressFlows;
