import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { mergeStyles } from "@fluentui/merge-styles";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavHeader from "./navbar/NavHeader";
import SideNavBar from "./sidenav/SideNavBar";
import { MessageBar, Spinner, SpinnerSize } from "@fluentui/react";
import { setShow } from "store/messageBarSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { getAuthorizedUserInfo } from "store/modeSlice";
import { getNotifications } from "store/notificationSlice";

const layoutStyling = mergeStyles({
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
  overflow: "hidden",
});

const mainScreen = mergeStyles({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflow: "hidden",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

const Layout = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const showMessageBar = useSelector((state) => state.messageBar.show);
  const messageBarType = useSelector((state) => state.messageBar.type);
  const messageBarText = useSelector((state) => state.messageBar.message);
  const loadingStatus = useSelector((state) => state.mode.status);
  const appModes = Object.keys(useSelector((state) => state.mode.allInfo));

  // Since this is the highest level component for any authorized (logged-in) view, all "on-startup" calls/events/functions will be done here
  useEffect(() => {
    const getNotificationList = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getNotifications(accessToken));
    };

    const getUserInfo = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getAuthorizedUserInfo(accessToken));
    };

    getUserInfo();
    getNotificationList();
  }, [account, dispatch, instance]);

  const layoutContent = () => {
    if (loadingStatus === "failed") {
      return <div>Could not confirm your authorization status.</div>;
    }

    if (loadingStatus === "success" && appModes.length < 1) {
      return <div>No app modes available. You cannot use this tool.</div>;
    }

    return (
      <>
        <NavHeader />
        <div className={layoutStyling}>
          <SideNavBar />
          <div className={mainScreen}>
            {showMessageBar && (
              <MessageBar
                messageBarType={messageBarType}
                isMultiline={false}
                dismissButtonAriaLabel="dismiss"
                onDismiss={() => dispatch(setShow(false))}
              >
                {messageBarText}
              </MessageBar>
            )}
            {props.children}
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      {loadingStatus === "loading" && (
        <div className={spinnerCentered}>
          <Spinner
            size={SpinnerSize.large}
            label="Fetching your authorization status"
            labelPosition="top"
          />
        </div>
      )}
      {loadingStatus && loadingStatus !== "loading" && layoutContent()}
    </React.Fragment>
  );
};

export default Layout;
