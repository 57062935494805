import * as React from "react";
import {
  baseDiv,
  card,
  createButton,
  createBatchCard,
  createBatchFooter,
  headerDiv,
  paddingTop,
  pivotPadding,
  titleFont,
  modalContainer,
  modalHeader,
  modalSpinner,
  eulaPadding,
  modalFooter,
  modalButtons,
  cancelButton,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { MessageBarType, Modal, Pivot, PivotItem } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { batchesAPI, userAupAPI } from "utils/endpoints";
import {
  getJustificationErrorText,
  getNameErrorText,
  getServiceTreeErrorText,
  validJustification,
  validName,
  validServiceTreeId,
} from "./batchValidation";
import { setMessage, setShow } from "store/messageBarSlice";

export const CreateBatches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const destinationField = selectedCloud
    ? selectedCloud.name.toUpperCase()
    : "";

  const [nameField, setNameField] = useState("");
  const [serviceTreeIdField, setServiceTreeIdField] = useState("");
  const [justificationField, setJustificationField] = useState("");

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [isFetchingAup, setIsFetchingAup] = useState(false);
  const [aupForm, setAupForm] = useState("");
  const [isSendingCreateRequest, setIsSendingCreateRequest] = useState(false);

  const isCreateAllowed = () => {
    return (
      validName(nameField) &&
      validServiceTreeId(serviceTreeIdField) &&
      validJustification(justificationField) &&
      selectedCloud
    );
  };

  const modalLoadingView = (
    <div>
      <Spinner
        size={SpinnerSize.large}
        label="Fetching Aup Form"
        labelPosition="top"
        className={modalSpinner}
      />
    </div>
  );

  const footerButtons = isFetchingAup ? (
    <DefaultButton onClick={hideModal} className={modalButtons}>
      Cancel
    </DefaultButton>
  ) : (
    <>
      <PrimaryButton
        disabled={isSendingCreateRequest}
        onClick={() => createBatch()}
        className={modalButtons}
        data-testid="create-button"
      >
        Create
      </PrimaryButton>
      <DefaultButton onClick={hideModal} className={cancelButton}>
        Cancel
      </DefaultButton>
    </>
  );

  const openAupModal = async () => {
    setIsFetchingAup(true);
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpoint(userAupAPI, token)
      .then((response) => response.text())
      .then((text) => {
        setAupForm(text);
        setIsFetchingAup(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setAupForm(null);
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          dispatch(setShow(true));
          setIsFetchingAup(false);
          hideModal();
        })
      );

    showModal();
  };

  const createBatch = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setIsSendingCreateRequest(true);
    // First send the request to sign off the AUP
    callAuthorizedEndpointWithBody(`${userAupAPI}/accept`, token, "POST", {})
      .then(() => {
        const requestBody = {
          Cloud: selectedCloud.project,
          ServiceTreeId: serviceTreeIdField,
          Justification: justificationField,
        };

        // Then actually send the create batch request
        callAuthorizedEndpointWithBody(
          `${batchesAPI}/${nameField}`,
          token,
          "POST",
          requestBody
        )
          .then((response) => response.json())
          .then((response) => {
            navigate(`/batches/${response.containerName}`);
            hideModal();
          })
          .catch((response) =>
            response.text().then((text) => {
              dispatch(
                setMessage({
                  type: MessageBarType.error,
                  message: text,
                })
              );
              dispatch(setShow(true));
            })
          )
          .finally(() => {
            setIsSendingCreateRequest(false);
            hideModal();
          });
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          dispatch(setShow(true));
          setIsSendingCreateRequest(false);
          hideModal();
        })
      );
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Create Batch Request</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={createBatchCard}>
            <div className={card}>
              <TextField
                label="Name"
                onGetErrorMessage={(value) => getNameErrorText(value)}
                onChange={(event, value) => setNameField(value)}
                validateOnLoad={false}
              />
              <TextField
                label="Service Tree ID"
                onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
                onChange={(event, value) => setServiceTreeIdField(value)}
                validateOnLoad={false}
                className={paddingTop}
              />
              <TextField
                label="Destination"
                readOnly={true}
                value={destinationField}
                className={paddingTop}
              />
              <TextField
                label="Justification"
                multiline
                onGetErrorMessage={(value) => getJustificationErrorText(value)}
                onChange={(event, value) => setJustificationField(value)}
                validateOnLoad={false}
                className={paddingTop}
              />
              <div className={createBatchFooter}>
                <PrimaryButton
                  className={createButton}
                  disabled={!isCreateAllowed()}
                  onClick={() => openAupModal()}
                  data-testid="show-aup"
                >
                  Create
                </PrimaryButton>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            containerClassName={modalContainer}
          >
            <div className={modalHeader}>Terms and Conditions</div>
            {isFetchingAup ? (
              modalLoadingView
            ) : (
              <div className={eulaPadding}>
                <div dangerouslySetInnerHTML={{ __html: aupForm }}></div>
              </div>
            )}
            <div className={modalFooter}>{footerButtons}</div>
          </Modal>
        </PivotItem>
      </Pivot>
    </div>
  );
};

export default CreateBatches;
