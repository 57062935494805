import * as React from "react";
import { useSelector } from "react-redux";
import NavSelectionGroup from "../NavSelectionGroup";
import { TELEPORTAL_MODE } from "store/modeSlice";

const AdminOpsNavGroup = () => {
  const roles = useSelector((state) => state.mode.roles);
  const selectedAppMode = useSelector((state) => state.mode.currentAppMode);

  const links = [];

  if (roles.dri) {
    links.push(
      {
        title: "Get Teleport Object Status",
        icon: "CloudSearch",
        path: "/GetObjectStatus",
      },
      {
        title: "Resync Flow",
        icon: "CloudSearch",
        path: "/resync",
      },
      {
        title: "Resend Teleport Object",
        icon: "CloudSearch",
        path: "/resend",
      },
      {
        title: "Resend Manifest",
        icon: "CloudSearch",
        path: "/manifest",
      },
      {
        title: "Batch Resend",
        icon: "CloudSearch",
        path: "/batchResend",
      }
    );

    if (selectedAppMode === TELEPORTAL_MODE) {
      links.push({
        title: "Audit Search",
        icon: "CloudSearch",
        path: "/auditSearch",
      });
    }
  }

  if (roles.experience) {
    links.push({
      title: "Admin RP",
      icon: "AzureLogo",
      path: "/adminRp",
    });
  }

  const rootProps = {
    title: "Admin Ops",
    icon: "CloudSearch",
    path: links.length > 0 ? links[0].path : null,
  };

  return links.length > 0 ? (
    <NavSelectionGroup root={rootProps} links={links} />
  ) : null;
};

export default AdminOpsNavGroup;
