import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { mergeStyles } from "@fluentui/react";
import { store } from "./store";
import { registerAppIcons } from "fluentIcons/RegisterIcons";
import App from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import "bootstrap/dist/css/bootstrap.css";
import "./fluentIcons/fabric-icons.css";

mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

// Need to call this at the base project level to utilize FluentUI <Icon> elements
registerAppIcons();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Router basename={baseUrl}>
          <App />
        </Router>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
