import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const comingSoon = mergeStyles({
  position: "relative",
  top: "45%",
  textAlign: "center",
  fontWeight: "700",
  fontSize: "xx-large",
});

export const baseDiv = classNames(greyPage, base);
