import {
  DefaultButton,
  Dropdown,
  Label,
  Modal,
  PrimaryButton,
  SpinButton,
  TextField,
} from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
  padTop,
} from "egressFlows/Styles";
import { useSelector } from "react-redux";

const ApprovalConfirmModal = (props) => {
  const {
    isModalOpen,
    hideModal,
    approveFlow,
    cluster,
    setCluster,
    syncPeriod,
    setSyncPeriod,
    notes,
    setNotes,
  } = props;

  const clusters = useSelector((state) => state.cluster.list);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const clusterList = clusters[selectedCloud.project] ?? [];
  const clusterDropdown = clusterList
    .filter((clusterName) => clusterName.slice(-1) === "b")
    .map((clusterName) => {
      return {
        key: clusterName,
        text: clusterName,
      };
    });

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Approve Egress Flow Confirmation</div>
      <div className={modalBodyPadding}>
        <Dropdown
          label="Cluster"
          selectedKey={cluster}
          onChange={(event, option, index) => setCluster(option.key)}
          options={clusterDropdown}
        />
        <Label className={padTop}>Sync Period in Minutes</Label>
        <SpinButton
          value={syncPeriod}
          onChange={(event, value) => setSyncPeriod(Number(value))}
          min={1}
          step={1}
        />
        <TextField
          label="Notes"
          placeholder="(Optional) Any additional notes"
          onChange={(event, value) => setNotes(value)}
          value={notes ?? ""}
          multiline={true}
          className={padTop}
        />
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => approveFlow()}
          className={modalButtons}
          disabled={!cluster}
          data-testid="approve-button"
        >
          Approve
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ApprovalConfirmModal;
