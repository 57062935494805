import {
  DefaultButton,
  Dropdown,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
  padTop,
} from "ingressFlows/Styles";
import { onboardingStatusOptions } from "ingressFlows/admin/ApprovalIngressDetails";

const ApprovalUpdateModal = (props) => {
  const {
    isModalOpen,
    hideModal,
    updateFlow,
    onboardingStatus,
    setOnboardingStatus,
    notes,
    setNotes,
  } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Update Ingress Flow</div>
      <div className={modalBodyPadding}>
        <Dropdown
          label="Onboarding Status"
          selectedKey={onboardingStatus}
          onChange={(event, option, index) => setOnboardingStatus(option.key)}
          options={onboardingStatusOptions}
        />
        <TextField
          label="Notes"
          placeholder="(Optional) Any additional notes"
          onChange={(event, value) => setNotes(value)}
          value={notes ?? ""}
          multiline={true}
          className={padTop}
        />
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => updateFlow()}
          className={modalButtons}
          data-testid="update-button"
        >
          Update
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ApprovalUpdateModal;
