import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

const AnalyticsNavGroup = () => {
  const links = [
    {
      title: "Flow stats",
      icon: "SummaryChart",
      path: "/analytics",
    },
  ];

  const rootProps = {
    title: "Analytics",
    icon: "AnalyticsView",
    path: links[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={links} />;
};

export default AnalyticsNavGroup;
