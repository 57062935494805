import { useAccount, useMsal } from "@azure/msal-react";
import { Pivot, PivotItem } from "@fluentui/react";
import { protectedResources } from "authConfig";
import LoadingView from "pipelines/LoadingView";
import {
  baseDiv,
  card,
  errorTextStyle,
  essentialsColumn,
  essentialsGrid,
  essentialsText,
  essentialsTitle,
  headerDiv,
  overviewCard,
  pivotPadding,
  titleFont,
} from "pipelines/Styles";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminPipelineAPI } from "utils/endpoints";
import ConnectionList from "./ConnectionList";
import RawView from "./RawView";

export const createGridElements = (keyText, valueText) => (
  <>
    <div className={essentialsText}>{keyText}</div>
    <div className={essentialsText}>:</div>
    <div className={essentialsText}>{valueText}</div>
  </>
);

export const showLocaleString = (date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const Pipeline = () => {
  const { subscriptionId, resourceGroup, pipelineName } = useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [pipeline, setPipeline] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);

  const cachedInfo = useSelector(
    (state) => state.resourceProvider.admin_pipeline_list
  ).find(
    (pipeline) =>
      pipeline.id.toLowerCase() ===
      `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.AzureDataTransfer/Pipelines/${pipelineName}`.toLowerCase()
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getPipeline = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${adminPipelineAPI}/${subscriptionId}/${resourceGroup}/${pipelineName}`,
      token
    )
      .then((response) => response.json())
      .then((pipeline) => {
        setPipeline(pipeline);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [
    account,
    instance,
    pipelineName,
    resourceGroup,
    selectedCloud,
    subscriptionId,
  ]);

  useEffect(() => {
    if (cachedInfo) {
      setPipeline(cachedInfo);
      setIsLoading(false);
    } else {
      getPipeline();
    }
  }, [cachedInfo, getPipeline]);

  const content = pipeline ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{`[Pipeline] ${pipelineName}`}</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={overviewCard}>
            <div className={card}>
              <div className={essentialsTitle}>Pipeline Details</div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Name", pipelineName)}
                  {createGridElements("Resource Group", resourceGroup)}
                  {createGridElements("Subscription ID", subscriptionId)}
                  {createGridElements(
                    "Created",
                    showLocaleString(new Date(pipeline.systemData.createdAt))
                  )}
                </div>
              </div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements(
                    "Remote Cloud",
                    pipeline.properties.remoteCloud
                  )}
                  {createGridElements(
                    "Provision State",
                    pipeline.properties.provisioningState
                  )}
                  {createGridElements(
                    "Flow Types",
                    pipeline.properties.flowTypes?.join(";")
                  )}
                  {createGridElements("Policies", pipeline.properties.policies)}
                </div>
              </div>
            </div>
          </div>
        </PivotItem>
        <PivotItem headerText="Connections">
          <ConnectionList connections={pipeline.properties.connections ?? []} />
        </PivotItem>
        <PivotItem headerText="JSON View">
          <RawView resource={pipeline} />
        </PivotItem>
      </Pivot>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Pipeline info" /> : content}
    </div>
  );
};

export default Pipeline;
