import {
  Dropdown,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import {
  baseDiv,
  cloudField,
  headerDiv,
  searchCard,
  searchTextField,
  spinnerLoading,
  titleFont,
} from "ingressFlows/Styles";
import { validGuid } from "ingressFlows/createFlowValidation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { adminIngressAPI } from "utils/endpoints";
import { setMessage, setShow } from "store/messageBarSlice";
import ConvertFromSas from "./ConvertFromSas";
import ConvertFromOAuth from "./ConvertFromOAuth";

const ConvertFlowAccess = () => {
  const tentedClouds = useSelector((state) => state.tentedClouds.list);
  const cloudOptions = tentedClouds.map((cloud) => ({
    key: cloud.project,
    text: cloud.project,
  }));
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const [selectedCloud, setSelectedCloud] = useState(cloudOptions[0]?.key);
  const [flowGuid, setFlowGuid] = useState("");
  const [fetching, setFetching] = useState(false);
  const [flow, setFlow] = useState(null);

  const getFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setFetching(true);
    setFlow(null);

    callAuthorizedEndpoint(
      `${adminIngressAPI}/${flowGuid}?cloud=${selectedCloud}`,
      token
    )
      .then((res) =>
        res.json().then((response) => {
          setFlow(response.flow);
        })
      )
      .catch((res) =>
        res.text().then((error) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Error Getting Flow - ${error}`,
            })
          );
          dispatch(setShow(true));
        })
      )
      .finally(() => setFetching(false));
  };

  const verifyNewAccess = async (requestBody) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    return callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/validateAccess`,
      token,
      "POST",
      requestBody
    );
  };

  const convertAccess = async (requestBody) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    return callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/convertAccess`,
      token,
      "POST",
      requestBody
    ).then(() => {
      setFlow(null);
      dispatch(
        setMessage({
          type: MessageBarType.success,
          message: "Successfully converted Flow Access type",
        })
      );
      dispatch(setShow(true));
    });
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Convert Flow Access</span>
      </div>
      <div className={searchCard}>
        <PrimaryButton
          text="Get Flow"
          disabled={!selectedCloud || !validGuid(flowGuid) || fetching}
          data-testid="getFlow-button"
          onClick={() => getFlow()}
        />
        <div className={cloudField}>
          <Dropdown
            options={cloudOptions}
            selectedKey={selectedCloud}
            onChange={(event, option, index) => setSelectedCloud(option.key)}
            data-testid="cluster-dropdown"
          />
        </div>
        <div className={searchTextField}>
          <TextField
            onChange={(event, newValue) => setFlowGuid(newValue)}
            value={flowGuid}
            placeholder="Enter Flow GUID Here"
            data-testid="flowGuid"
          />
        </div>
      </div>
      {fetching && (
        <div className={spinnerLoading}>
          <Spinner
            size={SpinnerSize.large}
            label="Fetching Flow Info"
            labelPosition="top"
          />
        </div>
      )}
      {flow && (
        <>
          {flow.useOAuth ? (
            <ConvertFromOAuth
              flow={flow}
              verifyFunc={verifyNewAccess}
              convertFunc={convertAccess}
            />
          ) : (
            <ConvertFromSas
              flow={flow}
              verifyFunc={verifyNewAccess}
              convertFunc={convertAccess}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConvertFlowAccess;
