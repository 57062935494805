import { Icon } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ADMIN_MODE, CUSTOMER_MODE } from "store/modeSlice";
import CloudToggle from "./CloudToggle";
import NavSelection from "./customer/NavSelection";
import AdminNavSelection from "./admin/NavSelection";
import {
  collapsed,
  hubGroupBase,
  expanded,
  separatorLine,
  sideNavBase,
  sideNavDiv,
  privacyTextDiv,
  arrowButtonBase,
  arrowButtonDiv,
  arrowIconStyle,
  privacyTextStyle,
  privacy,
} from "./Styles";

const SideNavBar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const widthClass = isExpanded ? expanded : collapsed;
  const arrowIcon = isExpanded ? "DoubleChevronLeft" : "DoubleChevronRight";

  const currentMode = useSelector((state) => state.mode.currentUserMode);
  const navSelection =
    currentMode === CUSTOMER_MODE ? (
      <NavSelection />
    ) : ADMIN_MODE ? (
      <AdminNavSelection />
    ) : null;

  return (
    <div className={sideNavBase}>
      <div className={`${sideNavDiv} ${widthClass}`}>
        <CloudToggle />
        <div className={separatorLine} />
        {navSelection}
        <div className={separatorLine} />

        <div className={hubGroupBase}>
          <div className={privacyTextDiv}>
            <span className={privacyTextStyle}>
              &copy; 2023 - Microsoft -{" "}
              <a
                target="_blank"
                href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                rel="noreferrer"
                className={privacy}
              >
                Privacy
              </a>
            </span>
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={arrowButtonBase}
            aria-label="sidenav-collapse-toggle"
          >
            <span className={arrowButtonDiv}>
              <Icon
                iconName={arrowIcon}
                className={arrowIconStyle}
                data-testid="arrow-button"
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
