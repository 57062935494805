import { Spinner, SpinnerSize } from "@fluentui/react";
import { spinnerCentered } from "./Styles";

const LoadingView = () => {
  return (
    <div className={spinnerCentered}>
      <Spinner
        size={SpinnerSize.large}
        label="Fetching Batch Details"
        labelPosition="top"
      />
    </div>
  );
};

export default LoadingView;
