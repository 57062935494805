import { TextField } from "@fluentui/react";
import {
  batchPropCard,
  batchProperties,
  batchPropTitleDiv,
  flexRow,
  halfWidth,
  paddingTop,
  sharedUsersTitle,
} from "../Styles";

const SharedBatchOverviewPivot = (props) => {
  const { batch } = props;

  return (
    <div className={batchProperties}>
      <div className={batchPropCard}>
        <div className={sharedUsersTitle}>
          <div className={batchPropTitleDiv}>Properties</div>
        </div>
        <div className={flexRow}>
          <div className={halfWidth}>
            <TextField
              label="Name"
              readOnly={true}
              defaultValue={batch.name}
              className={paddingTop}
            />
            <TextField
              label="Service Tree ID"
              readOnly={true}
              defaultValue={batch.serviceTreeId}
              className={paddingTop}
            />
            <TextField
              label="Justification"
              readOnly={true}
              multiline
              defaultValue={batch.justification}
              className={paddingTop}
            />
          </div>
          <div className={halfWidth}>
            <TextField
              label="State"
              readOnly={true}
              defaultValue={batch.state}
              className={paddingTop}
            />
            <TextField
              label="Cloud"
              readOnly={true}
              defaultValue={batch.cloud.toUpperCase()}
              className={paddingTop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedBatchOverviewPivot;
