import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { authorizedUserInfoAPI } from "utils/endpoints";
import * as CloudRedux from "./tentedSlice";
import { getClusters } from "./clusterSlice";

// Defined constants for what view/mode is currently being rendered
export const NO_MODE = -1;
export const CUSTOMER_MODE = 0;
export const ADMIN_MODE = 1;

export const RP_MODE = "Rp";
export const TELEPORTAL_MODE = "Teleportal";

const getRpUserModes = (roles) => {
  const { user, dri, experience } = roles;

  const userModes = [];

  if (user) {
    userModes.push(CUSTOMER_MODE);
  }

  if (dri || experience) {
    userModes.push(ADMIN_MODE);
  }

  return userModes;
};

const getTeleportalUserModes = (roles) => {
  const { user, dri, approver, verifier, batchVerifier } = roles;

  const userModes = [];

  if (user) {
    userModes.push(CUSTOMER_MODE);
  }

  if (dri || approver || verifier || batchVerifier) {
    userModes.push(ADMIN_MODE);
  }

  return userModes;
};

export const getAuthorizedUserInfo = createAsyncThunk(
  "mode/getAuthorizedUserInfo",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(authorizedUserInfoAPI, token)
      .then((res) => res.json())
      .then((info) => {
        const infoObj = {};
        const cloudList = [];
        let shouldFetchClusters = false;
        info.userModes.forEach((userMode) => {
          const { cloud, appModes } = userMode;

          const appModeMapping = {};

          appModes.forEach((appMode) => {
            if (appMode.mode === TELEPORTAL_MODE) {
              const roles = getTeleportalUserModes(appMode.roles);
              if (roles.length > 0) {
                const userData = {
                  userRoles: roles,
                  roles: appMode.roles,
                };

                appModeMapping[appMode.mode] = userData;
              }
            } else if (appMode.mode === RP_MODE) {
              const roles = getRpUserModes(appMode.roles);
              if (roles.length > 0) {
                const userData = {
                  userRoles: roles,
                  roles: appMode.roles,
                };

                appModeMapping[appMode.mode] = userData;
              }
            }

            if (
              appModeMapping[appMode.mode] &&
              appModeMapping[appMode.mode]["userRoles"].includes(ADMIN_MODE)
            ) {
              shouldFetchClusters = true;
            }
          });

          if (Object.keys(appModeMapping).length > 0) {
            infoObj[cloud.project] = appModeMapping;
            cloudList.push(cloud);
          }
        });

        getThunkAPI.dispatch(set_allInfo(infoObj));
        getThunkAPI.dispatch(CloudRedux.set_clouds(cloudList));

        if (shouldFetchClusters) {
          getThunkAPI.dispatch(getClusters(token));
        }

        if (cloudList.length > 0) {
          getThunkAPI.dispatch(CloudRedux.select(cloudList[0]));
        }
      })
      .catch(() => {
        getThunkAPI.dispatch(CloudRedux.set_cloud_status("failed"));
        return Promise.reject();
      });
  }
);

export const selectCloud = createAsyncThunk(
  "mode/selectCloud",
  async (cloudName, getThunkAPI) => {
    const modeInfo = getThunkAPI.getState().mode.allInfo[cloudName];
    getThunkAPI.dispatch(set_modeInfo(modeInfo));

    const appModeKeys = Object.keys(modeInfo);
    getThunkAPI.dispatch(set_appModes(appModeKeys));
    if (!appModeKeys.includes(getThunkAPI.getState().mode.currentAppMode)) {
      getThunkAPI.dispatch(selectAppMode(appModeKeys[0]));
    }
  }
);

export const selectAppMode = createAsyncThunk(
  "mode/selectAppMode",
  async (modeKey, getThunkAPI) => {
    getThunkAPI.dispatch(set_appMode(modeKey));
    const { userRoles, roles } = getThunkAPI.getState().mode.modeInfo[modeKey];
    getThunkAPI.dispatch(set_userModes(userRoles));
    getThunkAPI.dispatch(set_currentRoles(roles));
    if (!userRoles.includes(getThunkAPI.getState().mode.currentUserMode)) {
      getThunkAPI.dispatch(set_userMode(userRoles[0]));
    }
  }
);

export const modeSlice = createSlice({
  name: "mode",
  initialState: {
    roles: {},
    allInfo: {},
    modeInfo: {},
    appModes: [],
    currentAppMode: NO_MODE,
    userModes: [],
    currentUserMode: NO_MODE,
    status: null,
  },
  reducers: {
    set_allInfo: (state, action) => {
      state.allInfo = action.payload;
    },
    set_modeInfo: (state, action) => {
      state.modeInfo = action.payload;
    },
    set_appModes: (state, action) => {
      state.appModes = action.payload;
    },
    set_appMode: (state, action) => {
      state.currentAppMode = action.payload;
    },
    set_userModes: (state, action) => {
      state.userModes = action.payload;
    },
    set_userMode: (state, action) => {
      state.currentUserMode = action.payload;
    },
    set_currentRoles: (state, action) => {
      state.roles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuthorizedUserInfo.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAuthorizedUserInfo.fulfilled, (state, action) => {
      state.status = "success";
    });
    builder.addCase(getAuthorizedUserInfo.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const {
  set_allInfo,
  set_modeInfo,
  set_appModes,
  set_appMode,
  set_userModes,
  set_userMode,
  set_currentRoles,
} = modeSlice.actions;

export default modeSlice.reducer;
