import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

export const Link = (props) => {
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Destructure 'to' from props and append "hello" to it
  const { to, ...rest } = props;
  const modifiedTo = `${to}?cloud=${selectedCloud.name}`;

  return (
    <RouterLink {...rest} to={modifiedTo}>
      {props.children}
    </RouterLink>
  );
};
