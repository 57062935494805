import * as React from "react";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useCallback } from "react";
import { protectedResources } from "authConfig";
import { useDispatch, useSelector } from "react-redux";
import { getBatches } from "store/batchSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import PaginationTable from "paginationTable/Table";
import { getShortenedBatchName } from "batches/Utils";
import { Link } from "utils/Link";

export const Batches = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const batches = useSelector((state) => state.batch.list)
    .map((batch) => {
      return {
        ...batch,
        shortenedName: getShortenedBatchName(batch.name),
        dateObj: new Date(batch.lastModified),
        dateModified: new Date(batch.lastModified).toLocaleDateString(),
      };
    })
    .sort((a, b) => {
      return b.dateObj - a.dateObj;
    });
  const getBatchesStatus = useSelector((state) => state.batch.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the Batch Table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "shortenedName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return (
          <Link className={linkSpan} to={`/batches/${item.name}`}>
            {item.shortenedName}
          </Link>
        );
      },
    },
    {
      key: "column2",
      name: "Date Modified",
      fieldName: "dateModified",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "State",
      fieldName: "state",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/batches/${item.name}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of Batch List
   */
  const getBatchList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getBatches({ token: accessToken, cloud: selectedCloud.project }));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve batch list
   */
  useEffect(() => {
    getBatchList();
  }, [getBatchList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>My Batches</span>
        <PrimaryButton
          text="New batch request"
          onClick={() => navigate("/createbatch")}
        />
        <DefaultButton
          text="Refresh"
          onClick={() => getBatchList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={batches}
        columns={columns}
        tableStyling={tableStyling}
        loading={getBatchesStatus === "loading" || selectedCloud === null}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
};

export default Batches;
