import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { pastesAPI, sharedPastesAPI } from "utils/endpoints";

export const getPastes = createAsyncThunk(
  "paste/getPastes",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${pastesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const pasteList = obj.pastes;
        return pasteList;
      });
  }
);

export const getSharedPastes = createAsyncThunk(
  "paste/getSharedPastes",
  async (params, getThunkAPI) => {
    return callAuthorizedEndpoint(
      `${sharedPastesAPI}?cloud=${params.cloud}`,
      params.token
    )
      .then((res) => res.json())
      .then((obj) => {
        const pasteList = obj.pastes;
        return pasteList;
      });
  }
);

export const pasteSlice = createSlice({
  name: "paste",
  initialState: {
    list: [],
    status: null,
    shared_list: [],
    shared_status: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getPastes.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPastes.fulfilled, (state, action) => {
      state.list = action.payload;
      state.status = "success";
    });
    builder.addCase(getPastes.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getSharedPastes.pending, (state, action) => {
      state.shared_status = "loading";
    });
    builder.addCase(getSharedPastes.fulfilled, (state, action) => {
      state.shared_list = action.payload;
      state.shared_status = "success";
    });
    builder.addCase(getSharedPastes.rejected, (state, action) => {
      state.shared_status = "failed";
    });
  },
});

export default pasteSlice.reducer;
