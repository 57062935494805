import * as React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { mergeStyles } from "@fluentui/merge-styles";
import { loader } from "@monaco-editor/react";

import Layout from "./Layout";
import Dashboard from "dashboard/Dashboard";
import Notifications from "notifications/Notifications";
import Batches from "batches/customer/Batches";
import CreateBatches from "batches/customer/CreateBatches";
import SharedBatches from "batches/customer/SharedBatches";
import SharedBatchDetail from "batches/customer/SharedBatchDetails";
import IngressFlows from "ingressFlows/customer/IngressFlows";
import CreateIngressFlows from "ingressFlows/customer/CreateIngressFlows";
import EgressFlows from "egressFlows/customer/EgressFlows";
import CreateEgressFlows from "egressFlows/customer/CreateEgressFlows";
import Analytics from "analytics/Analytics";
import BatchDetails from "batches/customer/BatchDetails";
import { useEffect } from "react";
import IngressFlowDetails from "ingressFlows/customer/IngressFlowDetails";
import EgressFlowDetails from "egressFlows/customer/EgressFlowDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  set_userMode,
  ADMIN_MODE,
  CUSTOMER_MODE,
  RP_MODE,
  TELEPORTAL_MODE,
} from "store/modeSlice";
import BatchApprovals from "batches/admin/BatchApprovals";
import AdminBatchDetails from "batches/admin/AdminBatchDetails";
import AdminIngressFlows from "ingressFlows/admin/AdminIngressFlows";
import AdminIngressFlowDetails from "ingressFlows/admin/AdminIngressFlowDetails";
import ApprovalIngressFlows from "ingressFlows/admin/ApprovalIngressFlows";
import ApprovalIngressDetails from "ingressFlows/admin/ApprovalIngressDetails";
import AdminEgressFlows from "egressFlows/admin/AdminEgressFlows";
import AdminEgressFlowDetails from "egressFlows/admin/AdminEgressFlowDetails";
import VerifyFlows from "egressFlows/admin/VerifyFlows";
import VerifyEgressFlow from "egressFlows/admin/VerifyEgressFlow";
import ApprovalFlows from "egressFlows/admin/ApprovalFlows";
import ApproveEgressFlow from "egressFlows/admin/ApproveEgressFlow";
import CustomerHome from "pages/CustomerHome";
import AdminHome from "pages/AdminHome";
import Pastes from "pastebin/customer/Pastes";
import CreatePaste from "pastebin/customer/CreatePaste";
import PasteDetails from "pastebin/customer/PasteDetails";
import SharedPastes from "pastebin/customer/SharedPastes";
import GetObjStatus from "adminOps/GetObjStatus";
import ResyncFlow from "adminOps/ResyncFlow";
import ObjResend from "adminOps/ObjResend";
import ResendManifest from "adminOps/ResendManifest";
import BatchResend from "adminOps/BatchResend";
import AdminRp from "adminOps/AdminRp";
import ConvertFlowAccess from "ingressFlows/admin/ConvertFlowAccess";
import AdminDashboard from "dashboard/admin/AdminDashboard";
import Pipelines from "pipelines/admin/Pipelines";
import Connections from "connections/admin/Connections";
import Flows from "flows/admin/Flows";
import Pipeline from "pipelines/admin/Pipeline";
import Connection from "connections/admin/Connection";
import Flow from "flows/admin/Flow";
import AuditSearch from "adminOps/AuditSearch";
import BatchFileReviews from "batches/admin/BatchFileReviews";
import BatchFileReview from "batches/admin/BatchFileReview";

const rootStyle = mergeStyles({
  [`& a`]: {
    color: "rgb(0, 120, 212)",
  },
  [`a:focus-visible`]: {
    outline: "webkit-focus-ring-color auto 1px",
  },
  selectors: {
    ".btn-primary": {
      color: "#fff",
      backgrounColor: "#1b6ec2",
      borderColor: "#1861ac",
    },
  },

  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflow: "hidden",
  fontSize: "14px",
  height: "100%",
});

loader.config({ paths: { vs: "/vs" } });

const App = () => {
  useMsalAuthentication("redirect");

  const dispatch = useDispatch();
  const currentUserMode = useSelector((state) => state.mode.currentUserMode);
  const currentAppMode = useSelector((state) => state.mode.currentAppMode);

  useEffect(() => {
    if (localStorage.getItem("mode") === ADMIN_MODE.toString()) {
      dispatch(set_userMode(ADMIN_MODE));
    } else if (localStorage.getItem("mode") === CUSTOMER_MODE.toString()) {
      dispatch(set_userMode(CUSTOMER_MODE));
    }

    document.body.style.overflowY = "hidden";
  }, [dispatch]);

  return (
    <div className={rootStyle}>
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            {currentAppMode === RP_MODE && (
              <>
                {currentUserMode === ADMIN_MODE && (
                  <>
                    <Route path="/" element={<AdminHome />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="pipeline">
                      <Route index element={<Pipelines />} />
                      <Route path=":subscriptionId">
                        <Route path=":resourceGroup">
                          <Route path=":pipelineName" element={<Pipeline />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="connection">
                      <Route index element={<Connections />} />
                      <Route path=":subscriptionId">
                        <Route path=":resourceGroup">
                          <Route
                            path=":connectionName"
                            element={<Connection />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="flow">
                      <Route index element={<Flows />} />
                      <Route path=":subscriptionId">
                        <Route path=":resourceGroup">
                          <Route path=":connectionName">
                            <Route path=":flowName" element={<Flow />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                    <Route path="GetObjectStatus" element={<GetObjStatus />} />
                    <Route path="resync" element={<ResyncFlow />} />
                    <Route path="resend" element={<ObjResend />} />
                    <Route path="manifest" element={<ResendManifest />} />
                    <Route path="batchResend" element={<BatchResend />} />
                    <Route path="adminRp" element={<AdminRp />} />
                  </>
                )}
              </>
            )}
            {currentAppMode === TELEPORTAL_MODE && (
              <>
                {currentUserMode === CUSTOMER_MODE && (
                  <>
                    <Route path="/" element={<CustomerHome />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="batches">
                      <Route index element={<Batches />} />
                      <Route path=":batchName" element={<BatchDetails />} />
                    </Route>
                    <Route path="createbatch" element={<CreateBatches />} />
                    <Route path="sharedbatches">
                      <Route index element={<SharedBatches />} />
                      <Route
                        path=":batchName"
                        element={<SharedBatchDetail />}
                      />
                    </Route>
                    <Route path="ingress">
                      <Route index element={<IngressFlows />} />
                      <Route
                        path=":flowGuid"
                        element={<IngressFlowDetails />}
                      />
                    </Route>
                    <Route
                      path="CreateIngress"
                      element={<CreateIngressFlows />}
                    />
                    <Route path="egress">
                      <Route index element={<EgressFlows />} />
                      <Route path=":flowGuid" element={<EgressFlowDetails />} />
                    </Route>
                    <Route
                      path="CreateEgress"
                      element={<CreateEgressFlows />}
                    />
                    <Route path="analytics" element={<Analytics />} />
                    <Route path="pastes">
                      <Route index element={<Pastes />} />
                      <Route path=":pasteUrl" element={<PasteDetails />} />
                    </Route>
                    <Route path="createpaste" element={<CreatePaste />} />
                    <Route path="sharedpastes" element={<SharedPastes />} />
                  </>
                )}
                {currentUserMode === ADMIN_MODE && (
                  <>
                    <Route path="/" element={<AdminHome />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="batches">
                      <Route index element={<BatchApprovals />} />
                      <Route
                        path=":batchName"
                        element={<AdminBatchDetails />}
                      />
                    </Route>
                    <Route path="batchreview">
                      <Route index element={<BatchFileReviews />} />
                      <Route path=":batchName" element={<BatchFileReview />} />
                    </Route>
                    <Route path="ingress">
                      <Route index element={<AdminIngressFlows />} />
                      <Route
                        path=":flowGuid"
                        element={<AdminIngressFlowDetails />}
                      />
                    </Route>
                    <Route
                      path="convertflowaccess"
                      element={<ConvertFlowAccess />}
                    />
                    <Route path="ApproveIngress">
                      <Route index element={<ApprovalIngressFlows />} />
                      <Route
                        path=":flowGuid"
                        element={<ApprovalIngressDetails />}
                      />
                    </Route>
                    <Route path="egress">
                      <Route index element={<AdminEgressFlows />} />
                      <Route
                        path=":flowGuid"
                        element={<AdminEgressFlowDetails />}
                      />
                    </Route>
                    <Route path="VerifyEgress">
                      <Route index element={<VerifyFlows />} />
                      <Route path=":flowGuid" element={<VerifyEgressFlow />} />
                    </Route>
                    <Route path="ApproveEgress">
                      <Route index element={<ApprovalFlows />} />
                      <Route path=":flowGuid" element={<ApproveEgressFlow />} />
                    </Route>
                    <Route path="GetObjectStatus" element={<GetObjStatus />} />
                    <Route path="resync" element={<ResyncFlow />} />
                    <Route path="resend" element={<ObjResend />} />
                    <Route path="manifest" element={<ResendManifest />} />
                    <Route path="batchResend" element={<BatchResend />} />
                    <Route path="auditSearch" element={<AuditSearch />} />
                    <Route path="adminRp" element={<AdminRp />} />
                  </>
                )}
              </>
            )}
          </Routes>
        </Layout>
      </AuthenticatedTemplate>
    </div>
  );
};

export default App;
