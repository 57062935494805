import { ActivityItem, Icon, Link } from "@fluentui/react";
import { activityItemDiv, nameText } from "./Styles";

export const getShortenedBatchName = (batchName) => {
  const batchNameParts = batchName.split("-");
  return batchNameParts[0] === "v"
    ? batchNameParts.slice(2).join("-")
    : batchName.substring(batchName.indexOf("-") + 1);
};

const BATCH_CREATE_EVENT = "BATCH_CREATED";
const BATCH_UPDATED_EVENT = "BATCH_UPDATED";
const BATCH_SUBMITTED_EVENT = "BATCH_SUBMITTED";
const BATCH_DELETED_EVENT = "BATCH_DELETED";
const BATCH_ADD_SHARED_USER_EVENT = "BATCH_ADD_SHARED_USER";
const BATCH_DELETE_SHARED_USER_EVENT = "BATCH_DELETE_SHARED_USER";
const BATCH_APPROVED_EVENT = "BATCH_APPROVED";
const BATCH_REJECTED_EVENT = "BATCH_REJECTED";

export const BATCH_EVENT_TO_ICON_MAPPING = {
  [BATCH_CREATE_EVENT]: "FabricNewFolder",
  [BATCH_UPDATED_EVENT]: "Edit",
  [BATCH_SUBMITTED_EVENT]: "PublicFolder",
  [BATCH_DELETED_EVENT]: "Delete",
  [BATCH_ADD_SHARED_USER_EVENT]: "UserFollowed",
  [BATCH_DELETE_SHARED_USER_EVENT]: "UserRemove",
  [BATCH_APPROVED_EVENT]: "CheckMark",
  [BATCH_REJECTED_EVENT]: "Cancel",
};

export const convertDateToLocaleString = (date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const generateActivityDescription = (event, currIndex, showMoreInfo) => {
  if (event.eventName === BATCH_CREATE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === BATCH_UPDATED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === BATCH_SUBMITTED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;submitted Batch for approval</span>
      </div>
    );
  } else if (event.eventName === BATCH_DELETED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;deleted Batch</span>
      </div>
    );
  } else if (event.eventName === BATCH_ADD_SHARED_USER_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;added&nbsp;</span>
        <span key={3} className={nameText}>
          {event.eventDetails.User}
        </span>
        <span key={4}>&nbsp;as a shared user to the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_DELETE_SHARED_USER_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;removed&nbsp;</span>
        <span key={3} className={nameText}>
          {event.eventDetails.User}
        </span>
        <span key={4}>&nbsp;as a shared user to the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_APPROVED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved the Batch&nbsp;</span>
      </div>
    );
  } else if (event.eventName === BATCH_REJECTED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;rejected the Batch&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          (see more details)
        </Link>
      </div>
    );
  }
};

export const generateActivityItems = (events, showMoreInfo) => {
  return events?.map((event, currIndex) => (
    <div key={currIndex}>
      <ActivityItem
        activityDescription={generateActivityDescription(
          event,
          currIndex,
          showMoreInfo
        )}
        activityIcon={
          <Icon iconName={BATCH_EVENT_TO_ICON_MAPPING[event.eventName]} />
        }
        timeStamp={convertDateToLocaleString(event.creationDate)}
        className={currIndex !== 0 ? activityItemDiv : null}
      />
    </div>
  ));
};

const getDiffInfo = (currState, newObj) => {
  const diffInfo = {};

  Object.keys(newObj).forEach((key) => {
    if (
      currState.hasOwnProperty(key) &&
      newObj[key] !== null &&
      currState[key] !== newObj[key]
    ) {
      diffInfo[key] = [currState[key], newObj[key]];
      currState[key] = newObj[key];
    }
  });

  return diffInfo;
};

export const generateInfoList = (events) => {
  const infoList = new Array(events.length);

  let currState = {
    Cloud: "",
    Justification: "",
    ServiceTreeId: "",
  };

  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];

    if (
      event.eventName === BATCH_CREATE_EVENT ||
      event.eventName === BATCH_UPDATED_EVENT
    ) {
      event.diffInfo = getDiffInfo(currState, event.eventDetails);
    }

    infoList[i] = event;
  }

  return events;
};

export const generateDiffCallout = (diffObj) => {
  return (
    <div>
      <div>{`{`}</div>
      {Object.keys(diffObj).map((key) => {
        if (Array.isArray(diffObj[key]) && diffObj[key].length === 2) {
          return (
            <div
              key={key}
            >{`${key}: ${diffObj[key][0]} -> ${diffObj[key][1]}`}</div>
          );
        } else {
          return <div key={key}>{`${key}: ${diffObj[key]}`}</div>;
        }
      })}
      <div>{`}`}</div>
    </div>
  );
};
