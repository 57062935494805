export const validFlowGuid = (guid) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(guid);

export const guidErrorText = (guid) => {
  return validFlowGuid(guid) ? "" : "Not a valid GUID";
};

export const objErrorText = (objName) =>
  objName?.length > 0 ? "" : "Object Name must be provided";

export const validDatetime = (datetime) =>
  /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/.test(datetime);

export const datetimeErrorText = (datetime) => {
  return validDatetime(datetime)
    ? ""
    : "Datetime must be in the form  of yyyy-mm-ddThh:mm:ssZ";
};

/**
 * Checks if a given string has at least 1 alphanumeric character
 * @param {string} str
 * @returns True if the string has 1 letter or number
 */
export const hasAlphanumeric = (str) =>
  str ? new RegExp("^.*[a-zA-Z0-9]+.*$").test(str) : false;

/**
 * Checks that each row is in the form of {cluster_name},{flow_id},{objectHash}
 * @param {Array<Array<string>>} rows
 * @returns {Array<JSON>} list of the valid rows
 *
 * NOTE: if any row is invalid, a console.warn will be logged to the browser console with the specified line & error
 */
export const validateRows = (rows) => {
  const validRequests = [];

  rows.forEach((row, index) => {
    if (row.length !== 3) {
      console.warn(
        "Line " + (index + 1) + ": Found more/less than 2 delimiters."
      );
      return;
    }

    const cluster = row[0].trim();
    const flowGuid = row[1].trim();
    const objHash = row[2].trim();

    if (!hasAlphanumeric(cluster)) {
      console.warn("Line " + (index + 1) + ": No cluster was provided.");
      return;
    }

    if (!validFlowGuid(flowGuid)) {
      console.warn(
        "Line " + (index + 1) + ": No valid Flow GUID was provided."
      );
      return;
    }

    if (!hasAlphanumeric(objHash)) {
      console.warn("Line " + (index + 1) + ": No Object Hash was provided.");
      return;
    }

    validRequests.push({
      cluster,
      flowGuid,
      objHash,
    });
  });

  return validRequests;
};
