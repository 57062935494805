import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

export const ingressLinks = [
  {
    title: "My ingress flows",
    icon: "CloudSearch",
    path: "/ingress",
  },
  {
    title: "Create new ingress flow",
    icon: "CloudFlow",
    path: "/CreateIngress",
  },
];

const IngressNavGroup = () => {
  const rootProps = {
    title: "Ingress Flows",
    icon: "CloudUpload",
    path: ingressLinks[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={ingressLinks} />;
};

export default IngressNavGroup;
