import { useAccount, useMsal } from "@azure/msal-react";
import { Dropdown, Label, Pivot, PivotItem, TextField } from "@fluentui/react";
import { protectedResources } from "authConfig";
import LoadingView from "pastebin/LoadingView";
import {
  baseDiv,
  card,
  cardLabels,
  createPasteCard,
  errorTextStyle,
  headerDiv,
  pivotPadding,
  textEditor,
  titleFont,
} from "pastebin/Styles";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import Editor, { useMonaco } from "@monaco-editor/react";
import { pastesAPI } from "utils/endpoints";
import { getPasteDate } from "pastebin/pasteTimestamp";

const PasteDetails = () => {
  const { pasteUrl } = useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [paste, setPaste] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [selectedLanguageKey, setSelectedLanguageKey] = useState(
    paste?.pasteSyntaxLanguage
  );
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const monaco = useMonaco();

  const fetchPaste = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${pastesAPI}/${pasteUrl}`, token)
      .then((response) => response.json())
      .then((pasteObj) => {
        setPaste(pasteObj);
        setSelectedLanguageKey(pasteObj.pasteSyntaxLanguage);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        })
      );
  }, [account, pasteUrl, instance]);

  useEffect(() => {
    fetchPaste();
    if (monaco) {
      const languages = monaco?.languages.getLanguages().map((language) => {
        return {
          key: language.id,
          text: language.aliases ? language.aliases[0] : language.id,
        };
      });
      setSupportedLanguages(languages);
    }
  }, [fetchPaste, monaco]);

  const pasteView = paste ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{paste.pasteName}</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={createPasteCard}>
            <div className={card}>
              <h4>
                Sent by <u>{paste.pasteSenderAlias}</u> to{" "}
                <u>{paste.pasteDestinationCloud}</u> at{" "}
                <u>{getPasteDate(paste.pasteTimeStamp)}</u>
              </h4>
              <div className={cardLabels}>
                {paste.pasteRecipients && (
                  <>
                    <TextField
                      label="Paste Recipients"
                      readOnly={true}
                      value={paste.pasteRecipients}
                    />
                    <br />
                  </>
                )}
                <Dropdown
                  label="Language/Syntax Highlight"
                  options={supportedLanguages}
                  selectedKey={selectedLanguageKey}
                  onChange={(event, option, index) =>
                    setSelectedLanguageKey(option.key)
                  }
                />
              </div>
              <br />
              <Label>Paste Content</Label>
              <div className={textEditor}>
                <Editor
                  language={selectedLanguageKey}
                  defaultValue={paste.pasteContent}
                  options={{ readOnly: true }}
                />
              </div>
            </div>
          </div>
        </PivotItem>
      </Pivot>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : pasteView}</div>;
};

export default PasteDetails;
