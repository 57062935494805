import * as React from "react";
import { baseDiv, comingSoon } from "./Styles";

export const Analytics = () => {
  return (
    <div className={baseDiv}>
      <span className={comingSoon}>Coming Soon!</span>
    </div>
  );
};

export default Analytics;
