import {
  DefaultButton,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  cancelButton,
  deleteBatchButton,
  modalContainer,
  modalFooter,
  modalHeader,
  rejectionInput,
} from "batches/Styles";

const RejectModal = (props) => {
  const { isModalOpen, hideModal, rejection, setRejection, rejectBatch } =
    props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Reject Batch</div>
      <TextField
        label="Rejection Reason"
        className={rejectionInput}
        value={rejection}
        onChange={(event, value) => setRejection(value)}
        multiline={true}
      />
      <div className={modalFooter}>
        <PrimaryButton
          disabled={rejection.trim().length < 1}
          onClick={() => rejectBatch()}
          className={deleteBatchButton}
          data-testid="reject-button"
        >
          Reject
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default RejectModal;
