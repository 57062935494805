import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { ingressFlowAPI } from "utils/endpoints";
import EditFlow from "./EditFlow";
import FlowMetrics from "../FlowMetrics";
import LoadingView from "../LoadingView";
import { useBoolean } from "@fluentui/react-hooks";
import {
  baseDiv,
  cancelButton,
  card,
  createFlowCard,
  deleteFlowButton,
  errorTextStyle,
  essentialsColumn,
  essentialsGrid,
  essentialsText,
  essentialsTitle,
  headerDiv,
  pivotPadding,
  titleFont,
} from "../Styles";
import HistoricalTimeline from "ingressFlows/HistoricalTimeline";

const createGridElements = (keyText, valueText) => (
  <>
    <div className={essentialsText}>{keyText}</div>
    <div className={essentialsText}>:</div>
    <div className={essentialsText}>{valueText}</div>
  </>
);

const IngressFlowDetails = () => {
  const { flowGuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flow, setFlow] = useState(null);
  const [hideDialog, { toggle: toggleDialog }] = useBoolean(true);
  const [historyEvents, setHistoryEvents] = useState(null);

  const cachedFlowInfo = useSelector((state) => state.ingressFlows.list).find(
    (flow) => flow.dataflowGuid === flowGuid
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const deleteFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const apiEndpoint = `${ingressFlowAPI}/${flow.dataflowGuid}?cloud=${flow.cloud}`;

    callAuthorizedEndpointWithBody(apiEndpoint, token, "DELETE", {})
      .then(() => {
        navigate("/ingress");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          toggleDialog();
        })
      );
  };

  const deleteDialog = {
    type: DialogType.normal,
    title: "Delete Flow",
    subText: "Are you sure you want to delete this Flow?",
    footer: (
      <>
        <PrimaryButton
          onClick={deleteFlow}
          text="Delete"
          className={deleteFlowButton}
          data-testid="delete-button"
        />
        <DefaultButton
          onClick={toggleDialog}
          className={cancelButton}
          text="Cancel"
        />
      </>
    ),
  };

  const getFlowStatusText = () => {
    if (!flow.finalApprover && !flow.aupForm) {
      return "Approval Required";
    }

    if (!flow.onboarded) {
      return "Onboarding in process";
    }

    return flow.enabled ? "Enabled" : "Disabled";
  };

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${ingressFlowAPI}/${flowGuid}?cloud=${selectedCloud.project}`,
      token
    )
      .then((response) => response.json())
      .then((response) => {
        setFlow(response.flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [account, instance, flowGuid, selectedCloud]);

  useEffect(() => {
    if (cachedFlowInfo) {
      setFlow(cachedFlowInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedFlowInfo, getFlow]);

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{flow.title}</span>
        <PrimaryButton
          text="Delete Flow"
          onClick={toggleDialog}
          className={deleteFlowButton}
        />
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={createFlowCard}>
            <div className={card}>
              <div className={essentialsTitle}>Flow Details</div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Flow Identifier", flowGuid)}
                  {createGridElements("Owner", flow.primaryContact)}
                  {createGridElements("Flow Status", getFlowStatusText())}
                </div>
              </div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Title", flow.title)}
                  {createGridElements("Cloud", flow.cloud.toUpperCase())}
                </div>
              </div>
            </div>
          </div>
          <FlowMetrics flow={flow} />
        </PivotItem>
        <PivotItem headerText="Info">
          <EditFlow flow={flow} setFlow={setFlow} />
        </PivotItem>
        <PivotItem headerText="History">
          <HistoricalTimeline
            adminMode={false}
            flow={flow}
            events={historyEvents}
            setEvents={setHistoryEvents}
          />
        </PivotItem>
      </Pivot>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleDialog}
        dialogContentProps={deleteDialog}
      >
        <DialogFooter>{deleteDialog.footer}</DialogFooter>
      </Dialog>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow details" /> : content}
    </div>
  );
};

export default IngressFlowDetails;
