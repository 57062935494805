/**
 * Attaches a given access token to a HTTP Request.
 */
export const callAuthorizedEndpoint = async (endpoint, accessToken) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return new Promise((resolve, reject) => {
    fetch(endpoint, options)
      .then((response) => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const callAuthorizedEndpointWithBody = async (
  endpoint,
  accessToken,
  method,
  body
) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  };

  return new Promise((resolve, reject) => {
    fetch(endpoint, options)
      .then((response) => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

/**
 * Function to retrieve an authorization token to call protected API endpoints
 * @param {*} msalInstance instance from the msal "useAccount()" hook
 * @param {*} scopes requested scopes of the api
 * @param {*} account account making the token calls
 * @returns string bearer token
 */
export const getAuthorizationToken = async (msalInstance, scopes, account) => {
  return new Promise((resolve, reject) => {
    const requestFormat = {
      scopes: scopes,
      account: account,
    };
    msalInstance
      .acquireTokenSilent(requestFormat)
      .then((response) => {
        resolve(response.accessToken);
      })
      .catch(() => {
        msalInstance
          .acquireTokenPopup(requestFormat)
          .then((response) => {
            resolve(response.accessToken);
          })
          .catch((e) => reject(e));
      });
  });
};
