import { useAccount, useMsal } from "@azure/msal-react";
import { MessageBarType, NormalPeoplePicker } from "@fluentui/react";
import { graphApiScopes, protectedResources } from "authConfig";
import {
  getUserAccountInitials,
  SearchGraphUserAPI,
} from "employeePicker/EmployeePicker";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { batchesAPI } from "utils/endpoints";
import SharedUser from "./SharedUser";
import {
  peopleSelectionDiv,
  sharedUsersDiv,
  sharedUsersTitle,
  sharedUsersTitleDiv,
  smallText,
  userListDiv,
  userListSmallText,
} from "./Styles";

/**
 * Helper function to get alias from the UserPrincipalName
 * @param {string} upn UserPrincipalName
 * @returns {string} alias
 */
export const getAliasFromUserPrincipalName = (upn) => {
  return upn.substring(0, upn.indexOf("@")).toLowerCase();
};

const SharedUserList = (props) => {
  const { batchName, batchObj, setBatch } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const pickerSuggestions = {
    noResultsFoundText: "No results found",
    loadingText: "Loading",
  };

  const addSharedUser = async (alias) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${batchesAPI}/${batchName.trim()}/sharedusers/${alias}`,
      token,
      "POST",
      {}
    )
      .then((response) => response.json())
      .then((response) => {
        setBatch({ ...batchObj, sharedUsers: response.sharedUsers });
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: `successfully added ${alias} to shared access list`,
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  };

  const removeSharedUser = async (alias) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${batchesAPI}/${batchName.trim()}/sharedusers/${alias}`,
      token,
      "DELETE",
      {}
    )
      .then((response) => response.json())
      .then((response) => {
        setBatch({ ...batchObj, sharedUsers: response.sharedUsers });
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: `successfully removed ${alias} from shared access list`,
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  };

  const onSearchedUserClicked = (selectedItem) => {
    const alias = getAliasFromUserPrincipalName(selectedItem.upn);
    addSharedUser(alias);
    return null; // this prevents the picker from rendering it's own "selection"
  };

  const searchSharedUser = async (searchText) => {
    const token = await getAuthorizationToken(
      instance,
      graphApiScopes,
      account
    );

    return callAuthorizedEndpoint(SearchGraphUserAPI(searchText), token)
      .then((response) => response.json())
      .then((response) => {
        return response.value.map((user, currIndex) => {
          return {
            imageInitials: getUserAccountInitials(user.displayName),
            key: currIndex,
            secondaryText: user.userPrincipalName,
            text: user.displayName,
            upn: user.userPrincipalName,
          };
        });
      })
      .catch(() => {
        return [];
      });
  };

  const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
    return filterText ? searchSharedUser(filterText) : [];
  };

  return (
    <div className={sharedUsersDiv}>
      <div className={sharedUsersTitleDiv}>
        <div className={sharedUsersTitle}>Shared Users</div>
      </div>
      {setBatch && (
        <div className={peopleSelectionDiv}>
          <div className={smallText}>Add User</div>
          <NormalPeoplePicker
            onResolveSuggestions={onFilterChanged}
            pickerSuggestionsProps={pickerSuggestions}
            onItemSelected={onSearchedUserClicked}
            aria-label="person-picker-input"
          />
        </div>
      )}
      <div className={userListDiv}>
        <div className={userListSmallText}>Added</div>
        {batchObj.sharedUsers.map((alias) => (
          <div key={alias}>
            <SharedUser
              alias={alias}
              removeSharedUser={removeSharedUser}
              setBatch={setBatch}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedUserList;
