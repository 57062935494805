import { useAccount, useMsal } from "@azure/msal-react";
import { Dropdown } from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { analyticsAPI } from "utils/endpoints";
import LoadingView from "./LoadingView";
import {
  card,
  createFlowCard,
  createSectionTitle,
  daysAgoPicker,
  flowMetricsRow,
  metricsBox,
  metricsBoxBody,
} from "./Styles";

const timeframeOptions = [
  { key: "1d", text: "1 Day" },
  { key: "3d", text: "3 Days" },
  { key: "7d", text: "7 Days" },
  { key: "30d", text: "30 Days" },
];

const craftQueryParams = (flowId, cloud, lookback) =>
  `?flow=${flowId}&&cloud=${cloud}&&lookback=${lookback}`;

const FlowMetrics = (props) => {
  const { flow } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const mounted = useRef(false);
  const [timeframe, setTimeframe] = useState(timeframeOptions[0]);
  const [objDownloaded, setObjDownloaded] = useState(null);
  const [totalSize, setTotalSize] = useState(null);
  const [failDownload, setFailDownload] = useState(null);

  useEffect(() => {
    mounted.current = true;

    const fetchMetrics = async (daysAgo) => {
      setObjDownloaded(null);
      setTotalSize(null);
      setFailDownload(null);

      const token = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );

      const queryParams = craftQueryParams(
        flow.dataflowGuid,
        flow.cloud,
        daysAgo
      );

      callAuthorizedEndpoint(
        `${analyticsAPI}/ObjectsDownloaded${queryParams}`,
        token
      )
        .then((response) => response.json())
        .then((response) => {
          if (!mounted.current) return;

          if (!response.queryHadData) {
            setObjDownloaded("0");
            setTotalSize("0");
          } else {
            setObjDownloaded(response.data.downloadedObjectsCount);
            setTotalSize(response.data.downloadedObjectsSize);
          }
        });

      callAuthorizedEndpoint(
        `${analyticsAPI}/ObjectsFailed${queryParams}`,
        token
      )
        .then((response) => response.json())
        .then((response) => {
          if (!mounted.current) return;

          if (!response.queryHadData) {
            setFailDownload("0");
          } else {
            setFailDownload(response.data.failCount);
          }
        });
    };

    fetchMetrics(timeframe.key);

    return () => {
      mounted.current = false;
    };
  }, [account, instance, timeframe, flow.cloud, flow.dataflowGuid]);

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Flow Metrics</div>
        <Dropdown
          label="Timeframe"
          selectedKey={timeframe.key}
          onChange={(event, option, index) => setTimeframe(option)}
          options={timeframeOptions}
          className={daysAgoPicker}
        />
        <div className={flowMetricsRow}>
          <div className={metricsBox}>
            <div className={metricsBoxBody}>
              <h1>
                {objDownloaded ? (
                  objDownloaded
                ) : (
                  <LoadingView label="Fetching" />
                )}
              </h1>
              <span># Objects Downloaded</span>
            </div>
          </div>
          <div className={metricsBox}>
            <div className={metricsBoxBody}>
              <h1>
                {totalSize ? totalSize : <LoadingView label="Fetching" />}
              </h1>
              <span>Downloaded Total Size In Bytes</span>
            </div>
          </div>
          <div className={metricsBox}>
            <div className={metricsBoxBody}>
              <h1>
                {failDownload ? failDownload : <LoadingView label="Fetching" />}
              </h1>
              <span># Objects Failed Download</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowMetrics;
