/**
 * Taken from the base64-js module
 * https://github.com/beatgammit/base64-js/
 */
export function byteArrayToBase64(uint8) {
  var lookup =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
    extraBytes = uint8.length % 3, // if we have 1 byte left, pad 2 bytes
    output = "",
    temp,
    length,
    i;

  function tripletToBase64(num) {
    return (
      lookup[(num >> 18) & 0x3f] +
      lookup[(num >> 12) & 0x3f] +
      lookup[(num >> 6) & 0x3f] +
      lookup[num & 0x3f]
    );
  }

  // go through the array every three bytes, we'll deal with trailing stuff later
  for (i = 0, length = uint8.length - extraBytes; i < length; i += 3) {
    temp = (uint8[i] << 16) + (uint8[i + 1] << 8) + uint8[i + 2];
    output += tripletToBase64(temp);
  }

  // pad the end with zeros, but make sure to not forget the extra bytes
  switch (extraBytes) {
    case 1:
      temp = uint8[uint8.length - 1];
      output += lookup[temp >> 2];
      output += lookup[(temp << 4) & 0x3f];
      output += "==";
      break;
    case 2:
      temp = (uint8[uint8.length - 2] << 8) + uint8[uint8.length - 1];
      output += lookup[temp >> 10];
      output += lookup[(temp >> 4) & 0x3f];
      output += lookup[(temp << 2) & 0x3f];
      output += "=";
      break;
    default:
      break;
  }

  return output;
}

/**
 *  Conversion utilty from dwords to bytes
 *
 */
export function dwordstobytes(data) {
  var out = new Uint8Array(data.length * 4);

  for (let i = 0; i < data.length; i++) {
    out[i * 4] = data[i] & 0xff;
    out[i * 4 + 1] = (data[i] >>> 8) & 0xff;
    out[i * 4 + 2] = (data[i] >>> 16) & 0xff;
    out[i * 4 + 3] = (data[i] >>> 24) & 0xff;
  }

  return out;
}
