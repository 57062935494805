import { Icon, TextField, TooltipHost } from "@fluentui/react";

import {
  getServiceNameErrorText,
  getServiceTreeErrorText,
} from "../createFlowValidation";
import {
  card,
  createFlowCard,
  createInput,
  createLabel,
  createRequired,
  createRow,
  createSectionTitle,
  createTooltip,
} from "../Styles";

const JustificationStep = (props) => {
  const { serviceName, setServiceName, serviceTreeId, setServiceTreeId } =
    props;

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>
            Service Name
            <TooltipHost content="Enter the name of your Service" id="service">
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="service"
            onGetErrorMessage={(value) => getServiceNameErrorText(value)}
            onChange={(event, value) => setServiceName(value)}
            value={serviceName}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Service Name"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Service Tree ID
            <TooltipHost
              content="Enter the Service Tree ID of your service"
              id="serviceTree"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="serviceTree"
            onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
            onChange={(event, value) => setServiceTreeId(value.trim())}
            value={serviceTreeId}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Service Tree ID"
          />
        </div>
      </div>
    </div>
  );
};

export default JustificationStep;
