import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const dashboardDiv = mergeStyles({
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
});

export const flexRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

export const halfWidth = mergeStyles({
  width: "50%",
  paddingRight: "20px",
});

export const comingSoon = mergeStyles({
  position: "relative",
  top: "45%",
  textAlign: "center",
  fontWeight: "700",
  fontSize: "xx-large",
});

export const dashboardCard = mergeStyles({
  width: "50%",
  margin: "16px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflowX: "hidden",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  height: "max-content",
});

export const cardPadding = mergeStyles({
  padding: "16px 20px 16px 20px",
});

export const cardTitle = mergeStyles({
  flexGrow: "1",
  fontSize: "1.0625rem",
  display: "flex",
  flexDirection: "row",
  fontWeight: "bold",
});

export const baseDiv = classNames(greyPage, base);
