import { useAccount, useMsal } from "@azure/msal-react";
import { Pivot, PivotItem } from "@fluentui/react";
import { protectedResources } from "authConfig";
import LoadingView from "connections/LoadingView";
import {
  baseDiv,
  card,
  errorTextStyle,
  essentialsColumn,
  essentialsGrid,
  essentialsTitle,
  headerDiv,
  overviewCard,
  pivotPadding,
  titleFont,
} from "connections/Styles";
import { createGridElements, showLocaleString } from "pipelines/admin/Pipeline";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminConnectionAPI } from "utils/endpoints";
import FlowList from "./FlowList";
import RawView from "pipelines/admin/RawView";

const Connection = () => {
  const { subscriptionId, resourceGroup, connectionName } = useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flows, setFlows] = useState([]);

  const cachedInfo = useSelector(
    (state) => state.resourceProvider.admin_connections_list
  ).find(
    (connection) =>
      connection.id.toLowerCase() ===
      `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.AzureDataTransfer/connections/${connectionName}`.toLowerCase()
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getConnection = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(
      `${adminConnectionAPI}/${subscriptionId}/${resourceGroup}/${connectionName}`,
      token
    )
      .then((response) => response.json())
      .then((connection) => {
        setConnection(connection);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [
    account,
    instance,
    connectionName,
    resourceGroup,
    selectedCloud,
    subscriptionId,
  ]);

  useEffect(() => {
    if (cachedInfo) {
      setConnection(cachedInfo);
      setIsLoading(false);
    } else {
      getConnection();
    }
  }, [cachedInfo, getConnection]);

  const content = connection ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{`[Connection] ${connectionName}`}</span>
      </div>
      <Pivot className={pivotPadding}>
        <PivotItem headerText="Overview">
          <div className={overviewCard}>
            <div className={card}>
              <div className={essentialsTitle}>Connection Details</div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements("Name", connectionName)}
                  {createGridElements("Resource Group", resourceGroup)}
                  {createGridElements("Subscription ID", subscriptionId)}
                  {createGridElements(
                    "Created",
                    showLocaleString(new Date(connection.systemData.createdAt))
                  )}
                </div>
              </div>
              <div className={essentialsColumn}>
                <div className={essentialsGrid}>
                  {createGridElements(
                    "Direction",
                    connection.properties.direction
                  )}
                  {createGridElements(
                    "Pipeline",
                    connection.properties.pipeline
                  )}
                  {createGridElements("Status", connection.properties.status)}
                  {createGridElements(
                    "Flow Types",
                    connection.properties.flowTypes?.join(";")
                  )}
                </div>
              </div>
            </div>
          </div>
        </PivotItem>
        <PivotItem headerText="Flows">
          <FlowList connection={connection} flows={flows} setFlows={setFlows} />
        </PivotItem>
        <PivotItem headerText="JSON View">
          <RawView resource={connection} />
        </PivotItem>
      </Pivot>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Connection info" /> : content}
    </div>
  );
};

export default Connection;
