import { useAccount, useMsal } from "@azure/msal-react";
import { Icon, Persona, Shimmer, ShimmerElementType } from "@fluentui/react";
import { graphApiScopes } from "authConfig";
import { getUserAccountInitials } from "employeePicker/EmployeePicker";
import { useEffect } from "react";
import { useState } from "react";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import {
  batchEditIcons,
  sharedUserBox,
  sharedUserSecondaryText,
  sharedUserTextBoxDiv,
} from "./Styles";

/**
 * Helper function to craft the API for retrieving information regarding a specific user within the "signed-in" user's organization
 * @param {string} alias alias of the user to look up
 * @returns URI to send the HTTP Get Request to
 */
const getGraphUserAPI = (alias) =>
  `https://graph.microsoft.com/v1.0/users?$filter=mailNickname eq '${alias}'`;

/**
 * Helper function to generate a random integer which will represent one of the Persona (coin) Color
 * @returns Int to represent the enum value of PersonaInitialsColor
 */
const getRandomCoinColor = () => {
  const NUM_COLORS = 10;
  return Math.floor(Math.random() * NUM_COLORS);
};

const SharedUser = (props) => {
  const { alias, removeSharedUser, setBatch } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState("");
  const [coinText, setCoinText] = useState("");

  useEffect(() => {
    const getUserInfo = async (alias) => {
      const token = await getAuthorizationToken(
        instance,
        graphApiScopes,
        account
      );
      callAuthorizedEndpoint(getGraphUserAPI(alias), token)
        .then((response) => response.json())
        .then((response) => {
          const userObj = response.value[0];
          setDisplayName(userObj.displayName);
          setCoinText(getUserAccountInitials(userObj.displayName));
          setLoading(false);
        })
        .catch(() => {
          setDisplayName("User not found");
          setLoading(false);
        });
    };

    getUserInfo(alias);
  }, [account, alias, instance]);

  const sharedUserLoadingElements = [
    { type: ShimmerElementType.circle },
    { type: ShimmerElementType.gap, width: "2%" },
    { type: ShimmerElementType.line, width: "250px" },
  ];

  const loadingElement = (
    <Shimmer shimmerElements={sharedUserLoadingElements} />
  );

  const sharedUserCard = (
    <>
      <Persona
        initialsColor={getRandomCoinColor()}
        imageInitials={coinText}
        coinSize={24}
      />
      <div className={sharedUserTextBoxDiv}>
        <div>{displayName}</div>
        <div className={sharedUserSecondaryText}>{alias}</div>
      </div>
      {setBatch && (
        <button
          onClick={() => removeSharedUser(alias)}
          className={batchEditIcons}
          data-testid={`remove-${alias}`}
        >
          <Icon iconName="Cancel" />
        </button>
      )}
    </>
  );

  return (
    <div className={sharedUserBox}>
      {loading ? loadingElement : sharedUserCard}
    </div>
  );
};

export default SharedUser;
