import { Icon } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { batchLinks } from "sidenav/customer/BatchNavGroup";
import { egressLinks } from "sidenav/customer/EgressNavGroup";
import { ingressLinks } from "sidenav/customer/IngressNavGroup";
import { pasteLinks } from "sidenav/customer/PasteNavGroup";
import {
  baseDiv,
  bodyText,
  card,
  cardDescription,
  cardFooter,
  cardIcon,
  headerTitle,
  navRow,
  titleFont,
} from "./Styles";

const CustomerHome = () => {
  const navigate = useNavigate();

  return (
    <div className={baseDiv}>
      <div className={headerTitle}>
        <h2 className={titleFont}>Cloud Transfer Service Portal</h2>
      </div>
      <div className={bodyText}>
        Welcome to CTS Portal! This is the place to transfer your files from one
        cloud domain to another.
      </div>
      <div className={navRow}>
        <button
          onClick={() => navigate(batchLinks[0].path)}
          className={card}
          tabIndex={0}
        >
          <div>
            <Icon iconName="PublicFolder" className={cardIcon} />
            <p className={cardDescription}>
              Move your files to a destination cloud as a one-time use case.
            </p>
          </div>
          <span className={cardFooter}>Batches</span>
        </button>
        <button
          onClick={() => navigate(pasteLinks[0].path)}
          className={card}
          tabIndex={0}
        >
          <div>
            <Icon iconName="PasteAsCode" className={cardIcon} />
            <p className={cardDescription}>
              Send snippets of code within seconds to a destination cloud.
            </p>
          </div>
          <span className={cardFooter}>Pastes</span>
        </button>
        <button
          onClick={() => navigate(ingressLinks[0].path)}
          className={card}
          tabIndex={0}
        >
          <div>
            <Icon iconName="CloudUpload" className={cardIcon} />
            <p className={cardDescription}>
              Replicate your data over to another cloud through a continous
              stream.
            </p>
          </div>
          <span className={cardFooter}>Ingress Flows</span>
        </button>
        <button
          onClick={() => navigate(egressLinks[0].path)}
          className={card}
          tabIndex={0}
        >
          <div>
            <Icon iconName="CloudDownload" className={cardIcon} />
            <p className={cardDescription}>
              Sync your data over from another cloud to your Storage Account.
            </p>
          </div>
          <span className={cardFooter}>Egress Flows</span>
        </button>
      </div>
    </div>
  );
};

export default CustomerHome;
