import { MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import {
  card,
  convertFlowCard,
  essentialsColumn,
  essentialsGrid,
  essentialsTitle,
} from "ingressFlows/Styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import { createGridElements } from "./AdminIngressFlowDetails";

const ConvertFromOAuth = (props) => {
  const { flow, verifyFunc, convertFunc } = props;
  const dispatch = useDispatch();

  const [secretUri, setSecretUri] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [valid, setValid] = useState(false);

  const verifyClick = () => {
    setFetching(true);
    setValid(false);

    const requestBody = {
      type: "sas",
      flowId: flow.dataflowGuid,
      cloud: flow.cloud,
      secretUri: secretUri,
    };

    verifyFunc(requestBody)
      .then((response) => response.json())
      .then((response) => {
        if (response.valid) {
          setValid(true);
          dispatch(
            setMessage({
              type: MessageBarType.success,
              message: `Successfully validated new access type, you can convert the Flow Access now.`,
            })
          );
          dispatch(setShow(true));
        } else {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Failed to validate new access - ${response.errorInfo}`,
            })
          );
          dispatch(setShow(true));
        }
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setFetching(false);
      });
  };

  const convertClick = () => {
    setFetching(true);

    const requestBody = {
      type: "sas",
      flowId: flow.dataflowGuid,
      cloud: flow.cloud,
      secretUri: secretUri,
    };

    convertFunc(requestBody).catch((response) =>
      response.text().then((text) => {
        setFetching(false);
        dispatch(setMessage({ type: MessageBarType.error, message: text }));
        dispatch(setShow(true));
      })
    );
  };

  return (
    <>
      <div className={convertFlowCard}>
        <div className={card}>
          <div className={essentialsTitle}>Convert From</div>
          <div className={essentialsColumn}>
            <div className={essentialsGrid}>
              {createGridElements("Flow Identifier", flow.dataflowGuid)}
              {createGridElements("Access Type", "OAuth")}
            </div>
          </div>
          <div className={essentialsColumn}>
            <div className={essentialsGrid}>
              {createGridElements("Cluster", flow.cluster)}
              {createGridElements("Storage Tenant ID", flow.storageTenantId)}
            </div>
          </div>
        </div>
      </div>
      <div className={convertFlowCard}>
        <div className={card}>
          <div className={essentialsTitle}>Convert To</div>
          <div className={essentialsColumn}>
            <div className={essentialsGrid}>
              {createGridElements("Flow Identifier", flow.dataflowGuid)}
              {createGridElements("Access Type", "SAS")}
            </div>
          </div>
          <div className={essentialsColumn}>
            <div className={essentialsGrid}>
              {createGridElements("Cluster", flow.cluster)}
              {createGridElements(
                "Secret URI",
                <TextField
                  disabled={fetching}
                  onChange={(event, newValue) => {
                    setSecretUri(newValue.trim());
                    setValid(false);
                  }}
                  data-testid="secretUri"
                />
              )}
            </div>
          </div>
          {!valid && (
            <PrimaryButton
              text="Verify"
              data-testid="verify-button"
              disabled={!secretUri || fetching}
              onClick={() => verifyClick()}
            />
          )}
          {valid && (
            <PrimaryButton
              text="Convert"
              data-testid="convert-button"
              disabled={!secretUri || fetching}
              onClick={() => convertClick()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ConvertFromOAuth;
