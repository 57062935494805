import { useAccount, useMsal } from "@azure/msal-react";
import {
  ComboBox,
  Dropdown,
  Icon,
  Label,
  MessageBarType,
  SpinButton,
  TextField,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useState } from "react";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI } from "utils/endpoints";
import {
  getDataDestErrorText,
  getDataOriginErrorText,
  getDataUsageErrorText,
  getEmailErrorText,
  getResourceGroupErrorText,
  getServiceNameErrorText,
  getServiceTreeErrorText,
  getSubscriptionIdErrorText,
  getTitleErrorText,
  getUseCaseErrorText,
} from "../createFlowValidation";
import {
  cloudVaultOptions,
  dataTypeOptions,
  updateDataTypesList,
} from "ingressFlows/customer/JustificationStep";
import {
  card,
  createInput,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  editIconsPadding,
  flowEditDisabledIcons,
  flowEditIcon,
  flowSectionTitleDiv,
  validationLabel,
} from "ingressFlows/Styles";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";
import EmployeePicker from "employeePicker/EmployeePicker";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";

export const aliasesToPeoplePickerList = (aliases) => {
  if (!aliases) return [];

  return aliases.split(";").map((alias) => ({
    key: alias,
    text: alias,
  }));
};

const isEqualsJson = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  return (
    keys1.length === keys2.length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
};

const AdminEditFlow = (props) => {
  const { flow, setFlow, clusterList } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [editMode, setEditMode] = useState(false);
  const [modifiedFlow, setModifiedFlow] = useState(flow);
  const [sending, setSending] = useState(false);

  const onPrimaryUserSearchChange = (items) => {
    setModifiedFlow({
      ...modifiedFlow,
      primaryContact:
        items.length > 0 ? getAliasFromUserPrincipalName(items[0].upn) : "",
    });
  };

  const onSecondaryUserSearchChange = (items) => {
    const itemArr = items.map((item) =>
      item.key.includes("@")
        ? getAliasFromUserPrincipalName(item.key)
        : item.key
    );

    const uniqueArr = [...new Set(itemArr)];

    setModifiedFlow({
      ...modifiedFlow,
      secondaryContact: uniqueArr.join(";"),
    });
  };

  const cancelUpdate = () => {
    setEditMode(false);
    setModifiedFlow(flow);
  };

  const isUpdateAllowed = () => {
    return !sending && !isEqualsJson(flow, modifiedFlow);
  };

  const getDiff = (field) =>
    modifiedFlow[field] === flow[field] ? null : modifiedFlow[field];

  const getDiffJson = () => {
    const diffs = {
      AvgDailyData: getDiff("avgDailyData"),
      CloudVault: getDiff("cloudVault"),
      Cluster: getDiff("cluster"),
      DataTypes: getDiff("dataTypes"),
      DataUse: getDiff("dataUse"),
      Destination: getDiff("destination"),
      Email: getDiff("email"),
      MaxData: getDiff("maxData"),
      Notes: getDiff("notes"),
      Origin: getDiff("origin"),
      PrimaryContact: getDiff("primaryContact"),
      ResourceGroup: getDiff("resourceGroup"),
      SecondaryContact: getDiff("secondaryContact"),
      ServiceName: getDiff("serviceName"),
      ServiceTreeId: getDiff("serviceTreeId"),
      SubscriptionId: getDiff("subscriptionId"),
      SyncPeriod: getDiff("syncPeriod"),
      Title: getDiff("title"),
      UseCase: getDiff("useCase"),
    };

    Object.keys(diffs).forEach((key) => {
      if (diffs[key] === null) {
        delete diffs[key];
      }
    });

    return diffs;
  };

  const updateFlow = async () => {
    const body = getDiffJson();

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setSending(true);

    callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/${flow.dataflowGuid}?cloud=${flow.cloud}`,
      token,
      "PATCH",
      body
    )
      .then(() => {
        setFlow(modifiedFlow);
        setEditMode(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setSending(false);
      });
  };

  const editButton = editMode ? (
    <>
      <button
        onClick={() => {
          if (isUpdateAllowed()) updateFlow();
        }}
        className={isUpdateAllowed() ? flowEditIcon : flowEditDisabledIcons}
        data-testid="update-button"
      >
        <Icon iconName="Accept" />
      </button>
      <button
        onClick={() => {
          if (!sending) cancelUpdate();
        }}
        className={`${editIconsPadding} ${flowEditIcon} ${
          sending ? flowEditDisabledIcons : ""
        }`}
        data-testid="cancel-button"
      >
        <Icon iconName="Cancel" />
      </button>
    </>
  ) : (
    <button
      className={flowEditIcon}
      onClick={() => setEditMode(true)}
      data-testid="edit-button"
    >
      <Icon iconName="Edit" />
    </button>
  );

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>DRI Details</div>
          {editButton}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Teleport Onboarding Status</div>
          <Label className={validationLabel}>
            {flow.teleportOnboardingStatus}
          </Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Notes</div>
          {editMode ? (
            <TextField
              data-testid="Notes"
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, notes: value })
              }
              value={modifiedFlow.notes ?? ""}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.notes}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cluster</div>
          {editMode ? (
            <Dropdown
              selectedKey={modifiedFlow.cluster}
              onChange={(event, option, index) =>
                setModifiedFlow({ ...modifiedFlow, cluster: option.key })
              }
              options={clusterList}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.cluster}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Sync Period in Minutes</div>
          {editMode && flow.onboarded ? (
            <SpinButton
              value={modifiedFlow.syncPeriod}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  syncPeriod: Number(value),
                })
              }
              min={1}
              step={1}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.syncPeriod}</Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          {editMode ? (
            <TextField
              data-testid="Title"
              onGetErrorMessage={(value) => getTitleErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, title: value })
              }
              value={modifiedFlow.title}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.title}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          {editMode ? (
            <TextField
              data-testid="Email"
              onGetErrorMessage={(value) => getEmailErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, email: value.trim() })
              }
              value={modifiedFlow.email}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.email}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          {editMode ? (
            <EmployeePicker
              onChange={(items) => onPrimaryUserSearchChange(items)}
              itemLimit={1}
              defaultSelectedItems={[
                { key: flow.primaryContact, text: flow.primaryContact },
              ]}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.primaryContact}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          {editMode ? (
            <EmployeePicker
              onChange={(items) => onSecondaryUserSearchChange(items)}
              defaultSelectedItems={aliasesToPeoplePickerList(
                modifiedFlow.secondaryContact
              )}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.secondaryContact?.split(";").join(", ")}
            </Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>Access Type</div>
          <Label className={validationLabel}>
            {flow.useOAuth ? "OAuth" : "SAS"}
          </Label>
        </div>
        {flow.useOAuth && (
          <div className={createRow}>
            <div className={createLabel}>Tenant ID</div>
            <Label className={validationLabel}>{flow.storageTenantId}</Label>
          </div>
        )}
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          {editMode ? (
            <TextField
              data-testid="subscriptionID"
              onGetErrorMessage={(value) => getSubscriptionIdErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  subscriptionId: value.trim().toLowerCase(),
                })
              }
              value={modifiedFlow.subscriptionId}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.subscriptionId}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          {editMode ? (
            <TextField
              data-testid="resourceGroup"
              onGetErrorMessage={(value) => getResourceGroupErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  resourceGroup: value.trim(),
                })
              }
              value={modifiedFlow.resourceGroup}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.resourceGroup}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        {!flow.useOAuth && (
          <div className={createRow}>
            <div className={createLabel}>Key Vault URI</div>
            <Label className={validationLabel}>{flow.keyVaultUri}</Label>
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Use Case</div>
          {editMode ? (
            <TextField
              data-testid="useCase"
              onGetErrorMessage={(value) => getUseCaseErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  useCase: value,
                })
              }
              value={modifiedFlow.useCase}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.useCase}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Data Types</div>
          {editMode ? (
            <ComboBox
              multiSelect={true}
              selectedKey={modifiedFlow.dataTypes?.split(";")}
              onChange={(event, option, index, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  dataTypes: updateDataTypesList(
                    modifiedFlow.dataTypes.length > 0
                      ? modifiedFlow.dataTypes.split(";")
                      : [],
                    option.key
                  ).join(";"),
                })
              }
              options={dataTypeOptions}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.dataTypes
                ?.split(";")
                .map(
                  (item) =>
                    dataTypeOptions.find((option) => option.key === item)?.text
                )
                ?.join("; ")}
            </Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Content from CloudVault</div>
          {editMode ? (
            <Dropdown
              selectedKey={modifiedFlow.cloudVault}
              onChange={(event, option, index) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  cloudVault: option.key,
                })
              }
              options={cloudVaultOptions}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.cloudVault}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Origin of Data</div>
          {editMode ? (
            <TextField
              data-testid="dataOrigin"
              onGetErrorMessage={(value) => getDataOriginErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  origin: value,
                })
              }
              value={modifiedFlow.origin}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.origin}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination of Data</div>
          {editMode ? (
            <TextField
              data-testid="dataDest"
              onGetErrorMessage={(value) => getDataDestErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  destination: value,
                })
              }
              value={modifiedFlow.destination}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.destination}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>How Will Data be Used</div>
          {editMode ? (
            <TextField
              data-testid="dataUsage"
              onGetErrorMessage={(value) => getDataUsageErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  dataUse: value,
                })
              }
              value={modifiedFlow.dataUse}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.dataUse}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          {editMode ? (
            <TextField
              data-testid="serviceName"
              onGetErrorMessage={(value) => getServiceNameErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceName: value,
                })
              }
              value={modifiedFlow.serviceName}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceName}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          {editMode ? (
            <TextField
              data-testid="serviceTreeId"
              onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceTreeId: value.trim(),
                })
              }
              value={modifiedFlow.serviceTreeId}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceTreeId}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Average Data in a Day (in GB)</div>
          {editMode ? (
            <SpinButton
              value={modifiedFlow.avgDailyData}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  avgDailyData: parseInt(value),
                })
              }
              min={1}
              step={1}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.avgDailyData}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Max Data in a Day (in GB)</div>
          {editMode ? (
            <SpinButton
              value={modifiedFlow.maxData}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  maxData: parseInt(value),
                })
              }
              min={1}
              step={1}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.maxData}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Manual Usage of Teleport</div>
          <Label className={validationLabel}>{flow.manualUsage}</Label>
        </div>
      </div>
    </div>
  );
};

export default AdminEditFlow;
