import * as React from "react";
import {
  baseDiv,
  headerDiv,
  batchTitleFont,
  errorTextStyle,
  headerButtonPadding,
  batchDetailsPivot,
  batchPropertiesDiv,
} from "../Styles";
import { useEffect, useState } from "react";
import { protectedResources } from "../../authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminBatchesAPI, fileReviewBatchesAPI } from "utils/endpoints";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { MessageBarType, Pivot, PivotItem } from "@fluentui/react";
import BatchOverviewPivot from "./BatchOverviewPivot";
import { useCallback } from "react";
import SharedUserList from "../SharedUserList";
import LoadingView from "../LoadingView";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import { downloadToFiles, downloadToZip } from "batches/batchDownloader";
import FileReviewPivot from "./FileReviewPivot";
import FileApproveModal from "./FileApproveModal";
import FileRejectModal from "./FileRejectModal";

export const BatchFileReview = () => {
  const { batchName } = useParams();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [requestSending, setRequestSending] = useState(false);
  const [fileName, setFileName] = useState("");
  const [
    isApproveModalOpen,
    { setTrue: showApproveModal, setFalse: hideApproveModal },
  ] = useBoolean(false);
  const [
    isRejectModalOpen,
    { setTrue: showRejectModal, setFalse: hideRejectModal },
  ] = useBoolean(false);

  const dispatchFunc = (type, text) => {
    dispatch(
      setMessage({
        type: type,
        message: text,
      })
    );
    dispatch(setShow(true));
  };

  const downloadFile = async (blobName) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const fileName = encodeURIComponent(blobName);
    return callAuthorizedEndpoint(
      `${adminBatchesAPI}/${batchName.trim()}/${fileName}`,
      token
    )
      .then((resp) => resp.json())
      .then((downloadInfo) => {
        downloadToFiles([downloadInfo]);
      });
  };

  const getFilesSAS = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const sasPromises = batch.blobs.map((blob) => {
      const fileName = encodeURIComponent(blob.name);
      return callAuthorizedEndpoint(
        `${adminBatchesAPI}/${batchName.trim()}/${fileName}`,
        token
      );
    });

    return Promise.all(sasPromises).then((responses) =>
      Promise.all(responses.map((r) => r.json()))
    );
  };

  const downloadFiles = async () => {
    const filesArr = await getFilesSAS();
    downloadToFiles(filesArr);
  };

  const downloadZip = async () => {
    const filesArr = await getFilesSAS();
    downloadToZip(filesArr, batchName, dispatchFunc);
  };

  const downloadMenuProps = {
    items: [
      {
        key: "downloadFiles",
        text: "Download Files",
        onClick: (ev, props) => downloadFiles(),
      },
      {
        key: "downloadZip",
        text: "Download as Zip",
        onClick: (ev, props) => downloadZip(),
      },
    ],
    directionalHintFixed: true,
  };

  const approveFile = async (fileName) => {
    setRequestSending(true);
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${adminBatchesAPI}/${batchName.trim()}/approvefile`,
      token,
      "POST",
      { FileName: fileName }
    )
      .then(() => {
        const index = batch.blobs.findIndex(
          (element) => element.name === fileName
        );
        batch.blobs[index].internalStatus = "Approved";
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestSending(false);
        hideApproveModal();
      });
  };

  const openApproveModal = async (index) => {
    const fileName = batch.blobs[index].name;
    setFileName(fileName);
    showApproveModal();
  };

  const rejectFile = async (fileName, rejectReason) => {
    setRequestSending(true);
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${adminBatchesAPI}/${batchName.trim()}/rejectfile`,
      token,
      "POST",
      { FileName: fileName, RejectionReason: rejectReason }
    )
      .then(() => {
        const index = batch.blobs.findIndex(
          (element) => element.name === fileName
        );
        batch.blobs[index].internalStatus = "Rejected";
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestSending(false);
        hideRejectModal();
      });
  };

  const openRejectModal = async (index) => {
    const fileName = batch.blobs[index].name;
    setFileName(fileName);
    showRejectModal();
  };

  const fetchBatch = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${fileReviewBatchesAPI}/${batchName.trim()}`, token)
      .then((response) => response.json())
      .then((batchObj) => {
        batchObj.blobs = batchObj.blobs
          ? batchObj.blobs
              .filter((blob) => !blob.name.endsWith(".teleportal.sha512"))
              .map((blob) => ({
                ...blob,
                internalStatus: "",
              }))
          : [];
        setBatch(batchObj);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        })
      );
  }, [account, batchName, instance]);

  useEffect(() => {
    fetchBatch();
  }, [fetchBatch]);

  const batchView = batch ? (
    <>
      <div className={headerDiv}>
        <span className={batchTitleFont}>{batch.name}</span>
        <DefaultButton
          text="Download Batch"
          menuProps={downloadMenuProps}
          className={headerButtonPadding}
        />
      </div>
      <div className={batchDetailsPivot}>
        <Pivot>
          <PivotItem headerText="Overview">
            <div className={batchPropertiesDiv}>
              <BatchOverviewPivot batch={batch} batchName={batchName} />
              <SharedUserList batchName={batchName} batchObj={batch} />
            </div>
            <FileReviewPivot
              batch={batch}
              downloadFile={downloadFile}
              openApproveModal={openApproveModal}
              openRejectModal={openRejectModal}
              requestSending={requestSending}
            />
          </PivotItem>
        </Pivot>
      </div>
      <FileApproveModal
        isModalOpen={isApproveModalOpen}
        hideModal={hideApproveModal}
        approveFile={approveFile}
        fileName={fileName}
        requestSending={requestSending}
      />
      {isRejectModalOpen && (
        <FileRejectModal
          isModalOpen={isRejectModalOpen}
          hideModal={hideRejectModal}
          rejectFile={rejectFile}
          fileName={fileName}
          requestSending={requestSending}
        />
      )}
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : batchView}</div>;
};

export default BatchFileReview;
