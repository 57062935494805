import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import {
  Dropdown,
  TextField,
  Stack,
  DefaultButton,
  PrimaryButton,
  Label,
  Icon,
} from "@fluentui/react";
import { useState } from "react";
import Editor from "@monaco-editor/react";
import {
  baseDiv,
  body,
  footer,
  headerDiv,
  primaryActionButton,
  titleFont,
  whitebg,
} from "./Styles";
import { textEditor } from "pastebin/Styles";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminOpsAPI } from "utils/endpoints";
import { guidErrorText, validFlowGuid } from "./utils";

const AdminRp = () => {
  const [requestHeaders, setRequestHeaders] = useState([]);
  const [responseHeaders, setResponseHeaders] = useState([]);
  const [requestBody, setRequestBody] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [statusCode, setStatusCode] = useState("");
  const [httpMethod, setHttpMethod] = useState("GET");
  const [requestType, setRequestType] = useState("Custom");
  const [tenantId, setTenantId] = useState("");
  const [path, setPath] = useState("");
  const [isBlocking, setIsBlocking] = useState(false);
  const [responseHeadersShown, setResponseHeadersShown] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const changeRequestType = (requestType) => {
    // clear the values so they aren't saved during the request api call
    if (requestType === "GenerateAccessToken") {
      setRequestHeaders([]);
      setRequestBody("");
      setHttpMethod("GET");
      setPath("");
    } else {
      setTenantId("");
    }

    setRequestType(requestType);
  };

  const allowCall = () => {
    if (requestType === "GenerateAccessToken") {
      return validFlowGuid(tenantId);
    } else {
      return path.trim().length > 0;
    }
  };

  const addHeader = () => {
    setRequestHeaders([...requestHeaders, { key: "", value: "" }]);
  };

  const removeHeader = (index) => {
    setRequestHeaders(requestHeaders.filter((_, i) => i !== index));
  };

  const updateHeader = (index, key, value) => {
    const newHeaders = [...requestHeaders];
    newHeaders[index] = { key, value };
    setRequestHeaders(newHeaders);
  };

  const sendRequest = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    setStatusCode("Running");
    setResponseBody("");
    setIsBlocking(true);
    callAuthorizedEndpointWithBody(
      `${adminOpsAPI}/makeRequest`,
      token,
      "POST",
      {
        requestType: requestType,
        method: httpMethod,
        tenantId: tenantId,
        path: path,
        headers: requestHeaders.reduce((acc, header) => {
          acc[header.key] = header.value;
          return acc;
        }, {}),
        body: requestBody,
      }
    )
      .catch((response) => {
        return response;
      })
      .then((response) => {
        setStatusCode(response.status);
        response.text().then((data) => {
          try {
            // Try to parse the data as JSON
            const json = JSON.parse(data);
            const content = JSON.parse(json.Content);
            // If the parsing succeeds, stringify the JSON with indentation
            setResponseBody(JSON.stringify(content, null, 2));
            const responseHeadersArray = json.Headers
              ? Object.entries(json.Headers).map(([key, value]) => ({
                  key,
                  value,
                }))
              : [];
            setResponseHeaders(responseHeadersArray);
          } catch (error) {
            // If the parsing fails, assume that the data is a normal string and display it as is
            setResponseBody(data);
          }
        });
      })
      .finally(() => setIsBlocking(false));
  };

  const renderCustomRequestFields = () => {
    return (
      <>
        <Dropdown
          label="HTTP Method"
          options={httpMethodDropdownList}
          data-testid="http-method-dropdown"
          styles={{ dropdown: { maxWidth: 100 } }}
          selectedKey={httpMethod}
          onChange={(_, item) => {
            setHttpMethod(item.key);
          }}
        />
        <TextField
          label="Path"
          value={path}
          styles={{ root: { width: "100% !important" } }}
          onChange={(e, newValue) => {
            setPath(newValue);
          }}
        />
        <div>
          <Label>Headers</Label>
          <Stack tokens={{ childrenGap: 10 }}>
            {requestHeaders.map((header, index) => (
              <Stack horizontal tokens={{ childrenGap: 10 }} key={index}>
                <TextField
                  label="Key"
                  data-testid={`request-header-key-${index}`}
                  value={header.key}
                  onChange={(e, newValue) => {
                    updateHeader(index, newValue, header.value);
                  }}
                />
                <TextField
                  label="Value"
                  data-testid={`request-header-value-${index}`}
                  value={header.value}
                  onChange={(e, newValue) => {
                    updateHeader(index, header.key, newValue);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "31px",
                  }}
                >
                  <Icon
                    iconName={"Delete"}
                    onClick={() => removeHeader(index)}
                    styles={{ root: { cursor: "pointer" } }}
                  />
                </div>
              </Stack>
            ))}
          </Stack>
        </div>

        <div style={{ padding: "5px 0px" }}>
          <DefaultButton onClick={addHeader}>Add Header</DefaultButton>
        </div>
        <div>
          <Label>Request Body</Label>
          <div className={textEditor} style={{ height: "200px" }}>
            <Editor
              language={"json"}
              defaultValue={requestBody}
              onChange={(value, event) => setRequestBody(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const renderGenerateAccessTokenFields = () => {
    return (
      <>
        <TextField
          label="Tenant ID"
          value={tenantId}
          onGetErrorMessage={(value) => guidErrorText(value)}
          onChange={(e, newValue) => {
            setTenantId(newValue);
          }}
          validateOnLoad={false}
        />
      </>
    );
  };

  const adminRpRequestType = [
    { key: "GenerateAccessToken", text: "Generate Access Token" },
    { key: "Custom", text: "Custom" },
  ];

  const httpMethodDropdownList = [
    { key: "GET", text: "GET" },
    { key: "POST", text: "POST" },
    { key: "PUT", text: "PUT" },
    { key: "PATCH", text: "PATCH" },
    { key: "DELETE", text: "DELETE" },
  ];

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Admin RP</span>
      </div>
      <div className={whitebg}>
        <div className={body} style={{ width: "100%" }}>
          <Label styles={{ root: { fontSize: "20px" } }}>Request</Label>
          <Dropdown
            label="Preset"
            options={adminRpRequestType}
            data-testid="admin-rp-request-type-dropdown"
            selectedKey={requestType}
            onChange={(_, item) => changeRequestType(item.key)}
          />
          {requestType === "Custom" && renderCustomRequestFields()}
          {requestType === "GenerateAccessToken" &&
            renderGenerateAccessTokenFields()}
          <div className={footer}>
            <PrimaryButton
              className={primaryActionButton}
              data-testid="sendRequest-button"
              onClick={sendRequest}
              disabled={isBlocking || !allowCall()}
            >
              Send
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={whitebg}>
        <div className={body} style={{ width: "100%" }}>
          <Label styles={{ root: { fontSize: "20px" } }}>Response</Label>
          <TextField
            styles={{ fieldGroup: { maxWidth: 100 } }}
            label="Status"
            value={statusCode}
            readOnly={true}
          />
          <div>
            <Label>Response Body</Label>
            <div className={textEditor} style={{ height: "200px" }}>
              <Editor
                language={"json"}
                options={{ readOnly: true }}
                value={responseBody}
              />
            </div>
          </div>
          <div style={{ padding: "5px 0px" }}>
            <DefaultButton
              onClick={() => setResponseHeadersShown(!responseHeadersShown)}
            >
              {responseHeadersShown
                ? "Hide Response Headers"
                : "Show Response Headers"}
            </DefaultButton>
          </div>
          {responseHeadersShown && (
            <Stack tokens={{ childrenGap: 10 }}>
              {responseHeaders.map((header, index) => (
                <Stack horizontal tokens={{ childrenGap: 10 }} key={index}>
                  <TextField label="Name" value={header.key} readonly={true} />
                  <TextField
                    label="Value"
                    value={header.value}
                    readonly={true}
                  />
                </Stack>
              ))}
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminRp;
