import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";
import { useSelector } from "react-redux";

const OverviewNavGroup = () => {
  const roles = useSelector((state) => state.mode.roles);

  const links = [{ title: "Home", icon: "Home", path: "/" }];

  if (roles.dri) {
    links.push({
      title: "Dashboard",
      icon: "ViewDashboard",
      path: "/dashboard",
    });
    links.push({
      title: "Notifications",
      icon: "Ringer",
      path: "/notifications",
    });
  }

  const rootProps = {
    title: "Overview",
    icon: "TVMonitor",
    path: links[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={links} />;
};

export default OverviewNavGroup;
