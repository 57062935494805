import { useAccount, useMsal } from "@azure/msal-react";
import {
  Dropdown,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useState } from "react";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { validateFlowAPI } from "utils/endpoints";
import {
  getResourceGroupErrorText,
  getStorageAccountErrorText,
  getStorageContainerErrorText,
  getSubscriptionIdErrorText,
  getTenantIdErrorText,
  validResourceGroup,
  validStorageAccountName,
  validStorageContainerName,
  validSubscriptionId,
  validTenantId,
} from "../createFlowValidation";
import {
  card,
  createFlowCard,
  createInput,
  createLabel,
  createRequired,
  createRow,
  createSectionTitle,
  createTooltip,
  validationError,
  validationLabel,
  validationSuccess,
} from "../Styles";

export const containerTypeOptions = [
  { key: "Blob", text: "Blob" },
  { key: "Table", text: "Table" },
];

export const regionTypeOptions = [
  { key: "East", text: "US East" },
  { key: "West", text: "US West" },
];

const craftValidateUrl = (tenantId, accName, containerName, containerType) =>
  `${validateFlowAPI}?tenantId=${tenantId}&&acc=${accName}&&container=${containerName}&&type=${containerType}&&mode=receive`;

const ConnectionStep = (props) => {
  const {
    setLocked,
    tenantId,
    setTenantId,
    subscriptionId,
    setSubscriptionId,
    resourceGroup,
    setResourceGroup,
    storageAccName,
    setStorageAccName,
    storageContainerType,
    setStorageContainerType,
    storageContainerName,
    setStorageContainerName,
    containerRegion,
    setContainerRegion,
    sasTest,
    setSasTest,
  } = props;
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const configurationCheckDiv = () => {
    if (sasTest) {
      return (
        <Label className={validationSuccess}>
          Configuration looks all good!
        </Label>
      );
    }

    if (isCheckingAccess) {
      return (
        <Spinner
          size={SpinnerSize.large}
          label="Validating Configuration..."
          labelPosition="right"
        />
      );
    }

    if (
      validSubscriptionId(subscriptionId) &&
      validResourceGroup(resourceGroup) &&
      validStorageAccountName(storageAccName) &&
      validStorageContainerName(storageContainerName) &&
      validTenantId(tenantId)
    ) {
      return <PrimaryButton text="Test Configuration" onClick={checkAccess} />;
    } else {
      return (
        <Label className={validationLabel}>
          Need to fill in the required fields first
        </Label>
      );
    }
  };

  const checkAccess = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setLocked(true);
    setIsCheckingAccess(true);
    setErrorMsg("");

    callAuthorizedEndpoint(
      craftValidateUrl(
        tenantId,
        storageAccName,
        storageContainerName,
        storageContainerType
      ),
      token
    )
      .then((response) =>
        response.json().then((response) => {
          if (response.valid) {
            setSasTest(true);
            setErrorMsg("");
          } else {
            setErrorMsg(response.errorInfo);
          }
          setLocked(false);
          setIsCheckingAccess(false);
        })
      )
      .catch((response) =>
        response.text().then((error) => {
          setLocked(false);
          setIsCheckingAccess(false);
          setErrorMsg(error);
        })
      );
  };

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>
            Tenant ID
            <TooltipHost
              content="Enter the AAD Tenant ID of which your Storage Account is under."
              id="tenantId"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="tenantId"
            disabled={isCheckingAccess}
            onGetErrorMessage={(value) => getTenantIdErrorText(value)}
            onChange={(event, value) => setTenantId(value.trim().toLowerCase())}
            value={tenantId}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Tenant ID"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Subscription ID
            <TooltipHost
              content="Enter the Subscription ID of which your Storage Account is under."
              id="subscriptionId"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="subscriptionId"
            disabled={isCheckingAccess}
            onGetErrorMessage={(value) => getSubscriptionIdErrorText(value)}
            onChange={(event, value) =>
              setSubscriptionId(value.trim().toLowerCase())
            }
            value={subscriptionId}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Subscription ID"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Resource Group
            <TooltipHost
              content="Enter the name of your Resource Group which holds your Storage Account."
              id="resourceGroup"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="resourceGroup"
            disabled={isCheckingAccess}
            onGetErrorMessage={(value) => getResourceGroupErrorText(value)}
            onChange={(event, value) => setResourceGroup(value.trim())}
            value={resourceGroup}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Resource Group"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Storage Account Name
            <TooltipHost
              content="Enter the name of your Azure Storage Account."
              id="storageAcc"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="storageAcc"
            disabled={isCheckingAccess}
            onGetErrorMessage={(value) => getStorageAccountErrorText(value)}
            onChange={(event, value) => {
              setStorageAccName(value.trim().toLowerCase());
              setSasTest(false);
            }}
            value={storageAccName}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Storage Account"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Storage Container Type
            <TooltipHost
              content="Choose the Storage Type of your container that holds your data."
              id="containerType"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <Dropdown
            disabled={isCheckingAccess}
            selectedKey={storageContainerType}
            onChange={(event, option, index) => {
              setStorageContainerType(option.key);
              setSasTest(false);
            }}
            options={containerTypeOptions}
            data-testid="containerType-dropdown"
            className={createInput}
            ariaLabel="Container Type"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Storage Container Name
            <TooltipHost
              content="Enter the name of your data container in the Azure Storage Account."
              id="containerName"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <TextField
            data-testid="containerName"
            disabled={isCheckingAccess}
            onGetErrorMessage={(value) => getStorageContainerErrorText(value)}
            onChange={(event, value) => {
              setStorageContainerName(value.trim());
              setSasTest(false);
            }}
            value={storageContainerName}
            className={createInput}
            validateOnLoad={false}
            ariaLabel="Container Name"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Container Region
            <TooltipHost
              content="Select the region your storage container is in."
              id="containerRegion"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          <Dropdown
            disabled={isCheckingAccess}
            selectedKey={containerRegion}
            onChange={(event, option, index) => setContainerRegion(option.key)}
            options={regionTypeOptions}
            data-testid="containerRegion-dropdown"
            className={createInput}
            ariaLabel="Container Region"
          />
        </div>
        <div className={createRow}>
          <div className={createLabel}>
            Pass Configuration Test?
            <TooltipHost
              content="You cannot continue to the next step until we can verify your storage account has been configured correctly."
              id="validSas"
            >
              <Icon iconName="Info" className={createTooltip} />
            </TooltipHost>
            <span className={createRequired}>*</span>
          </div>
          {configurationCheckDiv()}
          {errorMsg && (
            <span className={validationError}>
              <b>{errorMsg}</b>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectionStep;
