import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerDiv = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
});

export const titleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const batchTitleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "400",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const refreshButton = mergeStyles({
  marginLeft: "8px",
});

export const tableDiv = mergeStyles({
  selectors: {
    ".ms-Viewport": {
      paddingTop: "0px",
      paddingBottom: "0px",
      maxHeight: "inherit",
    },
  },
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  maxHeight: "80%",
});

export const tableStyling = mergeStyles({
  overflowX: "auto",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  selectors: {
    "& [role=grid]": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    ".ms-DetailsHeader": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    ".ms-DetailsList": {
      maxHeight: "300px",
      overflowX: "scroll",
    },
  },
});

export const linkSpan = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgb(0, 69, 120)",
    },
  },
  cursor: "pointer",
  color: "rgb(0, 120, 212)",
  textDecoration: "underline",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

export const errorTextStyle = mergeStyles({
  position: "relative",
  top: "50%",
  left: "45%",
});

export const headerButtonPadding = mergeStyles({
  marginLeft: "8px",
});

export const deleteBatchButton = mergeStyles({
  selectors: {
    ":hover": {
      background: "#750b1c",
      borderColor: "#750b1c",
    },
    ":active": {
      background: "#610614",
      borderColor: "#610614",
    },
  },
  marginLeft: "8px",
  background: "#a4373a",
  borderColor: "#a4373a",
});

export const batchDetailsPivot = mergeStyles({
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
});

export const batchPropertiesDiv = mergeStyles({
  marginTop: "16px",
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
});

export const batchProperties = mergeStyles({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflowX: "hidden",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  height: "max-content",
});

export const sharedUsersDiv = mergeStyles({
  marginLeft: "28px",
  width: "340px",
  display: "flex",
  flexDirection: "column",
});

export const batchPropTitleDiv = mergeStyles({
  flexGrow: "1",
});

export const sharedUsersTitleDiv = mergeStyles({
  display: "flex",
});

export const sharedUsersTitle = mergeStyles({
  flexGrow: "1",
  fontSize: "1.0625rem",
  display: "flex",
  flexDirection: "row",
});

export const sharedUserBox = mergeStyles({
  padding: "10px 8px 10px 0px",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
});

export const batchPropCard = mergeStyles({
  padding: "16px 20px 16px 20px",
});

export const flexRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

export const halfWidth = mergeStyles({
  width: "50%",
  paddingRight: "20px",
});

export const paddingTop = mergeStyles({
  paddingTop: "20px",
});

export const rejectionInput = mergeStyles({
  padding: "0px 10px 20px 10px",
});

export const pivotPadding = mergeStyles({
  padding: "0 24px 0 24px",
});

export const createBatchCard = mergeStyles({
  padding: "16px 8px 16px 8px",
  maxWidth: "800px",
  display: "flex",
  flexDirection: "column",
});

export const card = mergeStyles({
  padding: "20px 20px",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  flexGrow: "1",
});

export const createBatchFooter = mergeStyles({
  display: "flex",
  flexDirection: "column",
  paddingTop: "16px",
  alignItems: "end",
});

export const createButton = mergeStyles({
  maxWidth: "80px",
});

export const modalContainer = mergeStyles({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "stretch",
});

export const modalHeader = mergeStyles({
  flex: "1 1 auto",
  borderTop: "4px solid rgb(0, 120, 212)",
  color: "rgb(50, 49, 48)",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  padding: "12px 12px 14px 24px",
  fontSize: "20px",
});

export const modalBodyPadding = mergeStyles({
  padding: "10px 24px 10px 24px",
  overflowX: "hidden",
});

export const modalSpinner = mergeStyles({
  paddingTop: "8px",
});

export const eulaPadding = mergeStyles({
  padding: "10px 10px 10px 30px",
  overflowX: "hidden",
});

export const modalFooter = mergeStyles({
  padding: "0px 12px 12px 12px",
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
  alignItems: "end",
});

export const modalButtons = mergeStyles({
  marginLeft: "auto",
});

export const cancelButton = mergeStyles({
  marginLeft: "8px",
});

export const fileTableDiv = mergeStyles({
  selectors: {
    ".ms-Viewport": {
      paddingTop: "0px",
      paddingBottom: "0px",
      maxHeight: "inherit",
    },
  },
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  maxHeight: "80%",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  marginBottom: "25px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
});

export const batchEditIcons = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgba(0, 90, 158, 1)",
      textShadow: "0 0 4px rgba(0, 120, 212, 0.5)",
    },
  },
  backgroundColor: "transparent",
  border: "1px solid transparent",
  padding: "0px",
  cursor: "pointer",
});

export const batchEditDisabledIcons = mergeStyles({
  color: "rgb(220,220,220)",
  backgroundColor: "transparent",
  border: "1px solid transparent",
  padding: "0px",
});

export const deleteFileCommandBar = {
  label: { fontSize: 16 },
  icon: { color: "rgb(232, 17, 35)" },
  iconHovered: { color: "rgb(164, 38, 44)" },
};

export const sharedUserTextBoxDiv = mergeStyles({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  flexGrow: "1",
});

export const sharedUserSecondaryText = mergeStyles({
  color: "rgba(0, 0, 0, 0.55)",
  fontSize: ".75rem",
});

export const peopleSelectionDiv = mergeStyles({
  marginTop: "12px",
  display: "flex",
  flexDirection: "column",
});

export const smallText = mergeStyles({
  marginBottom: "8px",
  color: "rgba(0, 0, 0, 0.55)",
  fontSize: "12px",
  fontWeight: "600",
});

export const userListDiv = mergeStyles({
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
});

export const userListSmallText = mergeStyles({
  color: "rgba(0, 0, 0, 0.55)",
  fontSize: "12px",
  fontWeight: "600",
});

export const dnd = mergeStyles({
  [`& a`]: {
    selectors: {
      ":hover": {
        color: "#39bfd3",
      },
    },
  },

  [`&.dz-drag-hover`]: {
    [`outline-offset`]: "-20px",
    [`outline-color`]: "#c8dadf",
    backgroundColor: "#d9edf761",
  },

  width: "100%",
  backgroundColor: "#d9edf7",
  outline: "2px dashed #92b0b3",
  [`outline-offset`]: "-10px",
  [`webkit-transition`]:
    "outline-offset .15s ease-in-out, background-color .15s linear",
  transition: "outline-offset .15s ease-in-out, background-color .15s linear",
  padding: "50px",
  verticalAlign: "middle",
  textAlign: "center",
  marginBottom: "20px",
  borderRadius: "0.25rem",
  marginTop: "16px",
});

export const fileInputButton = mergeStyles({
  cursor: "pointer",
});

export const dropzoneButtons = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

export const dropzonePadding = mergeStyles({
  marginTop: "16px",
});

export const dropzonePreview = mergeStyles({
  paddingTop: "20px",
});

export const fileRow = mergeStyles({
  [`& strong`]: {
    fontWeight: "normal",
  },
});

export const fileUploadPreview = mergeStyles({
  display: "flex",
  flexDirection: "column",
});

export const fileUploadPreviewText = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

export const fileUploadPreviewName = mergeStyles({
  fontWeight: "bold",
  paddingRight: "10px",
});

export const fileUploadPreviewSize = mergeStyles({
  flexGrow: "1",
});

export const fileCancel = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgba(0, 90, 158, 1)",
      textShadow: "0 0 4px rgba(0, 120, 212, 0.5)",
      cursor: "pointer",
    },
  },
});

export const historicalDiv = mergeStyles({
  marginRight: "15%",
  marginLeft: "15%",
  marginTop: "32px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
});

export const historyTitle = mergeStyles({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.2125rem",
  lineHeight: "20px",
});

export const borderLine = mergeStyles({
  height: "1px",
  background: "#EDEBE9",
  margin: "10px 0px",
});

export const nameText = mergeStyles({
  fontWeight: "bold",
});

export const activityItemDiv = mergeStyles({
  marginTop: "20px",
});

export const diffInfoCallout = mergeStyles({
  minWidth: 320,
  maxWidth: "90%",
  padding: "20px 24px",
});

export const tableRowButton = mergeStyles({
  selectors: {
    ".ms-Button": {
      lineHeight: "24px",
      height: "26px",
    },
  },
});

export const confirmModalButton = mergeStyles({
  marginLeft: "8px",
});

export const baseDiv = classNames(greyPage, base);
