import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectCloud } from "./modeSlice";

export const select = createAsyncThunk(
  "tentedClouds/select",
  async (cloud, getThunkAPI) => {
    getThunkAPI.dispatch(selectCloud(cloud.project));
    return cloud;
  }
);

export const tentedSlice = createSlice({
  name: "tentedClouds",
  initialState: {
    list: [],
    status: "loading",
    selected: null,
  },
  reducers: {
    set_clouds: (state, action) => {
      state.list = action.payload;
      state.status = "success";
    },
    set_cloud_status: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(select.fulfilled, (state, action) => {
      state.selected = action.payload;
    });
  },
});

export const { set_clouds, set_cloud_status } = tentedSlice.actions;

export default tentedSlice.reducer;
