import { Checkbox } from "@fluentui/react";
import LoadingView from "egressFlows/LoadingView";
import {
  aupPadding,
  card,
  createFlowCard,
  createRow,
  createSectionTitle,
} from "egressFlows/Styles";

const AupStep = (props) => {
  const { aupForm, fetchingAup, acceptAup, setAcceptAup } = props;

  const content = (
    <>
      {aupForm !== "" && (
        <div className={createRow}>
          <Checkbox
            label="I Agree"
            checked={acceptAup}
            onChange={(event, isChecked) => setAcceptAup(isChecked)}
          />
        </div>
      )}
      <div
        className={aupPadding}
        dangerouslySetInnerHTML={{ __html: aupForm }}
      ></div>
    </>
  );

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Acceptable Use Policy</div>
        {fetchingAup ? (
          <LoadingView label="Fetching Acceptable Use Policy Form" />
        ) : (
          content
        )}
      </div>
    </div>
  );
};

export default AupStep;
