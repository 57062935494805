import { useAccount, useMsal } from "@azure/msal-react";
import {
  Dropdown,
  DropdownMenuItemType,
  MessageBarType,
  Position,
  PrimaryButton,
  SpinButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { protectedResources } from "authConfig";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminOpsAPI } from "utils/endpoints";
import ConfirmManifestModal from "./ConfirmManifestModal";
import {
  baseDiv,
  body,
  footer,
  headerDiv,
  paddingTop,
  primaryActionButton,
  spinInputStyle,
  titleFont,
  whitebg,
} from "./Styles";

const ResendManifest = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [manifest, setManifest] = useState(0);
  const [cluster, setCluster] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const clusters =
    useSelector((state) => state.cluster.list)[selectedCloud?.project] ?? [];
  const ingressClusters = [
    {
      key: "ingressHeader",
      text: "Ingress Clusters",
      itemType: DropdownMenuItemType.Header,
    },
  ];
  clusters.forEach((clusterName) => {
    if (clusterName.slice(-1) === "a") {
      ingressClusters.push({
        key: clusterName,
        text: clusterName,
      });
    }
  });
  const clusterDropdownList = ingressClusters;

  const buttonEnabled = manifest > 0 && cluster;

  const resendManifest = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const bodyParams = {
      manifest: manifest,
      cluster: cluster.key,
    };

    setRequestPending(true);
    hideModal();

    callAuthorizedEndpointWithBody(
      `${adminOpsAPI}/resend/manifest`,
      token,
      "POST",
      bodyParams
    )
      .then(() => {
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Successfully resent manifest.",
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestPending(false);
      });
  };

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Resend Manifest</span>
      </div>
      <div className={whitebg}>
        <div className={body}>
          <SpinButton
            label="Manifest Sequence"
            labelPosition={Position.top}
            value={manifest}
            onChange={(event, value) => setManifest(parseInt(value))}
            min={1}
            step={1}
            className={spinInputStyle}
          />
          <Dropdown
            label="Cluster"
            options={clusterDropdownList}
            onChange={(event, option, index) => setCluster(option)}
            data-testid="cluster-dropdown"
            className={paddingTop}
          />
          <div className={footer}>
            <PrimaryButton
              className={primaryActionButton}
              disabled={!buttonEnabled || requestPending}
              data-testid="resendManifest-button"
              onClick={() => showModal()}
            >
              {requestPending ? "Loading..." : "Resend Manifest"}
            </PrimaryButton>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmManifestModal
          {...{
            isModalOpen,
            hideModal,
            manifest,
            cluster,
            resendManifest,
          }}
        />
      )}
    </div>
  );
};

export default ResendManifest;
