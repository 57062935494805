import { useAccount, useMsal } from "@azure/msal-react";
import { Icon, Label, MessageBarType, TextField } from "@fluentui/react";
import { protectedResources } from "authConfig";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { egressFlowAPI } from "utils/endpoints";
import {
  getResourceGroupErrorText,
  getServiceNameErrorText,
  getServiceTreeErrorText,
  getSubscriptionIdErrorText,
  getTitleErrorText,
  validResourceGroup,
  validServiceName,
  validServiceTreeId,
  validSubscriptionId,
  validTitle,
} from "../createFlowValidation";
import {
  card,
  createInput,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  editIconsPadding,
  flowEditDisabledIcons,
  flowEditIcon,
  flowSectionTitleDiv,
  validationLabel,
} from "../Styles";
import EmployeePicker from "employeePicker/EmployeePicker";
import { aliasesToPeoplePickerList } from "ingressFlows/admin/AdminEditFlow";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";

const isEqualsJson = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  return (
    keys1.length === keys2.length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
};

const EditFlow = (props) => {
  const { flow, setFlow } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [editMode, setEditMode] = useState(false);
  const [modifiedFlow, setModifiedFlow] = useState(flow);
  const [sending, setSending] = useState(false);

  const onSecondaryUserSearchChange = (items) => {
    const itemArr = items.map((item) =>
      item.key.includes("@")
        ? getAliasFromUserPrincipalName(item.key)
        : item.key
    );

    const uniqueArr = [...new Set(itemArr)];

    setModifiedFlow({
      ...modifiedFlow,
      secondaryContact: uniqueArr.join(";"),
    });
  };

  const isUpdateAllowed =
    !sending &&
    !isEqualsJson(flow, modifiedFlow) &&
    validTitle(modifiedFlow.title) &&
    validSubscriptionId(modifiedFlow.subscriptionId) &&
    validResourceGroup(modifiedFlow.resourceGroup) &&
    validServiceName(modifiedFlow.serviceName) &&
    validServiceTreeId(modifiedFlow.serviceTreeId);

  const getDiff = (field) =>
    modifiedFlow[field] === flow[field] ? null : modifiedFlow[field];

  const getDiffJson = () => {
    const diffs = {
      ResourceGroup: getDiff("resourceGroup"),
      SecondaryContact: getDiff("secondaryContact"),
      ServiceName: getDiff("serviceName"),
      ServiceTreeId: getDiff("serviceTreeId"),
      SubscriptionId: getDiff("subscriptionId"),
      Title: getDiff("title"),
    };

    Object.keys(diffs).forEach((key) => {
      if (diffs[key] === null) {
        delete diffs[key];
      }
    });

    return diffs;
  };

  const updateFlow = async () => {
    const body = getDiffJson();

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setSending(true);

    callAuthorizedEndpointWithBody(
      `${egressFlowAPI}/${flow.dataflowGuid}?cloud=${flow.cloud}`,
      token,
      "PATCH",
      body
    )
      .then(() => {
        setFlow(modifiedFlow);
        setEditMode(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setSending(false);
      });
  };

  const cancelUpdate = () => {
    setEditMode(false);
    setModifiedFlow(flow);
  };

  const editButton = editMode ? (
    <>
      <button
        onClick={() => {
          if (isUpdateAllowed) updateFlow();
        }}
        className={isUpdateAllowed ? flowEditIcon : flowEditDisabledIcons}
        data-testid="update-button"
        aria-label="confirm-edit-flow"
      >
        <Icon iconName="Accept" />
      </button>
      <button
        onClick={() => {
          if (!sending) cancelUpdate();
        }}
        className={`${editIconsPadding} ${flowEditIcon} ${
          sending ? flowEditDisabledIcons : ""
        }`}
        data-testid="cancel-button"
        aria-label="cancel-edit-flow"
      >
        <Icon iconName="Cancel" />
      </button>
    </>
  ) : (
    <button
      className={flowEditIcon}
      onClick={() => setEditMode(true)}
      data-testid="edit-button"
      aria-label="edit-egress-flow"
    >
      <Icon iconName="Edit" />
    </button>
  );

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>Basics</div>
          {editButton}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          {editMode ? (
            <TextField
              data-testid="Title"
              onGetErrorMessage={(value) => getTitleErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, title: value })
              }
              value={modifiedFlow.title}
              className={createInput}
              ariaLabel="title"
            />
          ) : (
            <Label className={validationLabel}>{flow.title}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{flow.email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          <Label className={validationLabel}>{flow.primaryContact}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          {editMode ? (
            <EmployeePicker
              onChange={(items) => onSecondaryUserSearchChange(items)}
              defaultSelectedItems={aliasesToPeoplePickerList(
                modifiedFlow.secondaryContact
              )}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.secondaryContact?.split(";").join(", ")}
            </Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>Storage Tenant Id</div>
          <Label className={validationLabel}>{flow.storageTenantId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          {editMode ? (
            <TextField
              data-testid="subscriptionID"
              onGetErrorMessage={(value) => getSubscriptionIdErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  subscriptionId: value.trim().toLowerCase(),
                })
              }
              value={modifiedFlow.subscriptionId}
              className={createInput}
              ariaLabel="Subscription ID"
            />
          ) : (
            <Label className={validationLabel}>{flow.subscriptionId}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          {editMode ? (
            <TextField
              data-testid="resourceGroup"
              onGetErrorMessage={(value) => getResourceGroupErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  resourceGroup: value.trim(),
                })
              }
              value={modifiedFlow.resourceGroup}
              className={createInput}
              ariaLabel="Resource Group"
            />
          ) : (
            <Label className={validationLabel}>{flow.resourceGroup}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          {editMode ? (
            <TextField
              data-testid="serviceName"
              onGetErrorMessage={(value) => getServiceNameErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceName: value,
                })
              }
              value={modifiedFlow.serviceName}
              className={createInput}
              ariaLabel="Service Name"
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceName}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          {editMode ? (
            <TextField
              data-testid="serviceTreeId"
              onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  serviceTreeId: value.trim(),
                })
              }
              value={modifiedFlow.serviceTreeId}
              className={createInput}
              ariaLabel="Service Tree ID"
            />
          ) : (
            <Label className={validationLabel}>{flow.serviceTreeId}</Label>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditFlow;
