import { Label } from "@fluentui/react";
import {
  card,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  flowSectionTitleDiv,
  validationLabel,
} from "egressFlows/Styles";

const ReviewDetails = (props) => {
  const { flow } = props;

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>DRI Details</div>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Egress Status</div>
          <Label className={validationLabel}>{flow.egressStatus}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Notes</div>
          <Label className={validationLabel}>{flow.notes}</Label>
        </div>
        <br />
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          <Label className={validationLabel}>{flow.title}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{flow.email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          <Label className={validationLabel}>{flow.primaryContact}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          <Label className={validationLabel}>
            {flow.secondaryContact?.split(";").join(", ")}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>Storage Tenant ID</div>
          <Label className={validationLabel}>{flow.storageTenantId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          <Label className={validationLabel}>{flow.subscriptionId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          <Label className={validationLabel}>{flow.resourceGroup}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Justification Details</div>

        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          <Label className={validationLabel}>{flow.serviceName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          <Label className={validationLabel}>{flow.serviceTreeId}</Label>
        </div>
      </div>
    </div>
  );
};

export default ReviewDetails;
