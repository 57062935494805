import { useAccount, useMsal } from "@azure/msal-react";
import { ActivityItem, Icon, TooltipHost } from "@fluentui/react";
import { protectedResources } from "authConfig";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  markNotificationRead,
} from "store/notificationSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import LoadingView from "./LoadingView";
import {
  activityDeleteIcon,
  activityItemIcon,
  activityItemStyle,
  activityItemText,
  baseDiv,
  borderLine,
  noNotificationsText,
  notificationBase,
  notifRow,
  notifTitle,
  tooltipStyle,
  unreadNotif,
} from "./Styles";

/* Helper to get the "time-since" string by calculating the time difference between two Dates */
const calculateTimeSince = (date1, date2) => {
  // convert to UTC to remove time-zone inconsistencies
  const utc1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    date1.getHours(),
    date1.getMinutes(),
    date1.getSeconds()
  );
  const utc2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    date2.getHours(),
    date2.getMinutes(),
    date2.getSeconds()
  );

  const _MS_PER_SEC = 1000;
  const _SEC_PER_MIN = 60;
  const _SEC_PER_HR = _SEC_PER_MIN * 60;
  const _SEC_PER_DAY = _SEC_PER_HR * 24;
  const _SEC_PER_WEEK = _SEC_PER_DAY * 7;

  // get the difference between the two dates in seconds
  const diff = Math.floor(Math.abs(utc1 - utc2) / _MS_PER_SEC);

  if (Math.floor(diff / _SEC_PER_MIN) === 0) {
    return `${diff}s`;
  } else if (Math.floor(diff / _SEC_PER_HR) === 0) {
    return `${Math.floor(diff / _SEC_PER_MIN)}m`;
  } else if (Math.floor(diff / _SEC_PER_DAY) === 0) {
    return `${Math.floor(diff / _SEC_PER_HR)}h`;
  } else if (Math.floor(diff / _SEC_PER_WEEK) === 0) {
    return `${Math.floor(diff / _SEC_PER_DAY)}d`;
  } else {
    return `${Math.floor(diff / _SEC_PER_WEEK)}w`;
  }
};

const notificationTypeToIconType = {
  BATCH_APPROVED: "CheckMark",
  BATCH_REJECTED: "Clear",
  BATCH_SUBMITTED: "PublicFolder",
  EGRESS_FINAL_APPROVED: "CheckMark",
  EGRESS_INTIAL_APPROVED: "CheckMark",
  EGRESS_REQUESTED: "CloudDownload",
  EGRESS_UPDATED: "CloudDownload",
  ONBOARD_APPROVED: "CheckMark",
  ONBOARD_REQUESTED: "CloudUpload",
  ONBOARD_UPDATED: "CloudUpload",
};

export const Notifications = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const loading =
    useSelector((state) => state.notification.status) === "loading";
  const notifications = useSelector((state) => state.notification.list)
    .map((notification) => ({
      ...notification,
      creationDate: new Date(notification.creationDate),
    }))
    .sort((notif1, notif2) => {
      return notif2.creationDate - notif1.creationDate;
    });

  const deleteNotif = async (id) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(deleteNotification({ token: token, id: id }));
  };

  const readNotif = async (id) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(markNotificationRead({ token: token, id: id }));
  };

  const notificationList = (
    <div className={notificationBase}>
      <span className={notifTitle}>Notifications</span>
      <div className={borderLine} />
      {notifications.map((notification, currIndex) => (
        <div
          key={`div-${currIndex}`}
          className={notification.isRead ? notifRow : unreadNotif}
        >
          <ActivityItem
            key={`activityItem-${currIndex}`}
            activityDescription={[
              <span key={1} className={activityItemText}>
                {notification.message}
              </span>,
            ]}
            activityIcon={
              <Icon
                key={1}
                iconName={
                  notificationTypeToIconType[notification.notificationType] ??
                  "Help"
                }
                className={activityItemIcon}
              />
            }
            timeStamp={calculateTimeSince(
              notification.creationDate,
              new Date()
            )}
            className={activityItemStyle}
          />
          {!notification.isRead && (
            <TooltipHost
              content="Mark notification as read"
              className={tooltipStyle}
            >
              <button
                className={activityDeleteIcon}
                onClick={() => readNotif(notification.id)}
                aria-label={`mark-read-${notification.id}`}
              >
                <Icon iconName="RingerOff" />
              </button>
            </TooltipHost>
          )}
          <TooltipHost content="Delete this notification">
            <button
              className={activityDeleteIcon}
              onClick={() => deleteNotif(notification.id)}
              aria-label={`delete-${notification.id}`}
            >
              <Icon iconName="Delete" />
            </button>
          </TooltipHost>
        </div>
      ))}
    </div>
  );

  const notificationView =
    notifications.length > 0 ? (
      notificationList
    ) : (
      <div className={noNotificationsText}>
        You don't have any notifications
      </div>
    );

  return (
    <div className={baseDiv}>
      {loading ? <LoadingView /> : notificationView}
    </div>
  );
};

export default Notifications;
