import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerDiv = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
});

export const titleFont = mergeStyles({
  fontSize: "1.3125rem",
  fontWeight: "600",
  letterSpacing: "-.02em",
  display: "flex",
  flexGrow: "1",
});

export const refreshButton = mergeStyles({
  marginLeft: "8px",
});

export const tableDiv = mergeStyles({
  selectors: {
    ".ms-Viewport": {
      paddingTop: "0px",
      paddingBottom: "0px",
      maxHeight: "inherit",
    },
  },
  paddingTop: "16px",
  paddingLeft: "32px",
  paddingRight: "32px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  maxHeight: "80%",
});

export const tableStyling = mergeStyles({
  overflowX: "auto",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  selectors: {
    "& [role=grid]": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    ".ms-DetailsHeader": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    ".ms-DetailsList": {
      maxHeight: "300px",
      overflowX: "scroll",
    },
  },
});

export const linkSpan = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgb(0, 69, 120)",
    },
  },
  cursor: "pointer",
  color: "rgb(0, 120, 212)",
  textDecoration: "underline",
});

export const pivotPadding = mergeStyles({
  padding: "0 24px 0 24px",
  flexGrow: "1",
  overflowY: "auto",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

export const createFooter = mergeStyles({
  padding: "25px 20px 20px 20px",
  margin: "0px 20px",
  borderTop: "1px solid rgb(187, 187, 187)",
});

export const footerRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

export const footerButtons = mergeStyles({
  height: "24px",
});

export const pivotSelectButton = mergeStyles({
  marginLeft: "10px",
  marginRight: "10px",
});

export const reviewDiv = mergeStyles({
  paddingLeft: "100px",
});

export const card = mergeStyles({
  padding: "20px 20px",
  background: "white",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow:
    "rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px",
  flexGrow: "1",
});

export const createFlowCard = mergeStyles({
  padding: "16px 8px 16px 8px",
  display: "flex",
  flexDirection: "column",
});

export const createInput = mergeStyles({
  width: "470px",
  selectors: {
    ".ms-TextField-fieldGroup": {
      height: "30px",
    },
  },
});

export const createLabel = mergeStyles({
  width: "250px",
  textOverflow: "ellipsis",
  display: "inline-block",
});

export const createRequired = mergeStyles({
  color: "rgb(164, 38, 44)",
  paddingRight: "12px",
});

export const createRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
  marginTop: "15px",
});

export const createSectionTitle = mergeStyles({
  fontWeight: "bold",
  marginBottom: "18px",
  flexGrow: "1",
  display: "flex",
  flexDirection: "row",
});

export const createTooltip = mergeStyles({
  verticalAlign: "middle",
  padding: "0px 8px",
  marginLeft: "5px",
});

export const validationLabel = mergeStyles({
  selectors: {
    ".ms-Label": {
      padding: "0px !important",
    },
  },
  padding: "0px !important",
});

export const successText = mergeStyles({
  color: "rgb(75, 181, 67)",
});

export const validationError = mergeStyles({
  color: "rgb(164, 38, 44)",
  paddingLeft: "12px",
});

export const aupPadding = mergeStyles({
  paddingTop: "18px",
});

export const errorTextStyle = mergeStyles({
  position: "relative",
  top: "50%",
  left: "45%",
});

export const essentialsTitle = mergeStyles({
  fontWeight: "bold",
  marginBottom: "18px",
  paddingLeft: "3px",
});

export const essentialsColumn = mergeStyles({
  width: "50%",
  display: "inline-block",
  verticalAlign: "top",
});

export const essentialsGrid = mergeStyles({
  display: "grid",
  gridTemplateColumns: "max-content max-content auto",
  overflow: "hidden",
});

export const essentialsText = mergeStyles({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "5px 3px",
  "line-height": "24px",
});

export const editFlowCard = mergeStyles({
  padding: "16px 8px 16px 8px",
  display: "flex",
  flexDirection: "column",
  width: "90%",
});

export const flowSectionTitleDiv = mergeStyles({
  flexGrow: "1",
});

export const flowEditIcon = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgba(0, 90, 158, 1)",
      textShadow: "0 0 4px rgba(0, 120, 212, 0.5)",
    },
  },
  backgroundColor: "transparent",
  border: "1px solid transparent",
  padding: "0px",
  cursor: "pointer",
});

export const editIconsPadding = mergeStyles({
  marginLeft: "8px",
});

export const testSasButton = mergeStyles({
  selectors: {
    ".ms-Button": {
      lineHeight: "24px",
      height: "26px",
    },
  },
});

export const flowEditDisabledIcons = mergeStyles({
  backgroundColor: "transparent",
  border: "1px solid transparent",
  padding: "0px",
  color: "rgb(220,220,220)",
});

export const deleteFlowButton = mergeStyles({
  selectors: {
    ":hover": {
      background: "#750b1c",
      borderColor: "#750b1c",
    },
    ":active": {
      background: "#610614",
      borderColor: "#610614",
    },
  },
  marginLeft: "8px",
  background: "#a4373a",
  borderColor: "#a4373a",
});

export const cancelButton = mergeStyles({
  marginLeft: "8px",
});

export const approveButton = mergeStyles({
  marginLeft: "8px",
});

export const modalContainer = mergeStyles({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "stretch",
});

export const modalBodyPadding = mergeStyles({
  padding: "10px 24px 10px 24px",
  overflowX: "hidden",
});

export const modalHeader = mergeStyles({
  flex: "1 1 auto",
  borderTop: "4px solid rgb(0, 120, 212)",
  color: "rgb(50, 49, 48)",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  padding: "12px 12px 14px 24px",
  fontSize: "20px",
});

export const modalFooter = mergeStyles({
  padding: "30px 12px 12px 12px",
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
  alignItems: "end",
});

export const modalButtons = mergeStyles({
  marginLeft: "auto",
});

export const padTop = mergeStyles({
  marginTop: "20px",
});

export const redText = mergeStyles({
  color: "red",
});

export const historicalDiv = mergeStyles({
  marginRight: "15%",
  marginLeft: "15%",
  marginTop: "32px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
});

export const historyTitle = mergeStyles({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.2125rem",
  lineHeight: "20px",
});

export const borderLine = mergeStyles({
  height: "1px",
  background: "#EDEBE9",
  margin: "10px 0px",
});

export const nameText = mergeStyles({
  fontWeight: "bold",
});

export const activityItemDiv = mergeStyles({
  marginTop: "20px",
});

export const diffInfoCallout = mergeStyles({
  minWidth: 320,
  maxWidth: "90%",
  padding: "20px 24px",
});

export const statusDropdown = mergeStyles({
  selectors: {
    ".ms-Dropdown-title": {
      lineHeight: "24px",
      height: "26px",
    },
    ".ms-Dropdown-caretDownWrapper": {
      lineHeight: "24px",
      height: "24px",
    },
  },
  width: "80%",
});

export const baseDiv = classNames(greyPage, base);
export const pivotSelect = classNames(footerButtons, pivotSelectButton);
export const validationSuccess = classNames(validationLabel, successText);
