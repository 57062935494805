import {
  Callout,
  Dropdown,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import {
  borderLine,
  card,
  cardFooter,
  diffInfoCallout,
  eventExpandedPane,
  eventListDiv,
  footer,
  historicalDiv,
  historyTitle,
  paddingTop,
  primaryActionButton,
  queryPane,
  queryTitleDiv,
  resizeLine,
  resourceTitleFont,
  spinnerCentered,
  splitPane,
  titleFont,
  titleWithPadding,
} from "./Styles";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { useDispatch } from "react-redux";
import { adminOpsAPI } from "utils/endpoints";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  generateActivityItems,
  generateDiffCallout,
  generateInfoList,
} from "./HistoryUtils";

const searchTypeOptions = [
  { key: "full", text: "Full Scan" },
  { key: "query", text: "Param Query" },
];

const resourceTypeOptions = [
  { key: "batch", text: "Batch" },
  { key: "paste", text: "Paste" },
  { key: "ingressFlow", text: "Ingress Flow" },
  { key: "egressFlow", text: "Egress Flow" },
  { key: "all", text: "All" },
];

const queryTypeOptions = [
  { key: "substring", text: "Substring Matching" },
  { key: "exact", text: "Exact Matching" },
];

const prepEventResponse = (eventObj) => {
  const output = {
    BATCH: [],
    PASTE: [],
    EGRESS_FLOW: [],
    INGRESS_FLOW: [],
  };

  Object.keys(output).forEach((eventType) => {
    if (eventObj[eventType]) {
      Object.keys(eventObj[eventType]).forEach((resource) => {
        const eventList = eventObj[eventType][resource].map((event) => ({
          ...event,
          eventDetails: JSON.parse(event.requestBody),
          creationDate: new Date(event.eventDate),
        }));

        const infoList = generateInfoList(eventList, eventType);

        output[eventType].push(infoList);
      });
    }
  });

  return output;
};

const AuditSearch = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [requestPending, setRequestPending] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState(
    searchTypeOptions[1].key
  );
  const [selectedResourceType, setSelectedResourceType] = useState(
    resourceTypeOptions[0].key
  );
  const [resourceName, setResourceName] = useState("");
  const [selectedQueryType, setSelectedQueryType] = useState(
    queryTypeOptions[0].key
  );
  const [events, setEvents] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const [targetId, setTarget] = useState(null);
  const [info, setInfo] = useState({});

  const showMoreInfo = (target, info) => {
    setTarget(target);
    setInfo(info);
    toggleIsCalloutVisible();
  };

  const onClickResource = (eventType, resourceIndex) => {
    const eventList = events[eventType][resourceIndex];
    setSelectedEvent(eventList);
  };

  const resetSearchType = (option) => {
    setSelectedSearchType(option.key);
    setResourceName("");
    setSelectedQueryType(queryTypeOptions[0].key);
  };

  const searchButtonEnabled = () => {
    if (selectedSearchType === searchTypeOptions[1].key && !resourceName) {
      return false;
    }

    return true;
  };

  const getAuditHistory = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const bodyParams = {
      searchType: selectedSearchType,
      resourceType: selectedResourceType,
      resourceName: resourceName,
      queryType: selectedQueryType,
    };

    setTarget(null);
    setInfo({});
    setSelectedEvent(null);
    setEvents(null);
    setRequestPending(true);

    callAuthorizedEndpointWithBody(
      `${adminOpsAPI}/getAuditHistory`,
      token,
      "POST",
      bodyParams
    )
      .then((response) => response.json())
      .then((response) => {
        const events = prepEventResponse(response);
        setEvents(events);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setRequestPending(false);
      });
  };

  return (
    <div className={splitPane}>
      <div className={queryPane}>
        <div className={queryTitleDiv}>
          <span className={titleFont}>Audit Event Search</span>
        </div>
        <Dropdown
          label="Search Type"
          options={searchTypeOptions}
          className={paddingTop}
          selectedKey={selectedSearchType}
          data-testid="search-type"
          onChange={(event, option, index) => resetSearchType(option)}
        />
        <Dropdown
          label="Resource Type"
          options={resourceTypeOptions}
          className={paddingTop}
          errorMessage=""
          selectedKey={selectedResourceType}
          onChange={(event, option, index) =>
            setSelectedResourceType(option.key)
          }
        />
        {selectedSearchType === searchTypeOptions[1].key && (
          <>
            <TextField
              label="Resource Name"
              className={paddingTop}
              value={resourceName}
              onGetErrorMessage={(value) =>
                value?.length > 0 ? "" : "You must provide this field"
              }
              onChange={(event, newValue) => setResourceName(newValue)}
            />
            <Dropdown
              label="Query Type"
              options={queryTypeOptions}
              className={paddingTop}
              selectedKey={selectedQueryType}
              onChange={(event, option, index) =>
                setSelectedQueryType(option.key)
              }
            />
          </>
        )}
        <div className={footer}>
          <PrimaryButton
            disabled={!searchButtonEnabled() || requestPending}
            className={primaryActionButton}
            onClick={getAuditHistory}
            data-testid="search-button"
          >
            Search
          </PrimaryButton>
        </div>
      </div>
      <div className={resizeLine} />
      {requestPending && (
        <div className={spinnerCentered}>
          <Spinner
            size={SpinnerSize.large}
            label="Searching"
            labelPosition="top"
          />
        </div>
      )}
      {events && (
        <>
          <div className={eventListDiv}>
            <span className={resourceTitleFont}>Pastes</span>
            {events.PASTE.length > 0 &&
              events.PASTE.map((event, currIndex) => (
                <div
                  className={card}
                  key={currIndex}
                  onClick={() => onClickResource("PASTE", currIndex)}
                >
                  <span className={cardFooter}>{event[0].partitionKey}</span>
                </div>
              ))}
            {events.PASTE.length === 0 && <span>No results found</span>}
            <span className={titleWithPadding}>Batches</span>
            {events.BATCH.length > 0 &&
              events.BATCH.map((event, currIndex) => (
                <div
                  className={card}
                  key={currIndex}
                  onClick={() => onClickResource("BATCH", currIndex)}
                >
                  <span className={cardFooter}>{event[0].partitionKey}</span>
                </div>
              ))}
            {events.BATCH.length === 0 && <span>No results found</span>}
            <span className={titleWithPadding}>Ingress Flows</span>
            {events.INGRESS_FLOW.length > 0 &&
              events.INGRESS_FLOW.map((event, currIndex) => (
                <div
                  className={card}
                  key={currIndex}
                  onClick={() => onClickResource("INGRESS_FLOW", currIndex)}
                >
                  <span className={cardFooter}>{event[0].partitionKey}</span>
                </div>
              ))}
            {events.INGRESS_FLOW.length === 0 && <span>No results found</span>}
            <span className={titleWithPadding}>Egress Flows</span>
            {events.EGRESS_FLOW.length > 0 &&
              events.EGRESS_FLOW.map((event, currIndex) => (
                <div
                  className={card}
                  key={currIndex}
                  onClick={() => onClickResource("EGRESS_FLOW", currIndex)}
                >
                  <span className={cardFooter}>{event[0].partitionKey}</span>
                </div>
              ))}
            {events.EGRESS_FLOW.length === 0 && <span>No results found</span>}
          </div>
          <div className={resizeLine} />
          {selectedEvent && (
            <div className={eventExpandedPane}>
              <div className={historicalDiv}>
                <span className={historyTitle}>
                  {selectedEvent[0].partitionKey}
                </span>
                <div className={borderLine} />
                {generateActivityItems(selectedEvent, showMoreInfo)}
              </div>
              {isCalloutVisible && (
                <Callout
                  role="dialog"
                  target={`#${targetId}`}
                  gapSpace={0}
                  onDismiss={toggleIsCalloutVisible}
                  className={diffInfoCallout}
                  setInitialFocus
                >
                  <div>{generateDiffCallout(info)}</div>
                </Callout>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AuditSearch;
