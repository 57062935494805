import { connectionStatusMapping } from "connections/utils";
import PaginationTable from "paginationTable/Table";
import { linkSpan, tableStyling } from "pipelines/Styles";
import { Link } from "react-router-dom";
import {
  getResourceGroup,
  getSubscriptionId,
} from "utils/resourceProviderUtils";

const ConnectionList = (props) => {
  const { connections } = props;

  const columns = [
    {
      key: "column1",
      name: "Name",
      onRender: (item) => {
        return (
          <Link
            className={linkSpan}
            to={`/connection/${getSubscriptionId(item.id)}/${getResourceGroup(
              item.id
            )}/${item.name}
            `}
          >
            {item.name}
          </Link>
        );
      },
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Direction",
      onRender: (item) => {
        return <div>{item.properties.direction}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Status",
      onRender: (item) => {
        return <div>{connectionStatusMapping[item.properties.status]}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Flow Types",
      onRender: (item) => {
        return <div>{item.properties.flowTypes?.join(";") ?? ""}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  return (
    <PaginationTable
      items={connections}
      columns={columns}
      tableStyling={tableStyling}
      loading={false}
    />
  );
};

export default ConnectionList;
