import { useAccount, useMsal } from "@azure/msal-react";
import { cardPadding, cardTitle, dashboardCard } from "dashboard/Styles";
import PaginationTable from "paginationTable/Table";
import { useDispatch, useSelector } from "react-redux";
import { protectedResources } from "authConfig";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useEffect } from "react";
import { adminDashboardAPI } from "../../utils/endpoints";
import { setShow, setMessage } from "store/messageBarSlice";
import { MessageBarType } from "@fluentui/react";
import { useState } from "react";
import { useCallback } from "react";

const TentedCheckCard = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const [fetching, setFetching] = useState(true);
  const [items, setItems] = useState([]);

  const columns = [
    {
      key: "column1",
      name: "Flow GUID",
      fieldName: "flowId",
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "column2",
      name: "Owner",
      fieldName: "owner",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Enabled",
      fieldName: "enabled",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Untented",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{item.untentedContacts.join(";")}</div>;
      },
    },
  ];

  const getUntentedList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setFetching(true);
    callAuthorizedEndpoint(
      `${adminDashboardAPI}/tentcheck/ingress?cloud=${selectedCloud.project}`,
      accessToken
    )
      .then((response) => response.json())
      .then((response) => {
        setItems(response.untentedFlows);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => setFetching(false));
  }, [account, dispatch, selectedCloud, instance]);

  /**
   * On start, retrieve batch list
   */
  useEffect(() => {
    getUntentedList();
  }, [getUntentedList]);

  return (
    <div className={dashboardCard}>
      <div className={cardPadding}>
        <div className={cardTitle}>Ingress Flows with Untented Contacts</div>
        <PaginationTable
          items={items}
          columns={columns}
          tableStyling={{}}
          loading={fetching}
        />
      </div>
    </div>
  );
};

export default TentedCheckCard;
