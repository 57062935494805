import { TooltipHost } from "@fluentui/react";
import { boltButton, menuItem } from "./Styles";

const NavMenuItem = (props) => {
  const { onClick, tooltipContent, ariaLabel } = props;

  return (
    <div className={menuItem}>
      <TooltipHost content={tooltipContent}>
        <button
          className={boltButton}
          tabIndex={0}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          {props.children}
        </button>
      </TooltipHost>
    </div>
  );
};

export default NavMenuItem;
