import { useAccount, useMsal } from "@azure/msal-react";
import { NormalPeoplePicker } from "@fluentui/react";
import { graphApiScopes } from "authConfig";
import { useState } from "react";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";

/**
 * Helper function to craft the API for retrieving the list of users within the organization that matches the filter search
 * @param {string} alias alias of the user to look up
 * @returns URI to send the HTTP Get Request to
 */
export const SearchGraphUserAPI = (searchText) =>
  `https://graph.microsoft.com/v1.0/users?$filter=startsWith(displayName,'${searchText}') or startsWith(userPrincipalName,'${searchText}')`;

/**
 * Helper function to get the first initials of a Full Name
 * @param {string} fullName full name string of the person
 * @returns string in the form of "{firstName_initial}{lastName_initial}"
 */
export const getUserAccountInitials = (fullName) => {
  const nameArr = fullName.split(" ");
  return nameArr.length === 2
    ? `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`
    : "";
};

const pickerSuggestions = {
  noResultsFoundText: "No results found",
  loadingText: "Loading",
};

const EmployeePicker = (props) => {
  const {
    pickerSuggestionsProps,
    onChange,
    itemLimit,
    defaultSelectedItems,
    className,
  } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [selectedItems, setSelectedItems] = useState([]);

  const searchSharedUser = async (searchText) => {
    const token = await getAuthorizationToken(
      instance,
      graphApiScopes,
      account
    );

    return callAuthorizedEndpoint(SearchGraphUserAPI(searchText), token)
      .then((response) => response.json())
      .then((response) => {
        return response.value.map((user, currIndex) => {
          return {
            imageInitials: getUserAccountInitials(user.displayName),
            key: user.userPrincipalName,
            secondaryText: user.userPrincipalName,
            text: user.displayName,
            upn: user.userPrincipalName,
          };
        });
      })
      .catch(() => {
        return [];
      });
  };

  const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
    return filterText ? searchSharedUser(filterText) : [];
  };

  const onSelectHandler = (items) => {
    setSelectedItems(items);
    onChange(items);
  };

  // Only allow unique items, filter out duplicates
  const itemSelectHandler = (item) => {
    const found = selectedItems.find(
      (selectedItem) => selectedItem.key === item.key
    );
    return found ? null : item;
  };

  return (
    <NormalPeoplePicker
      onResolveSuggestions={onFilterChanged}
      pickerSuggestionsProps={pickerSuggestionsProps ?? pickerSuggestions}
      onChange={(items) => onSelectHandler(items)}
      itemLimit={itemLimit}
      onItemSelected={itemSelectHandler}
      defaultSelectedItems={defaultSelectedItems ?? []}
      className={className ?? {}}
      aria-label="employee-picker"
    />
  );
};

export default EmployeePicker;
