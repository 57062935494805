import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "./Styles";

const ConfirmBatchResendModal = (props) => {
  const { isModalOpen, hideModal, requests, resendAll } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Batch Resend Confirmation</div>
      <div className={modalBodyPadding}>
        <div>
          <h6>
            <b>
              You are about to trigger Teleport Object Resend(s). This action
              will be logged. Continue?
            </b>
          </h6>
        </div>
        <div>
          <b>{`Number of requests: `}</b>
          {requests.length}
        </div>
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => resendAll()}
          className={modalButtons}
          data-testid="confirm-resend-button"
        >
          Confirm
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default ConfirmBatchResendModal;
