import {
  DefaultButton,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
  padTop,
} from "egressFlows/Styles";

const VerifyUpdateModal = (props) => {
  const { isModalOpen, hideModal, updateFlow, notes, setNotes } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Update Egress Flow</div>
      <div className={modalBodyPadding}>
        <TextField
          label="Notes"
          placeholder="(Optional) Any additional notes"
          onChange={(event, value) => setNotes(value)}
          value={notes ?? ""}
          multiline={true}
          className={padTop}
        />
      </div>
      <div className={modalFooter}>
        <PrimaryButton
          onClick={() => updateFlow()}
          className={modalButtons}
          data-testid="update-button"
        >
          Update
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default VerifyUpdateModal;
