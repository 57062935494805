import {
  DefaultButton,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import {
  cancelButton,
  deleteBatchButton,
  modalBodyPadding,
  modalContainer,
  modalFooter,
  modalHeader,
  rejectionInput,
} from "batches/Styles";
import { useState } from "react";

const FileRejectModal = (props) => {
  const { isModalOpen, hideModal, rejectFile, fileName, requestSending } =
    props;

  const [rejection, setRejection] = useState("");

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Reject File</div>
      <div className={modalBodyPadding}>
        Please provide a reason for rejecting <b>{fileName}</b>
      </div>
      <TextField
        label="Rejection Reason"
        className={rejectionInput}
        value={rejection}
        onChange={(event, value) => setRejection(value)}
        multiline={true}
      />
      <div className={modalFooter}>
        <PrimaryButton
          disabled={rejection.trim().length < 1 || requestSending}
          onClick={() => rejectFile(fileName, rejection)}
          className={deleteBatchButton}
          data-testid="reject-button"
        >
          {requestSending ? "Rejecting..." : "Reject"}
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default FileRejectModal;
