import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { cancelButton } from "batches/Styles";
import { useState } from "react";

export const DELETE_BATCH_DIALOG_TYPE = "DELETE";
export const SUBMIT_BATCH_DIALOG_TYPE = "SUBMIT";

const ConfirmationDialog = (props) => {
  const { type, show, setShow, primaryFunc } = props;
  const [blocking, setBlocking] = useState(false);

  const deleteContents = {
    type: DialogType.normal,
    title: "Delete Batch",
    subText: "Are you sure you want to delete this batch?",
  };

  const submitContents = {
    type: DialogType.normal,
    title: "Submit Batch",
    subText: "You are about to submit this Batch for approval. Confirm?",
  };

  const diaglogContents = {
    [DELETE_BATCH_DIALOG_TYPE]: deleteContents,
    [SUBMIT_BATCH_DIALOG_TYPE]: submitContents,
  };

  const confirmButtonText = (type) => {
    if (type === DELETE_BATCH_DIALOG_TYPE) {
      return blocking ? "Deleting..." : "Delete";
    }

    if (type === SUBMIT_BATCH_DIALOG_TYPE) {
      return blocking ? "Submitting..." : "Submit";
    }

    return "";
  };

  const primaryButtonClick = () => {
    setBlocking(true);
    primaryFunc();
  };

  return (
    <Dialog
      hidden={!show}
      onDismiss={() => setShow(false)}
      dialogContentProps={diaglogContents[type]}
    >
      <DialogFooter>
        <PrimaryButton
          onClick={() => primaryButtonClick()}
          disabled={blocking}
          text={confirmButtonText(type)}
          data-testid="confirmation-button"
        />
        <DefaultButton
          onClick={() => setShow(false)}
          className={cancelButton}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationDialog;
