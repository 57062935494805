import { deleteFileCommandBar, fileTableDiv } from "../Styles";
import {
  DetailsList,
  CheckboxVisibility,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useState } from "react";
import { CommandBar, CommandBarButton, MessageBarType } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import DropZoneFile from "./Dropzone";

/* Function to convert a full directory path to just the file name */
export const pathToFileName = (path) => {
  return path.split("\\").pop().split("/").pop();
};

/**
 * Sends the HTTP Request to delete a Blob from a Blob Container and returns the promise
 * @param {string} blobUri the URI that includes the SAS token of the Blob in the Azure Storage Blob Container
 */
const deleteBlobFile = (blobUri) => {
  return fetch(blobUri, { method: "DELETE" });
};

/**
 * Generates a URI with the SAS token included to be able to modify Azure Storage Blobs through HTTP Requests.
 * @param {string} containerSAS the URI for the Azure Storage Blob container with the SAS appended as the query parameter
 * @param {string} blobFileName the name of the blob
 */
export const generateBlobFileUri = (containerSAS, blobFileName) => {
  const containerSplit = containerSAS.split("?");

  return `${containerSplit[0]}/${blobFileName}?${containerSplit[1]}`;
};

/**
 * Given a file size in bytes, convert to a more human friendly string
 * @param {int} bytes file size
 */
export const calculateFileSize = (bytes) => {
  const BYTES_PER_KB = 1024;
  const BYTES_PER_MB = BYTES_PER_KB * 1024;
  const BYTES_PER_GB = BYTES_PER_MB * 1024;

  if (Math.floor(bytes / BYTES_PER_KB) === 0) {
    return `${bytes} B`;
  } else if (Math.floor(bytes / BYTES_PER_MB) === 0) {
    return `${Math.floor(bytes / BYTES_PER_KB)} KB`;
  } else if (Math.floor(bytes / BYTES_PER_GB) === 0) {
    return `${Math.floor(bytes / BYTES_PER_MB)} MB`;
  } else {
    return `${Math.floor(bytes / BYTES_PER_GB)} GB`;
  }
};

const BatchFilesPivot = (props) => {
  const { batch, fetchBatch } = props;
  const blobs = batch ? batch.blobs : [];
  const [selectedItems, setSelectedItems] = useState([]);
  const selection = new Selection({
    onSelectionChanged: () => setSelectedItems(selection.getSelection()),
  });
  const dispatch = useDispatch();

  const deleteFiles = () => {
    if (!batch.sas) {
      dispatch(
        setMessage({
          type: MessageBarType.error,
          message: "You cannot edit a Batch that is in a Locked State.",
        })
      );
      dispatch(setShow(true));
      return;
    }

    const deleteCalls = [];

    selectedItems.forEach((item) => {
      deleteCalls.push(
        deleteBlobFile(generateBlobFileUri(batch.sas, item.name))
      );

      const shaFilename = item.name + ".teleportal.sha512";
      const shaFile = batch.allBlobs.find((blob) => {
        return blob.name === shaFilename;
      });

      if (shaFile) {
        deleteCalls.push(
          deleteBlobFile(generateBlobFileUri(batch.sas, shaFilename))
        );
      }
    });

    Promise.allSettled(deleteCalls).then((results) => {
      const failedCount = results.filter(
        (result) => result.status === "rejected"
      ).length;

      if (failedCount === 0) {
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Files deleted successfully!",
          })
        );
        dispatch(setShow(true));
      } else {
        dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Some files failed to delete",
          })
        );
        dispatch(setShow(true));
      }

      fetchBatch();
    });
  };

  const customButton = (props) => {
    return <CommandBarButton {...props} styles={deleteFileCommandBar} />;
  };

  const commandbarItems = [
    {
      key: "deleteSelected",
      text: "Delete Selected",
      iconProps: { iconName: "Delete" },
      onClick: deleteFiles,
      disabled: selectedItems.length < 1,
    },
  ];

  // Column Settings for the Table which lists the files in the Batch
  const columns = [
    {
      key: "column1",
      name: "File Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return <div>{pathToFileName(item.name)}</div>;
      },
    },
    {
      key: "column2",
      name: "Size",
      fieldName: "size",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{calculateFileSize(item.properties.contentLength)}</div>;
      },
    },
    {
      key: "column3",
      name: "Content Type",
      fieldName: "contentType",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{item.properties.contentType}</div>;
      },
    },
  ];

  return (
    <>
      <DropZoneFile sas={batch.sas} fetchBatch={fetchBatch} />
      <div className={fileTableDiv}>
        <CommandBar buttonAs={customButton} items={commandbarItems} />
        <DetailsList
          compact={true}
          items={blobs}
          columns={columns}
          checkboxVisibility={CheckboxVisibility.always}
          selection={selection}
          selectionMode={SelectionMode.multiple}
          isSelectedOnFocus={false}
        />
      </div>
    </>
  );
};

export default BatchFilesPivot;
