import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import {
  adminConnectionAPI,
  adminFlowAPI,
  adminPipelineAPI,
} from "utils/endpoints";
import { setMessage, setShow } from "./messageBarSlice";
import { MessageBarType } from "@fluentui/react";

export const adminGetPipelines = createAsyncThunk(
  "resourceProvider/adminGetPipelines",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${adminPipelineAPI}`, token)
      .then((res) => res.json())
      .then((pipelineList) => pipelineList)
      .catch((response) =>
        response.text().then((text) => {
          getThunkAPI.dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          getThunkAPI.dispatch(setShow(true));
          return Promise.reject();
        })
      );
  }
);

export const adminGetConnections = createAsyncThunk(
  "resourceProvider/adminGetConnections",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${adminConnectionAPI}`, token)
      .then((res) => res.json())
      .then((connectionList) => connectionList)
      .catch((response) =>
        response.text().then((text) => {
          getThunkAPI.dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          getThunkAPI.dispatch(setShow(true));
          return Promise.reject();
        })
      );
  }
);

export const adminGetFlows = createAsyncThunk(
  "resourceProvider/adminGetFlows",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${adminFlowAPI}`, token)
      .then((res) => res.json())
      .then((flowList) => flowList)
      .catch((response) =>
        response.text().then((text) => {
          getThunkAPI.dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          getThunkAPI.dispatch(setShow(true));
          return Promise.reject();
        })
      );
  }
);

export const resourceProviderSlice = createSlice({
  name: "resourceProvider",
  initialState: {
    admin_pipeline_list: [],
    fetching_admin_pipeline_list: null,
    admin_connections_list: [],
    fetching_admin_connection_list: null,
    admin_flows_list: [],
    fetching_admin_flow_list: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminGetPipelines.pending, (state, action) => {
      state.fetching_admin_pipeline_list = "loading";
    });
    builder.addCase(adminGetPipelines.fulfilled, (state, action) => {
      state.admin_pipeline_list = action.payload;
      state.fetching_admin_pipeline_list = "success";
    });
    builder.addCase(adminGetPipelines.rejected, (state, action) => {
      state.fetching_admin_pipeline_list = "failed";
    });
    builder.addCase(adminGetConnections.pending, (state, action) => {
      state.fetching_admin_connection_list = "loading";
    });
    builder.addCase(adminGetConnections.fulfilled, (state, action) => {
      state.admin_connections_list = action.payload;
      state.fetching_admin_connection_list = "success";
    });
    builder.addCase(adminGetConnections.rejected, (state, action) => {
      state.fetching_admin_connection_list = "failed";
    });
    builder.addCase(adminGetFlows.pending, (state, action) => {
      state.fetching_admin_flow_list = "loading";
    });
    builder.addCase(adminGetFlows.fulfilled, (state, action) => {
      state.admin_flows_list = action.payload;
      state.fetching_admin_flow_list = "success";
    });
    builder.addCase(adminGetFlows.rejected, (state, action) => {
      state.fetching_admin_flow_list = "failed";
    });
  },
});

export const { select, set_clouds } = resourceProviderSlice.actions;

export default resourceProviderSlice.reducer;
