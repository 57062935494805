import * as React from "react";
import {
  batchProperties,
  sharedUsersTitle,
  batchPropCard,
  halfWidth,
  flexRow,
  paddingTop,
  batchPropTitleDiv,
  headerButtonPadding,
  batchEditIcons,
  batchEditDisabledIcons,
} from "../Styles";
import { TextField } from "@fluentui/react/lib/TextField";
import { useState } from "react";
import {
  getJustificationErrorText,
  getServiceTreeErrorText,
  validJustification,
  validServiceTreeId,
} from "./batchValidation";
import { Icon, MessageBarType } from "@fluentui/react";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { batchesAPI } from "utils/endpoints";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";

const BatchOverviewPivot = (props) => {
  const { batch, batchName, setBatch } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const [inEditMode, setInEditMode] = useState(false);
  const [serviceTreeId, setServiceTreeId] = useState(batch.serviceTreeId);
  const [justification, setJustification] = useState(batch.justification);

  const updateClicked = () => {
    if (isUpdateAllowed) {
      updateBatch(justification, serviceTreeId);
      setInEditMode(false);
    }
  };

  const cancelUpdate = () => {
    setInEditMode(false);
    setServiceTreeId(batch.serviceTreeId);
    setJustification(batch.justification);
  };

  const isUpdateAllowed = () => {
    return (
      validServiceTreeId(serviceTreeId) && validJustification(justification)
    );
  };

  const updateBatch = async (justification, serviceTreeId) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const requestBody = {
      justification: justification,
      serviceTreeId: serviceTreeId,
    };

    callAuthorizedEndpointWithBody(
      `${batchesAPI}/${batchName.trim()}`,
      token,
      "PATCH",
      requestBody
    )
      .then(() => {
        setBatch({
          ...batch,
          justification: justification,
          serviceTreeId: serviceTreeId,
        });
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Successfully updated Batch",
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: text,
            })
          );
          dispatch(setShow(true));
        })
      );
  };

  const editButton = inEditMode ? (
    <>
      <button
        className={isUpdateAllowed() ? batchEditIcons : batchEditDisabledIcons}
        data-testid="update-button"
        onClick={() => {
          if (isUpdateAllowed()) updateClicked();
        }}
        tabIndex={0}
        aria-label="confirm-edit-batch"
      >
        <Icon iconName="Accept" />
      </button>
      <button
        onClick={() => cancelUpdate()}
        className={`${headerButtonPadding} ${batchEditIcons}`}
        data-testid="cancel-button"
        tabIndex={0}
        aria-label="cancel-edit-batch"
      >
        <Icon iconName="Cancel" />
      </button>
    </>
  ) : (
    <button
      className={batchEditIcons}
      onClick={() => setInEditMode(true)}
      data-testid="edit-button"
      tabIndex={0}
      aria-label="edit-batch-info"
    >
      <Icon iconName="Edit" />
    </button>
  );

  return (
    <div className={batchProperties}>
      <div className={batchPropCard}>
        <div className={sharedUsersTitle}>
          <div className={batchPropTitleDiv}>Properties</div>
          {editButton}
        </div>
        <div className={flexRow}>
          <div className={halfWidth}>
            <TextField
              label="Name"
              readOnly={true}
              disabled={inEditMode}
              defaultValue={batch.name}
              className={paddingTop}
            />
            <TextField
              label="Service Tree ID"
              readOnly={!inEditMode}
              value={serviceTreeId}
              className={paddingTop}
              onChange={(event, value) => setServiceTreeId(value)}
              onGetErrorMessage={(value) => getServiceTreeErrorText(value)}
            />
            <TextField
              label="Justification"
              readOnly={!inEditMode}
              multiline
              value={justification}
              onChange={(event, value) => setJustification(value)}
              onGetErrorMessage={(value) => getJustificationErrorText(value)}
              className={paddingTop}
            />
          </div>
          <div className={halfWidth}>
            <TextField
              label="State"
              readOnly={true}
              disabled={inEditMode}
              value={batch.state}
              className={paddingTop}
            />
            <TextField
              label="Cloud"
              readOnly={true}
              disabled={inEditMode}
              defaultValue={batch.cloud.toUpperCase()}
              className={paddingTop}
            />
            {batch.state === "Rejected" && (
              <TextField
                label="Rejection reason"
                multiline
                readOnly={true}
                disabled={inEditMode}
                defaultValue={decodeURIComponent(batch.rejectionReason)}
                className={paddingTop}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchOverviewPivot;
