import { Label } from "@fluentui/react";
import { regionTypeOptions } from "./ConnectionStep";
import { dataTypeOptions } from "./JustificationStep";
import {
  card,
  createFlowCard,
  createLabel,
  createRow,
  createSectionTitle,
  validationLabel,
} from "../Styles";

const ReviewStep = (props) => {
  const {
    title,
    cloud,
    email,
    secondaryContact,
    tenantId,
    subscriptionId,
    resourceGroup,
    storageAccName,
    storageContainerType,
    storageContainerName,
    containerRegion,
    useCase,
    dataTypes,
    cloudVault,
    dataOrigin,
    dataDest,
    dataUsage,
    serviceName,
    serviceTreeId,
    dailyData,
    maxData,
    manualUsage,
    manualUsers,
  } = props;

  const manualUsageContent = () => {
    if (manualUsage === "No") {
      return (
        <div className={createRow}>
          <div className={createLabel}>Manual Usage</div>
          <Label className={validationLabel}>{manualUsage}</Label>
        </div>
      );
    }

    return (
      <>
        <div className={createRow}>
          <div className={createLabel}>Manual Usage</div>
          <Label className={validationLabel}>{manualUsage}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Manual Users List</div>
          <Label className={validationLabel}>
            {manualUsers.map((contact) => contact.text).join(", ")}
          </Label>
        </div>
      </>
    );
  };

  return (
    <div className={createFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          <Label className={validationLabel}>{title}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{cloud}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Additional Contacts</div>
          <Label className={validationLabel}>
            {secondaryContact.map((contact) => contact.text).join(", ")}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        <div className={createRow}>
          <div className={createLabel}>Tenant ID</div>
          <Label className={validationLabel}>{tenantId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          <Label className={validationLabel}>{subscriptionId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          <Label className={validationLabel}>{resourceGroup}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{storageAccName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {
              regionTypeOptions.find((item) => item.key === containerRegion)
                ?.text
            }
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Use Case</div>
          <Label className={validationLabel}>{useCase}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Data Types</div>
          <Label className={validationLabel}>
            {dataTypes
              .map(
                (item) =>
                  dataTypeOptions.find((option) => option.key === item)?.text
              )
              ?.join(", ")}
          </Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Content from CloudVault</div>
          <Label className={validationLabel}>{cloudVault}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Origin of Data</div>
          <Label className={validationLabel}>{dataOrigin}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination of Data</div>
          <Label className={validationLabel}>{dataDest}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>How Will Data be Used</div>
          <Label className={validationLabel}>{dataUsage}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          <Label className={validationLabel}>{serviceName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          <Label className={validationLabel}>{serviceTreeId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Average Data in a Day</div>
          <Label className={validationLabel}>{`${dailyData} GB`}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Max Data in a Day</div>
          <Label className={validationLabel}>{`${maxData} GB`}</Label>
        </div>
        {manualUsageContent()}
      </div>
    </div>
  );
};

export default ReviewStep;
