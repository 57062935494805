import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import PaginationTable from "paginationTable/Table";
import { Link } from "react-router-dom";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "pipelines/Styles";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetPipelines } from "store/resourceProviderSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import {
  getResourceGroup,
  getSubscriptionId,
} from "utils/resourceProviderUtils";

const Pipelines = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  const pipelines = useSelector(
    (state) => state.resourceProvider.admin_pipeline_list
  );
  const fetchingPipelines = useSelector(
    (state) => state.resourceProvider.fetching_admin_pipeline_list
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "name",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item) => {
        return (
          <Link
            className={linkSpan}
            to={`/pipeline/${getSubscriptionId(item.id)}/${getResourceGroup(
              item.id
            )}/${item.name}`}
          >
            {item.name}
          </Link>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Subscription",
      onRender: (item) => {
        return <div>{getSubscriptionId(item.id)}</div>;
      },
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Resource Group",
      onRender: (item) => {
        return <div>{getResourceGroup(item.id)}</div>;
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Location",
      fieldName: "location",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "# Connections",
      onRender: (item) => {
        return <div>{item.properties.connections?.length ?? 0}</div>;
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of Pipeline List
   */
  const getPipelines = useCallback(async () => {
    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(adminGetPipelines(accessToken));
  }, [account, instance, dispatch]);

  /**
   * On start, retrieve pipeline list
   */
  useEffect(() => {
    getPipelines();
  }, [getPipelines]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>All Pipelines</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getPipelines()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={pipelines}
        columns={columns}
        tableStyling={tableStyling}
        loading={fetchingPipelines === "loading" || selectedCloud === null}
      />
    </div>
  );
};

export default Pipelines;
