import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  DetailsRow,
  Dropdown,
  getTheme,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAdminFlows } from "store/ingressFlowSlice";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useBoolean } from "@fluentui/react-hooks";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  statusDropdown,
  tableStyling,
  testSasButton,
  titleFont,
} from "ingressFlows/Styles";
import { useState } from "react";
import PaginationTable from "paginationTable/Table";
import { adminIngressAPI } from "utils/endpoints";
import { setMessage, setShow } from "store/messageBarSlice";
import JustificationModal from "./JustificationModal";
import { Link } from "utils/Link";

export const MODAL_FLOW_DISABLE = "MODAL_FLOW_DISABLE";
export const MODAL_FLOW_ENABLE = "MODAL_FLOW_ENABLE";

const theme = getTheme();

export const AdminIngressFlows = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [modalType, setModalType] = useState(null);
  const [modalFlow, setModalFlow] = useState(null);
  const [checking, setChecking] = useState(false);

  // grab global states from redux store
  const flows = useSelector((state) => state.ingressFlows.admin_list);
  const getFlowsStatus = useSelector(
    (state) => state.ingressFlows.admin_list_status
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const checkAccess = async (flow) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setChecking(true);
    dispatch(
      setMessage({
        type: MessageBarType.info,
        message: `Checking access for ${flow.dataflowGuid}`,
      })
    );
    dispatch(setShow(true));

    callAuthorizedEndpoint(
      `${adminIngressAPI}/${flow.dataflowGuid}/checkaccess?cloud=${flow.cloud}${
        flow.cluster ? `&&cluster=${flow.cluster}` : ""
      }`,
      token
    )
      .then((res) =>
        res.json().then((response) => {
          if (response.valid) {
            dispatch(
              setMessage({
                type: MessageBarType.success,
                message: `Validation succeeded for ${flow.dataflowGuid}`,
              })
            );
          } else {
            dispatch(
              setMessage({
                type: MessageBarType.warning,
                message: `Validation failed for ${flow.dataflowGuid} - ${response.errorInfo}`,
              })
            );
          }
          dispatch(setShow(true));
        })
      )
      .catch((res) =>
        res.text().then((error) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Backend Error During Validation - ${error}`,
            })
          );
          dispatch(setShow(true));
        })
      )
      .finally(() => setChecking(false));
  };

  const getFlowStatusText = (flow) => {
    if (flow.isDeleted) {
      return "Deleted by User";
    }

    if (!flow.finalApprover && !flow.aupForm) {
      return "Approval Required";
    }

    if (!flow.onboarded) {
      return "Onboarding in process";
    }

    const options = [
      { key: "Enabled", text: "Enabled" },
      { key: "Disabled", text: "Disabled" },
    ];

    const selectedKey = flow.enabled ? "Enabled" : "Disabled";

    const toggleChange = (toggleKey) => {
      if (toggleKey === selectedKey) return;

      if (toggleKey === "Enabled") {
        setModalType(MODAL_FLOW_ENABLE);
      } else {
        setModalType(MODAL_FLOW_DISABLE);
      }
      setModalFlow(flow);
      showModal();
    };

    return (
      <Dropdown
        selectedKey={selectedKey}
        onChange={(event, option, index) => toggleChange(option.key)}
        options={options}
        className={statusDropdown}
      />
    );
  };

  const tableRowRender = (props) => {
    const customStyles = {};
    if (props) {
      if (props.item.isDeleted) {
        customStyles.root = {
          backgroundColor: theme.palette.neutralTertiary,
        };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const columns = [
    {
      key: "column1",
      name: "Dataflow GUID",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <Link className={linkSpan} to={`/ingress/${item.dataflowGuid}`}>
            {item.dataflowGuid}
          </Link>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Flow Status",
      fieldName: "state",
      onRender: (item) => {
        return <div>{getFlowStatusText(item)}</div>;
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Check Access",
      onRender: (item) => {
        return (
          <div className={testSasButton}>
            {item.onboarded ? (
              <PrimaryButton
                onClick={() => checkAccess(item)}
                text="Test"
                disabled={checking}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Title",
      fieldName: "title",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Owner",
      fieldName: "primaryContact",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Storage Account Name",
      fieldName: "storageAccountName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column7",
      name: "Storage Container Name",
      fieldName: "storageContainerName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/ingress/${item.dataflowGuid}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of the list of Ingress Flows
   */
  const getFlowList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(
      getAdminFlows({ token: accessToken, cloud: selectedCloud.project })
    );
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve list of flows or when cloud mode got toggled
   */
  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>All Ingress Flows</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getFlowList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={getFlowsStatus === "loading" || selectedCloud === null}
        onRenderFunc={tableRowRender}
        onItemInvoked={onItemInvoked}
      />
      {isModalOpen && (
        <JustificationModal
          {...{ isModalOpen, hideModal, modalType, modalFlow }}
        />
      )}
    </div>
  );
};
export default AdminIngressFlows;
