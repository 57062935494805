import { ActivityItem, Icon, Link } from "@fluentui/react";
import { activityItemDiv, nameText } from "./Styles";

const INGRESS_FLOW_CREATE_EVENT = "INGRESS_FLOW_CREATED";
const INGRESS_FLOW_UPDATED_EVENT = "INGRESS_FLOW_UPDATED";
const INGRESS_FLOW_DELETE_EVENT = "INGRESS_FLOW_DELETED";
const INGRESS_FLOW_ADMIN_CONVERT_ACCESS = "INGRESS_FLOW_ADMIN_CONVERT_ACCESS";
const INGRESS_FLOW_ADMIN_DELETE = "INGRESS_FLOW_ADMIN_DELETE";
const INGRESS_FLOW_ADMIN_DISABLED = "INGRESS_FLOW_ADMIN_DISABLED";
const INGRESS_FLOW_ADMIN_ENABLED = "INGRESS_FLOW_ADMIN_ENABLED";
const INGRESS_FLOW_ADMIN_UPDATED = "INGRESS_FLOW_ADMIN_UPDATED";
const INGRESS_FLOW_APPROVED = "INGRESS_FLOW_APPROVED";
const INGRESS_FLOW_APPROVAL_UPDATE = "INGRESS_FLOW_APPROVAL_UPDATE";

export const INGRESS_EVENT_TO_ICON_MAPPING = {
  [INGRESS_FLOW_CREATE_EVENT]: "CloudFlow",
  [INGRESS_FLOW_UPDATED_EVENT]: "Edit",
  [INGRESS_FLOW_ADMIN_CONVERT_ACCESS]: "Switch",
  [INGRESS_FLOW_ADMIN_DELETE]: "Delete",
  [INGRESS_FLOW_ADMIN_DISABLED]: "ToggleLeft",
  [INGRESS_FLOW_ADMIN_ENABLED]: "ToggleRight",
  [INGRESS_FLOW_ADMIN_UPDATED]: "Edit",
  [INGRESS_FLOW_APPROVED]: "CheckMark",
  [INGRESS_FLOW_DELETE_EVENT]: "Delete",
  [INGRESS_FLOW_APPROVAL_UPDATE]: "Edit",
};

export const convertDateToLocaleString = (date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const generateActivityDescription = (event, currIndex, showMoreInfo) => {
  if (event.eventName === INGRESS_FLOW_CREATE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;created Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_UPDATED_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_CONVERT_ACCESS) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;convert access method for Ingress Flow&nbsp;</span>
        <Link
          id={`info${currIndex}`}
          key={3}
          onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
        >
          (see more details)
        </Link>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_DELETE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;hard deleted Ingress Flow&nbsp;</span>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_DISABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;disabled Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_ENABLED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;enabled Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_ADMIN_UPDATED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;edited Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.diffInfo)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_APPROVED) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;approved Ingress Flow</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_DELETE_EVENT) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;soft-deleted Ingress Flow</span>
      </div>
    );
  } else if (event.eventName === INGRESS_FLOW_APPROVAL_UPDATE) {
    return (
      <div>
        <span key={1} className={nameText}>
          {event.user}
        </span>
        <span key={2}>&nbsp;updated Ingress Flow in approval state</span>
        {event.isAdminEvent && (
          <Link
            id={`info${currIndex}`}
            key={3}
            onClick={() => showMoreInfo(`info${currIndex}`, event.eventDetails)}
          >
            &nbsp;(see more details)
          </Link>
        )}
      </div>
    );
  }
};

export const generateActivityItems = (events, showMoreInfo) => {
  return events?.map((event, currIndex) => (
    <div key={currIndex}>
      <ActivityItem
        activityDescription={generateActivityDescription(
          event,
          currIndex,
          showMoreInfo
        )}
        activityIcon={
          <Icon iconName={INGRESS_EVENT_TO_ICON_MAPPING[event.eventName]} />
        }
        timeStamp={convertDateToLocaleString(event.creationDate)}
        className={currIndex !== 0 ? activityItemDiv : null}
      />
    </div>
  ));
};

const getDiffInfo = (currState, newObj) => {
  const diffInfo = {};

  Object.keys(newObj).forEach((key) => {
    if (
      currState.hasOwnProperty(key) &&
      newObj[key] !== null &&
      currState[key] !== newObj[key]
    ) {
      diffInfo[key] = [currState[key], newObj[key]];
      currState[key] = newObj[key];
    }
  });

  return diffInfo;
};

// Function to remove the duplciate customer's non-admin event from the final list
export const filterAdminList = (events) => {
  const result = [];

  for (let i = 0; i < events.length; i++) {
    if (i < events.length) {
      result.push(events[i]);
    }

    if (i + 1 >= events.length) {
      break;
    }

    if (
      events[i].eventName === events[i + 1].eventName &&
      events[i].isAdminEvent &&
      !events[i + 1].isAdminEvent
    ) {
      i++;
    }
  }

  return result;
};

export const generateInfoList = (events) => {
  const infoList = new Array(events.length);

  let currState = {
    AvgDailyData: "",
    Cloud: "",
    CloudVault: "",
    DataTypes: "",
    DataUse: "",
    DataflowGuid: "",
    Justification: "",
    Destination: "",
    Email: "",
    ManualUsage: "",
    ManualUserList: null,
    MaxData: "",
    Origin: "",
    PrimaryContact: "",
    ResourceGroup: "",
    SecondaryContact: "",
    ServiceName: "",
    StorageAccountName: "",
    StorageContainerName: "",
    StorageContainerRegion: "",
    StorageContainerType: "",
    StorageTenantId: "",
    ServiceTreeId: "",
    SubscriptionId: "",
    Title: "",
    UseCase: "",
  };

  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i];

    if (
      event.eventName === INGRESS_FLOW_CREATE_EVENT ||
      event.eventName === INGRESS_FLOW_UPDATED_EVENT ||
      event.eventName === INGRESS_FLOW_ADMIN_UPDATED
    ) {
      event.diffInfo = getDiffInfo(currState, event.eventDetails);
    }

    infoList[i] = event;
  }

  return events;
};

export const generateDiffCallout = (diffObj) => {
  return (
    <div>
      <div>{`{`}</div>
      {Object.keys(diffObj).map((key) => {
        if (Array.isArray(diffObj[key]) && diffObj[key].length === 2) {
          return (
            <div
              key={key}
            >{`${key}: ${diffObj[key][0]} -> ${diffObj[key][1]}`}</div>
          );
        } else {
          return <div key={key}>{`${key}: ${diffObj[key]}`}</div>;
        }
      })}
      <div>{`}`}</div>
    </div>
  );
};
