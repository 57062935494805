import { pathToFileName } from "./customer/BatchFilesPivot";
import streamSaver from "libs/streamsaver/StreamSaver.js";
import JSZip from "jszip";
import { MessageBarType } from "@fluentui/react";

const downloadStreamHelper = (readableStream, fileStream) => {
  const abortDownload = () => {
    fileStream.abort();
    window.writer.abort();
  };

  window.addEventListener("beforeunload", abortDownload);

  // check if Streams API is supported
  if (window.WritableStream && readableStream.pipeTo) {
    return readableStream.pipeTo(fileStream).then(() => {
      window.removeEventListener("beforeunload", abortDownload);
    });
  }

  // Write (pipe) manually
  const writer = fileStream.getWriter();

  const reader = readableStream.getReader();
  const pump = () =>
    reader.read().then((res) => {
      if (res.done) {
        writer.close();
        window.removeEventListener("beforeunload", abortDownload);
      } else {
        writer.write(res.value).then(pump);
      }
    });

  pump();
};

const downloadFile = (url, fileName) => {
  const fileStream = streamSaver.createWriteStream(fileName);

  fetch(url).then((res) => {
    const readableStream = res.body;
    downloadStreamHelper(readableStream, fileStream);
  });
};

export const downloadToFiles = (SASresponses) => {
  SASresponses.forEach((response) => {
    const { sas, fileName } = response;
    const blob = pathToFileName(fileName);
    downloadFile(sas, blob);
  });
};

const addToZipWithSAS = (zip, fileName, sas, request) => {
  return new Promise((resolve, reject) => {
    request.addEventListener("readystatechange", (e) => {
      if (request.readyState === 2 && request.status === 200) {
        // Download is being started
      } else if (request.readyState === 3) {
        // Download is under progress
      } else if (request.readyState === 4) {
        // Downloading has finished
        zip.file(fileName, request.response, { binary: true });
        resolve();
        // request.response holds the file data
      }
    });

    request.responseType = "blob";
    request.open("get", sas);
    request.send();
  });
};

const downloadBlob = (blob, filename) => {
  const fileStream = streamSaver.createWriteStream(filename, {
    size: blob.size, // Makes the percentage visible in the download
  });

  const readableStream = blob.stream();
  downloadStreamHelper(readableStream, fileStream);
};

const calculateTotalProgress = (progressMap, fileName, loaded, total) => {
  if (!progressMap.hasOwnProperty(fileName)) {
    progressMap[fileName] = { progress: loaded, total: total };
  } else {
    progressMap[fileName].progress = loaded;
  }

  let totalProgress = 0;
  let totalWork = 0;

  Object.keys(progressMap).forEach((key) => {
    const { progress, total } = progressMap[key];
    totalWork += total;
    totalProgress += progress;
  });

  return Math.floor((totalProgress / totalWork) * 100);
};

export const downloadToZip = (SASresponses, batchName, dispatchFunc) => {
  var zip = new JSZip();
  var downloadPromises = [];
  const totalProgressMap = {};

  SASresponses.forEach((response) => {
    const { sas, fileName } = response;
    const blob = pathToFileName(fileName);
    const request = new XMLHttpRequest();

    request.addEventListener("progress", (e) => {
      const progress = calculateTotalProgress(
        totalProgressMap,
        fileName,
        e.loaded,
        e.total
      );
      if (dispatchFunc) {
        dispatchFunc(
          MessageBarType.warning,
          `Generating ${batchName}.zip - ${progress}/100% done.`
        );
      }
    });

    downloadPromises.push(addToZipWithSAS(zip, blob, sas, request));
  });

  Promise.all(downloadPromises).then((responses) => {
    // Generate then download the Zip
    zip
      .generateAsync({ type: "blob" }, (metadata) => {})
      .then((content) => {
        if (dispatchFunc) {
          dispatchFunc(
            MessageBarType.success,
            `${batchName}.zip successfully generated, downloading now.`
          );
        }
        downloadBlob(content, `${batchName}.zip`);
      });
  });
};
