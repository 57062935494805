export const validName = (pasteName) => pasteName.match(/^[a-zA-Z0-9-]{4,40}$/);

export const getNameErrorText = (pasteName) => {
  return validName(pasteName)
    ? ""
    : "Only alphanumeric and hyphen characters allowed (4-40 characters)";
};

export const validContent = (pasteContent) =>
  pasteContent.length > 0 && pasteContent.length <= 25000;

export const validSenderAlias = (pasteSenderAlias) =>
  pasteSenderAlias.match(/^[a-zA-Z]{3,40}$/);

export const validPasteLanguage = (pasteLanguage) =>
  pasteLanguage.length > 0 && pasteLanguage.Length <= 100;

export const validPasteDestination = (pasteDestination) => pasteDestination;
