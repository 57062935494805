import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import PaginationTable from "paginationTable/Table";
import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFlows } from "store/egressFlowSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "../Styles";
import { Link } from "utils/Link";

export const flowStates = {
  SecurityReview: "Awaiting Verification",
  Verified: "Awaiting Final Approval",
  Approved: "Approved",
};

export const EgressFlows = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const flows = useSelector((state) => state.egressFlows.list);
  const getFlowsStatus = useSelector((state) => state.egressFlows.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getFlowStatusText = (flow) => {
    if (flowStates[flow.egressStatus] === flowStates.SecurityReview) {
      return "Initial Approval Required";
    }

    if (flowStates[flow.egressStatus] === flowStates.Verified) {
      return "Final Approval Required";
    }

    return flow.enabled ? "Enabled" : "Disabled";
  };

  const columns = [
    {
      key: "column1",
      name: "Dataflow GUID",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <Link className={linkSpan} to={`/egress/${item.dataflowGuid}`}>
            {item.dataflowGuid}
          </Link>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Flow Status",
      fieldName: "state",
      onRender: (item) => {
        return <div>{getFlowStatusText(item)}</div>;
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Title",
      fieldName: "title",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Owner",
      fieldName: "primaryContact",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Storage Account Name",
      fieldName: "storageAccountName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Storage Container Name",
      fieldName: "storageContainerName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/egress/${item.dataflowGuid}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of the list of Egres Flows
   */
  const getFlowList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getFlows({ token: accessToken, cloud: selectedCloud.project }));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve list of flows or when cloud mode got toggled
   */
  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>My Egress Flows</span>
        <PrimaryButton
          text="New egress request"
          onClick={() => navigate("/CreateEgress")}
        />
        <DefaultButton
          text="Refresh"
          onClick={() => getFlowList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={getFlowsStatus === "loading" || selectedCloud === null}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
};
export default EgressFlows;
