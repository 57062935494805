import {
  CheckboxVisibility,
  DefaultButton,
  DetailsRow,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
} from "@fluentui/react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { tableButtons, tableDiv, tableHeader } from "./Styles";

const MAX_PAGE_SIZE = 10;

const PaginationTable = (props) => {
  const { items, columns, tableStyling, loading, onRenderFunc, onItemInvoked } =
    props;
  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchText, setSearchText] = useState("");

  const maxPages = Math.ceil(filteredItems.length / 10);
  const paginatedItems = [];
  for (let i = 0; i < MAX_PAGE_SIZE; i++) {
    const index = page * MAX_PAGE_SIZE + i;
    if (index >= filteredItems.length) break;

    paginatedItems.push(filteredItems[index]);
  }

  const changeSearch = useCallback(() => {
    setPage(0);

    if (searchText.trim().length === 0) {
      setFilteredItems(items);
      return;
    }

    const filteredArr = [];
    items.forEach((item) => {
      if (JSON.stringify(item).toLowerCase().includes(searchText)) {
        filteredArr.push(item);
      }
    });
    setFilteredItems(filteredArr);
  }, [items, searchText]);

  const wordWrapColumns = columns.map((column) => {
    return { ...column, isMultiline: true };
  });

  const defaultRender = (props) => {
    return props ? <DetailsRow {...props} /> : null;
  };

  useEffect(() => {
    setFilteredItems(items);

    changeSearch();
  }, [items, changeSearch]);

  return (
    <div className={tableDiv}>
      <div className={tableHeader}>
        {!loading &&
          `Showing ${
            filteredItems.length > 0 ? page * MAX_PAGE_SIZE + 1 : 0
          } to ${
            filteredItems.length > 0
              ? Math.min(page * MAX_PAGE_SIZE + 10, filteredItems.length)
              : 0
          } of ${filteredItems.length} items`}
        <DefaultButton
          text="Skip to first"
          onClick={() => setPage(0)}
          disabled={loading || page === 0}
          className={tableButtons}
        />
        <DefaultButton
          text="Previous"
          onClick={() => setPage(page - 1)}
          disabled={loading || page === 0}
          className={tableButtons}
        />
        <DefaultButton
          text="Next"
          onClick={() => setPage(page + 1)}
          disabled={loading || page === maxPages - 1 || maxPages === 0}
          className={tableButtons}
        />
        <DefaultButton
          text="Skip to last"
          onClick={() => setPage(maxPages - 1)}
          disabled={loading || page === maxPages - 1 || maxPages === 0}
          className={tableButtons}
        />
        <TextField
          onChange={(event, newValue) => setSearchText(newValue.toLowerCase())}
          className={tableButtons}
          placeholder="Search here"
          data-testid="searchBar"
        />
      </div>
      <ShimmeredDetailsList
        items={paginatedItems}
        columns={wordWrapColumns}
        detailsListStyles={{ root: tableStyling }}
        checkboxVisibility={CheckboxVisibility.hidden}
        onRenderRow={onRenderFunc ? onRenderFunc : defaultRender}
        enableShimmer={loading}
        selectionMode={SelectionMode.none}
        onItemInvoked={onItemInvoked ? onItemInvoked : null}
      />
    </div>
  );
};

export default PaginationTable;
