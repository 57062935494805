import * as React from "react";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useCallback } from "react";
import { protectedResources } from "authConfig";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { getFileReviewBatches } from "store/batchSlice";
import PaginationTable from "paginationTable/Table";
import { Link } from "utils/Link";

export const BatchFileReviews = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const batches = useSelector((state) => state.batch.file_reviews).map(
    (batch) => {
      return {
        ...batch,
        dateModified: new Date(batch.lastModified).toLocaleDateString(),
      };
    }
  );
  const getBatchesStatus = useSelector(
    (state) => state.batch.file_reviews_status
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the Batch Table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "name",
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item) => {
        return (
          <Link className={linkSpan} to={`/batchreview/${item.name}`}>
            {item.name}
          </Link>
        );
      },
    },
    {
      key: "column2",
      name: "Date Modified",
      fieldName: "dateModified",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/batchreview/${item.name}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of Batch List
   */
  const getBatches = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(
      getFileReviewBatches({ token: accessToken, cloud: selectedCloud.project })
    );
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve batch list
   */
  useEffect(() => {
    getBatches();
  }, [getBatches]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Batch Files That Need Reviewing</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getBatches()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={batches}
        columns={columns}
        tableStyling={tableStyling}
        loading={getBatchesStatus === "loading" || selectedCloud === null}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
};

export default BatchFileReviews;
