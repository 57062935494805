import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton, MessageBarType, PrimaryButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getVerifyFlows } from "store/egressFlowSlice";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  testSasButton,
  titleFont,
} from "egressFlows/Styles";
import PaginationTable from "paginationTable/Table";
import { useState } from "react";
import { setMessage, setShow } from "store/messageBarSlice";
import { adminEgressAPI } from "utils/endpoints";

export const VerifyFlows = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [checking, setChecking] = useState(false);

  // grab global states from redux store
  const flows = useSelector((state) => state.egressFlows.verify_list);
  const getFlowsStatus = useSelector(
    (state) => state.egressFlows.verify_list_status
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const checkAccess = async (flow) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setChecking(true);
    dispatch(
      setMessage({
        type: MessageBarType.info,
        message: `Checking access for ${flow.dataflowGuid}`,
      })
    );
    dispatch(setShow(true));

    callAuthorizedEndpoint(
      `${adminEgressAPI}/${flow.dataflowGuid}/checkcreateaccess?cloud=${flow.cloud}`,
      token
    )
      .then((res) =>
        res.json().then((response) => {
          if (response.valid) {
            dispatch(
              setMessage({
                type: MessageBarType.success,
                message: `Validation succeeded for ${flow.dataflowGuid}`,
              })
            );
          } else {
            dispatch(
              setMessage({
                type: MessageBarType.warning,
                message: `Validation failed for ${flow.dataflowGuid} - ${response.errorInfo}`,
              })
            );
          }
          dispatch(setShow(true));
        })
      )
      .catch((res) =>
        res.text().then((error) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Backend Error During Validation - ${error}`,
            })
          );
          dispatch(setShow(true));
        })
      )
      .finally(() => setChecking(false));
  };

  const columns = [
    {
      key: "column1",
      name: "Dataflow GUID",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/VerifyEgress/${item.dataflowGuid}`)}
          >
            {item.dataflowGuid}
          </div>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Check Access",
      onRender: (item) => {
        return (
          <div className={testSasButton}>
            <PrimaryButton
              onClick={() => checkAccess(item)}
              text="Test"
              disabled={checking}
            />
          </div>
        );
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Title",
      fieldName: "title",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Owner",
      fieldName: "primaryContact",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Storage Account Name",
      fieldName: "storageAccountName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Storage Container Name",
      fieldName: "storageContainerName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  // Function called on Accessibility-enter-key input
  const onItemInvoked = (item) => {
    navigate(`/VerifyEgress/${item.dataflowGuid}`);
  };

  /**
   * Function to dispatch the redux action to retrieve the fetching of the list of Ingress Flows
   */
  const getFlowList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(
      getVerifyFlows({ token: accessToken, cloud: selectedCloud.project })
    );
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve list of flows or when cloud mode got toggled
   */
  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Flows that need Initial Review</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getFlowList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={getFlowsStatus === "loading" || selectedCloud === null}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
};
export default VerifyFlows;
