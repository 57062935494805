import * as React from "react";
import {
  baseDiv,
  headerDiv,
  batchTitleFont,
  errorTextStyle,
  headerButtonPadding,
  deleteBatchButton,
  batchDetailsPivot,
  batchPropertiesDiv,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { protectedResources } from "../../authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminBatchesAPI, batchApproverAupAPI } from "utils/endpoints";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import {
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
  Pivot,
  PivotItem,
  TooltipHost,
} from "@fluentui/react";
import BatchOverviewPivot from "./BatchOverviewPivot";
import BatchFilesPivot from "./BatchFilesPivot";
import { useCallback } from "react";
import SharedUserList from "../SharedUserList";
import LoadingView from "../LoadingView";
import { useDispatch, useSelector } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import ApproverAupModal from "./ApproverAupModal";
import RejectModal from "./RejectModal";
import { downloadToFiles, downloadToZip } from "batches/batchDownloader";
import HistoricalTimeline from "batches/HistoricalTimeline";

const forceApproveDialogProps = {
  type: DialogType.normal,
  title: "Force Approval",
  closeButtonAriaLabel: "Close",
  subText:
    "Are you sure you want to approve a Batch with one or more unverified files?",
};

export const AdminBatchDetails = () => {
  const { batchName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [
    isApproveModalOpen,
    { setTrue: showApproveModal, setFalse: hideApproveModal },
  ] = useBoolean(false);
  const [
    isRejectModalOpen,
    { setTrue: showRejectModal, setFalse: hideRejectModal },
  ] = useBoolean(false);
  const [aup, setAup] = useState("");
  const [rejection, setRejection] = useState("");
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const roles = useSelector((state) => state.mode.roles);
  const [historyEvents, setHistoryEvents] = useState(null);

  const approveDisabled = batch?.blobs?.find(
    (blob) => blob.verificationStatus !== "VERIFIED"
  );

  const dispatchFunc = (type, text) => {
    dispatch(
      setMessage({
        type: type,
        message: text,
      })
    );
    dispatch(setShow(true));
  };

  const getFileSAS = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const sasPromises = batch.blobs.map((blob) => {
      const fileName = encodeURIComponent(blob.name);
      return callAuthorizedEndpoint(
        `${adminBatchesAPI}/${batchName.trim()}/${fileName}`,
        token
      );
    });

    return Promise.all(sasPromises).then((responses) =>
      Promise.all(responses.map((r) => r.json()))
    );
  };

  const downloadFiles = async () => {
    const filesArr = await getFileSAS();
    downloadToFiles(filesArr);
  };

  const downloadZip = async () => {
    const filesArr = await getFileSAS();
    downloadToZip(filesArr, batchName, dispatchFunc);
  };

  const downloadMenuProps = {
    items: [
      {
        key: "downloadFiles",
        text: "Download Files",
        onClick: (ev, props) => downloadFiles(),
      },
      {
        key: "downloadZip",
        text: "Download as Zip",
        onClick: (ev, props) => downloadZip(),
      },
    ],
    directionalHintFixed: true,
  };

  const approveBatch = async () => {
    hideApproveModal();
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${adminBatchesAPI}/${batchName.trim()}/approve`,
      token,
      "POST",
      {}
    )
      .then((response) => {
        navigate("/batches");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  };

  const rejectBatch = async () => {
    hideRejectModal();
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpointWithBody(
      `${adminBatchesAPI}/${batchName.trim()}/reject`,
      token,
      "POST",
      { Rejection: encodeURIComponent(rejection.trim()) }
    )
      .then((response) => {
        navigate("/batches");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  };

  const openAupModal = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    callAuthorizedEndpoint(
      `${batchApproverAupAPI}?cloud=${batch.cloud.toLowerCase()}`,
      token
    )
      .then((response) => response.text())
      .then((text) => {
        setAup(text);
        showApproveModal();
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      );
  };

  const openRejectionModal = () => {
    setRejection("");
    showRejectModal();
  };

  const fetchBatch = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${adminBatchesAPI}/${batchName.trim()}`, token)
      .then((response) => response.json())
      .then((batchObj) => {
        batchObj.blobs = batchObj.blobs
          ? batchObj.blobs.filter(
              (blob) => !blob.name.endsWith(".teleportal.sha512")
            )
          : [];
        setBatch(batchObj);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        })
      );
  }, [account, batchName, instance]);

  useEffect(() => {
    fetchBatch();
  }, [fetchBatch]);

  const batchView = batch ? (
    <>
      <div className={headerDiv}>
        <span className={batchTitleFont}>{batch.name}</span>
        {approveDisabled ? (
          roles.approver ? (
            <PrimaryButton
              text="Force Approve Batch"
              onClick={toggleHideDialog}
            />
          ) : (
            <TooltipHost content="You may not force approve batches.">
              <PrimaryButton text="Approve Batch" disabled={true} />
            </TooltipHost>
          )
        ) : (
          <PrimaryButton text="Approve Batch" onClick={() => openAupModal()} />
        )}
        <DefaultButton
          text="Download Batch"
          menuProps={downloadMenuProps}
          className={headerButtonPadding}
        />
        <PrimaryButton
          text="Reject Batch"
          onClick={() => openRejectionModal()}
          className={deleteBatchButton}
        />
      </div>
      <div className={batchDetailsPivot}>
        <Pivot>
          <PivotItem headerText="Overview">
            <div className={batchPropertiesDiv}>
              <BatchOverviewPivot batch={batch} batchName={batchName} />
              <SharedUserList batchName={batchName} batchObj={batch} />
            </div>
            <BatchFilesPivot batch={batch} fetchBatch={fetchBatch} />
          </PivotItem>
          <PivotItem headerText="History">
            <HistoricalTimeline
              adminMode={true}
              batchName={batchName.trim()}
              events={historyEvents}
              setEvents={setHistoryEvents}
            />
          </PivotItem>
        </Pivot>
      </div>
      <ApproverAupModal
        isModalOpen={isApproveModalOpen}
        hideModal={hideApproveModal}
        aup={aup}
        approveBatch={approveBatch}
      />
      <RejectModal
        isModalOpen={isRejectModalOpen}
        hideModal={hideRejectModal}
        rejection={rejection}
        setRejection={setRejection}
        rejectBatch={rejectBatch}
      />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={forceApproveDialogProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              toggleHideDialog();
              openAupModal();
            }}
            text="Confirm"
          />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : batchView}</div>;
};

export default AdminBatchDetails;
