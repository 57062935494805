import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  MessageBarType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import { getAliasFromUserPrincipalName } from "batches/SharedUserList";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { ingressFlowAPI, ingressFlowAupAPI } from "utils/endpoints";
import AupStep from "./AupStep";
import BasicsStep from "./BasicsStep";
import ConnectionStep, {
  containerTypeOptions,
  regionTypeOptions,
} from "./ConnectionStep";
import {
  validDataDest,
  validDataOrigin,
  validDataTypes,
  validDataUsage,
  validEmail,
  validManualFields,
  validResourceGroup,
  validServiceName,
  validServiceTreeId,
  validStorageAccountName,
  validStorageContainerName,
  validSubscriptionId,
  validTenantId,
  validTitle,
  validUseCase,
} from "ingressFlows/createFlowValidation";
import JustificationStep, {
  cloudVaultOptions,
  manualUsageOptions,
} from "ingressFlows/customer/JustificationStep";
import LoadingView from "ingressFlows/LoadingView";
import ReviewStep from "./ReviewStep";
import {
  baseDiv,
  createFooter,
  footerButtons,
  footerRow,
  headerDiv,
  pivotPadding,
  pivotSelect,
  reviewDiv,
  titleFont,
} from "../Styles";
import { setMessage, setShow } from "store/messageBarSlice";

export const CreateIngressFlows = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isCreating, setIsCreating] = useState(false);
  const [selectedPivot, setSelectedPivot] = useState(0);
  const [locked, setLocked] = useState(false);
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState([]);
  const [tenantId, setTenantId] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [resourceGroup, setResourceGroup] = useState("");
  const [storageAccName, setStorageAccName] = useState("");
  const [storageContainerType, setStorageContainerType] = useState(
    containerTypeOptions[0].key
  );
  const [storageContainerName, setStorageContainerName] = useState("");
  const [containerRegion, setContainerRegion] = useState(
    regionTypeOptions[0].key
  );
  const [sasTest, setSasTest] = useState(false);
  const [useCase, setUseCase] = useState("");
  const [dataTypes, setDataTypes] = useState([]);
  const [cloudVault, setCloudVault] = useState(cloudVaultOptions[1].key);
  const [dataOrigin, setDataOrigin] = useState("");
  const [dataDest, setDataDest] = useState("");
  const [dataUsage, setDataUsage] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceTreeId, setServiceTreeId] = useState("");
  const [dailyData, setDailyData] = useState(1);
  const [maxData, setMaxData] = useState(1);
  const [manualUsage, setManualUsage] = useState(manualUsageOptions[1].key);
  const [manualUsers, setManualUsers] = useState([]);
  const [manualJit, setManualJit] = useState(false);
  const [tented, setTented] = useState(false);
  const [aupForm, setAupForm] = useState("");
  const [fetchingAup, setFetchingAup] = useState(true);
  const [acceptAup, setAcceptAup] = useState(false);

  const selectedCloud = useSelector((state) => state.tentedClouds.selected);
  const destinationField = selectedCloud
    ? selectedCloud.name.toUpperCase()
    : "";

  const getManualPayload = () => {
    const output = {
      ManualUsage: manualUsage,
    };

    if (manualUsage === "Yes") {
      output.ManualUserList = manualUsers
        .map((contact) => getAliasFromUserPrincipalName(contact.upn))
        .join(";");

      output.JITForManualTransfers = "Yes";
      output.ManualUsersTented = "Yes";
    }

    return output;
  };

  const createFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setIsCreating(true);

    const body = {
      AvgDailyData: dailyData,
      Cloud: selectedCloud.project,
      CloudVault: cloudVault,
      DataTypes: dataTypes.join(";"),
      DataUse: dataUsage,
      Destination: dataDest,
      Email: email,
      ...getManualPayload(),
      MaxData: maxData,
      Origin: dataOrigin,
      ResourceGroup: resourceGroup,
      SecondaryContact: secondaryContact
        .map((contact) => getAliasFromUserPrincipalName(contact.upn))
        .join(";"),
      ServiceName: serviceName,
      ServiceTreeId: serviceTreeId,
      StorageAccountName: storageAccName,
      StorageContainerName: storageContainerName,
      StorageContainerRegion: containerRegion,
      StorageContainerType: storageContainerType,
      StorageTenantId: tenantId,
      SubscriptionId: subscriptionId,
      Title: title,
      UseCase: useCase,
    };

    callAuthorizedEndpointWithBody(ingressFlowAPI, token, "POST", body)
      .then((response) => response.json())
      .then((response) => {
        setIsCreating(false);
        navigate("/ingress");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          setIsCreating(false);
        })
      );
  };

  const validBasic = () => {
    return validTitle(title) && selectedCloud && validEmail(email);
  };

  const validConnection = () => {
    return (
      validTenantId(tenantId) &&
      validSubscriptionId(subscriptionId) &&
      validResourceGroup(resourceGroup) &&
      validStorageAccountName(storageAccName) &&
      validStorageContainerName(storageContainerName) &&
      sasTest
    );
  };

  const validJustification = () => {
    return (
      validUseCase(useCase) &&
      validDataTypes(dataTypes) &&
      validDataOrigin(dataOrigin) &&
      validDataDest(dataDest) &&
      validDataUsage(dataUsage) &&
      validServiceName(serviceName) &&
      validServiceTreeId(serviceTreeId) &&
      validManualFields(manualUsage, manualUsers, manualJit, tented)
    );
  };

  const validAup = () => {
    return acceptAup;
  };

  const validCreation = () =>
    validBasic() && validConnection() && validJustification() && validAup();

  const createSteps = [
    {
      title: "Basics",
      key: "basics",
      render: (
        <BasicsStep
          cloud={destinationField}
          title={title}
          setTitle={setTitle}
          email={email}
          setEmail={setEmail}
          secondaryContact={secondaryContact}
          setSecondaryContact={setSecondaryContact}
        />
      ),
      validateFunc: () => validBasic(),
    },
    {
      title: "Connection Info",
      key: "connection",
      render: (
        <ConnectionStep
          setLocked={setLocked}
          tenantId={tenantId}
          setTenantId={setTenantId}
          subscriptionId={subscriptionId}
          setSubscriptionId={setSubscriptionId}
          resourceGroup={resourceGroup}
          setResourceGroup={setResourceGroup}
          storageAccName={storageAccName}
          setStorageAccName={setStorageAccName}
          storageContainerType={storageContainerType}
          setStorageContainerType={setStorageContainerType}
          storageContainerName={storageContainerName}
          setStorageContainerName={setStorageContainerName}
          containerRegion={containerRegion}
          setContainerRegion={setContainerRegion}
          sasTest={sasTest}
          setSasTest={setSasTest}
        />
      ),
      validateFunc: () => validConnection(),
    },
    {
      title: "Business Justification",
      key: "justification",
      render: (
        <JustificationStep
          useCase={useCase}
          setUseCase={setUseCase}
          dataTypes={dataTypes}
          setDataTypes={setDataTypes}
          cloudVault={cloudVault}
          setCloudVault={setCloudVault}
          dataOrigin={dataOrigin}
          setDataOrigin={setDataOrigin}
          dataDest={dataDest}
          setDataDest={setDataDest}
          dataUsage={dataUsage}
          setDataUsage={setDataUsage}
          serviceName={serviceName}
          setServiceName={setServiceName}
          serviceTreeId={serviceTreeId}
          setServiceTreeId={setServiceTreeId}
          dailyData={dailyData}
          setDailyData={setDailyData}
          maxData={maxData}
          setMaxData={setMaxData}
          manualUsage={manualUsage}
          setManualUsage={setManualUsage}
          manualUsers={manualUsers}
          setManualUsers={setManualUsers}
          manualJit={manualJit}
          setManualJit={setManualJit}
          tented={tented}
          setTented={setTented}
        />
      ),
      validateFunc: () => validJustification(),
    },
    {
      title: "AUP",
      key: "aup",
      render: (
        <AupStep
          aupForm={aupForm}
          fetchingAup={fetchingAup}
          acceptAup={acceptAup}
          setAcceptAup={setAcceptAup}
        />
      ),
      validateFunc: () => validAup(),
    },
    {
      title: "Review & Create",
      key: "review",
      render: (
        <ReviewStep
          title={title}
          cloud={destinationField}
          email={email}
          secondaryContact={secondaryContact}
          tenantId={tenantId}
          subscriptionId={subscriptionId}
          resourceGroup={resourceGroup}
          storageAccName={storageAccName}
          storageContainerType={storageContainerType}
          storageContainerName={storageContainerName}
          containerRegion={containerRegion}
          useCase={useCase}
          dataTypes={dataTypes}
          cloudVault={cloudVault}
          dataOrigin={dataOrigin}
          dataDest={dataDest}
          dataUsage={dataUsage}
          serviceName={serviceName}
          serviceTreeId={serviceTreeId}
          dailyData={dailyData}
          maxData={maxData}
          manualUsage={manualUsage}
          manualUsers={manualUsers}
        />
      ),
    },
  ];

  useEffect(() => {
    const getAupForm = async () => {
      const token = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );

      callAuthorizedEndpoint(ingressFlowAupAPI, token)
        .then((response) => response.text())
        .then((text) => {
          setAupForm(text);
          setFetchingAup(false);
        })
        .catch((response) =>
          response.text().then((error) => {
            setFetchingAup(false);
          })
        );
    };

    getAupForm();
  }, [account, instance]);

  return (
    <div className={baseDiv}>
      {isCreating && <LoadingView label="Creating your new Flow" />}
      {!isCreating && (
        <>
          <div className={headerDiv}>
            <span className={titleFont}>Create New Ingress Flow</span>
          </div>
          <Pivot
            className={pivotPadding}
            selectedKey={createSteps[selectedPivot].key}
          >
            {createSteps.map((step) => (
              <PivotItem
                headerText={step.title}
                itemKey={step.key}
                key={step.key}
              >
                {step.render}
              </PivotItem>
            ))}
          </Pivot>
          <div className={createFooter}>
            <div className={footerRow}>
              <DefaultButton
                disabled={selectedPivot === 0 || locked}
                onClick={() => setSelectedPivot(selectedPivot - 1)}
                text="< Previous"
                className={pivotSelect}
              />
              <DefaultButton
                disabled={
                  selectedPivot === createSteps.length - 1 ||
                  !createSteps[selectedPivot].validateFunc() ||
                  locked
                }
                onClick={() => setSelectedPivot(selectedPivot + 1)}
                text="Next >"
                className={pivotSelect}
              />
              {selectedPivot === createSteps.length - 1 && (
                <div className={reviewDiv}>
                  <PrimaryButton
                    text="Create"
                    disabled={locked || !validCreation()}
                    onClick={createFlow}
                    className={footerButtons}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CreateIngressFlows;
